import React, { useState, useRef } from 'react';
import CommonAPI from '../../components/CommonAPI';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Loading from '../assets/Loading'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
    >
        {value === index && <Box style={{ padding: 0 }} p={3}>{children}</Box>}
    </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        alignItems: 'center',
        width: '100%'
    },
    tabPanels: {
      padding: theme.spacing(0, 0),
    },
    inputPadding: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 0),
        textAlign: 'center'
    },
}));

// 오늘 날짜
function getCurDate() {
    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = '' + d.getFullYear();
    if (month.length < 2) {
        month = '0' + month
    } else if (day.length < 2) {
        day = '0' + day
    };
    return [year, month, day].join('-');
}

// 아이디 찾기 API콜
function getFindingMyAccountInfo(type, param) {
    let path = "/api/account/";
    if(type === "ID") {
        path = path + "get-my-identity";
    } else if(type === "PW") {
        path = path + "get-change-temp-passwd";
    }
    const api = new CommonAPI(path);
    api.setHttpPostParam(param);
    return api.httpPostAxios();
}

export default function FindAccount(o) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [blindId, setBlindId] = useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // ID 찾기
    const phoneRef = useRef();
    const [phone, setPhone] = useState('');
    const [birth, setBirth] = useState(getCurDate);

    const handleChangePhone = (e) => {
        const curValue = e.target.value;
        const newValue = curValue.replace(/[^0-9]/g, '');
        setPhone(newValue);
    }

    const handleChangeBirth = event => {
        setBirth(event.target.value);
    }

    // PW 찾기
    const idRef = useRef();
    const pwPhoneRef = useRef();
    const [id, setId] = useState('');
    const [pwPhone, setPwPhone] = useState('');
    const [pwBirth, setPwBirth] = useState(getCurDate);

    const handleChangeId = (e) => {
        setId(e.target.value);
    }

    const handleChangePwPhone = (e) => {
        const curValue = e.target.value;
        const newValue = curValue.replace(/[^0-9]/g, '');
        setPwPhone(newValue);
    }

    const handleChangePwBirth = (e) => {
        setPwBirth(e.target.value);
    }

    function initFindIdentity() {
        setIsLoading(true);
        if(phone === '' || phone === undefined) {
            alert("입력되지 않은 값이 있습니다.");
            setIsLoading(false);
            phoneRef.current.focus();
            return false;
        }
        const param = {
            "phoneNum" : phone,
            "birth" : birth
        }
        getFindingMyAccountInfo('ID', param).then(res => {
            const data = res.data;
            const resultCode = data.result;
            if(resultCode === 301) {
                alert("입력하신 정보가 틀렸거나, 미가입 계정입니다.\n다시 확인하여 주십시오.");
                setIsLoading(false);
                phoneRef.current.focus();
                return false;
            } else if(resultCode === 401) {
                alert("오류가 발생했습니다.\n오류가 지속된다면, help@byeongmat.com 으로 문의해주세요.");
                setIsLoading(false);
                return false;
            } else if(resultCode === 0) {
                const blindUserId = data.data.userID;
                setBlindId("계정정보 : " + blindUserId);
                setIsLoading(false);
            }
        });
    }

    function initFindPassword() {
        setIsLoading(true);
        if(id === '' || id === undefined) {
            alert("입력되지 않은 값이 있습니다.");
            setIsLoading(false);
            idRef.current.focus();
            return false;
        } else if(pwPhone === '' || pwPhone === undefined) {
            alert("입력되지 않은 값이 있습니다.");
            setIsLoading(false);
            pwPhoneRef.current.focus();
            return false;
        }
        const param = {
            "userID" : id,
            "phoneNum" : pwPhone,
            "birth" : pwBirth
        }
        getFindingMyAccountInfo('PW', param).then(res => {
            const data = res.data;
            const resultCode = data.result;
            if(resultCode === 301) {
                alert("입력하신 정보가 틀렸거나 가입되지 않은 계정정보입니다.\n다시 확인하여 주십시오.");
                setIsLoading(false);
                idRef.current.focus();
                return false;
            } else if(resultCode === 401) {
                alert("오류가 발생했습니다.\n오류가 지속된다면, help@byeongmat.com 으로 문의해주세요.");
                setIsLoading(false);
                return false;
            } else if(resultCode === 0) {
                alert("고객님의 이메일로 임시비밀번호를 발송했습니다.\n서버 상태에 따라 시간이 소요될 수 있습니다.");
                setIsLoading(false);
                window.location.reload();
            }
        });
    }

    function findIdentity() {
        return (
            <form className={classes.inputPadding} autoComplete="off">
                <TextField
                    id="standard-basic"
                    label="휴대폰 번호 ('-' 제외)"
                    style={{ width: 200 }}
                    value={phone}
                    onChange={handleChangePhone}
                    inputRef={phoneRef}
                    inputProps={{ maxLength: 11 }}
                />
                <br /><br />
                <TextField
                    id="date"
                    label="생년월일"
                    type="date"
                    required
                    value={birth}
                    className={classes.dateField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleChangeBirth}
                    style={{ width: 200 }}
                />
                <br />
                <Button variant="outlined" size="medium" color="primary" style={{marginTop: '20px'}} onClick={() => initFindIdentity()}>아이디 찾기</Button>
                <br /><br />
                {blindId}
            </form>
        )
    }

    function findPassword() {
        return (
            <form className={classes.inputPadding} autoComplete="off">
                <TextField
                    id="standard-basic"
                    label="아이디"
                    style={{ width: 200 }}
                    value={id}
                    onChange={handleChangeId}
                    inputRef={idRef}
                    inputProps={{ maxLength: 50 }}
                />
                <br /><br />
                <TextField
                    id="standard-basic"
                    label="휴대폰 번호 ('-' 제외)"
                    style={{ width: 200 }}
                    value={pwPhone}
                    onChange={handleChangePwPhone}
                    inputRef={pwPhoneRef}
                    inputProps={{ maxLength: 11 }}
                />
                <br /><br />
                <TextField
                    id="date"
                    label="생년월일"
                    type="date"
                    required
                    value={pwBirth}
                    className={classes.dateField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleChangePwBirth}
                    style={{ width: 200 }}
                />
                <br />
                <Button variant="outlined" size="medium" color="primary" style={{marginTop: '20px'}} onClick={() => initFindPassword()}>비밀번호 찾기</Button>
            </form>
        )
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    className={classes.tabs}
                >
                    <Tab label="아이디 찾기" {...a11yProps(0)} />
                    <Tab label="비밀번호 찾기" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel className={classes.tabPanels} value={value} index={0}>
                {isLoading === true ? <div style={{textAlign:'center', padding:'30px'}}><Loading /></div> : findIdentity()}
            </TabPanel>
            <TabPanel className={classes.tabPanels} value={value} index={1}>
                {isLoading === true ?<div style={{textAlign:'center', padding:'30px'}}><Loading /></div> : findPassword()}
            </TabPanel>
        </div>
    )
}
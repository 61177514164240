import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton';
// import Typography from '@material-ui/core/Typography';
// import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import MoreIcon from '@material-ui/icons/MoreVert';
import { Link, withRouter } from 'react-router-dom';
import NavMenu from './NavMenu';
import logo from '../../assets/img/logo_real_name.png';
import logoMobile from '../../assets/img/kyungpumdal_mobile_logo.png';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TokenContext from '../../components/ContextAPI'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import LockOpenIcon from '@material-ui/icons/LockOpen';
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  AppBarColor: {
    [theme.breakpoints.down('sm')]: {
     backgroundColor:"#001353",
    },
  },
  accountCircleColor: {
    [theme.breakpoints.down('md')]: {
     color:"white",
    },
  },
  homeLogo: {
    width: '140%',
    paddingTop: 7,
    paddingBottom: 7,
  },
  imgMobile: {
    [theme.breakpoints.up('sm')]: {
      width:'40%',
    },
    [theme.breakpoints.down('xs')]: {
      width:'70%',
    },
    // padding: 10
  },
  desktopFont: {
    fontSize: '100%',
    color: 'inherit',
  },
  indicator: {
    backgroundColor:'#001353',
  },
  AppBarRoot: {
    display:'inline'
  }
}));

export default withRouter(function NavBar(history) {
  const classes = useStyles();
  const tokenContext = useContext(TokenContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState('/');
  const nowPath = window.location.pathname;

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const isIE = /*@cc_on!@*/false || !!document.documentMode;

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };


  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link to='/mypage' underline='none'>
        <MenuItem onClick={handleMenuClose}>
          <div style={{ color: 'black'}}>마이페이지</div>
        </MenuItem>
      </Link>
      <Link to='/logout' underline='none'>
        <MenuItem onClick={handleMenuClose}>
          <div style={{ color: 'black' }}>로그아웃</div>
        </MenuItem>
      </Link>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link to='/mypage' underline='none'>
        <MenuItem onClick={handleMobileMenuClose}>
          <div style={{ color: 'black' , fontSize:'14px'}}>마이페이지</div>
        </MenuItem>
      </Link>
      <Link to='/logout' underline='none'>
        <MenuItem onClick={handleMobileMenuClose}>
          <div style={{ color: 'black', fontSize:'14px' }}>로그아웃</div>
        </MenuItem>
      </Link>

    </Menu>
  );

  const desktopMenu = (
    <Container maxWidth="md"  style={{padding:0}}>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Grid container spacing={0} alignItems="center" justify="center">
                <Grid item xs={1}>
                  <Link to="/">
                    <img src={logo} alt='경품달로고' className={classes.homeLogo} />
                  </Link>
                </Grid>
                {/* 쿠팡주문번호 난수화로 인한 삭제 */}
                {/* <Grid item xs={1} style={{marginLeft:48}}>
                  <Link to="/coupang" underline='none' className={classes.desktopFont}>
                    <Button color="inherit">
                      쿠팡관
                    </Button>
                  </Link>
                </Grid> */}
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={1} style={{marginLeft:48}}>
                  <Link to="/domestic-shop" underline='none' className={classes.desktopFont}>
                    <Button color="inherit">
                      국내몰
                  </Button>
                  </Link>
                </Grid>
                <Grid item xs={1}>
                  <Link to="/overseas-shop" underline='none' className={classes.desktopFont}>
                    <Button color="inherit">
                      해외몰
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={1}>
                  <Link to="/tour" underline='none' className={classes.desktopFont}>
                    <Button color="inherit">
                      여행/티켓
                  </Button>
                  </Link>
                </Grid>
                <Grid item xs={1}>
                  <Link to="/discount-code" underline='none' className={classes.desktopFont}>
                    <Button color="inherit">
                      할인코드
                  </Button>
                  </Link>
                </Grid>
                |
                <Grid item xs={1}>
                  <Link to="/how-to-use" underline='none' className={classes.desktopFont}>
                    <Button color="inherit">
                      이용방법
                  </Button>
                  </Link>
                </Grid>
                <Grid item xs={1}>
                  <Link to="/prize-event" underline='none' className={classes.desktopFont}>
                    <Button color="inherit">
                      추첨행사
                  </Button>
                  </Link>
                </Grid>
                {/* 추첨 자동화로 인한 삭제 */}
                {/* <Grid item xs={1}>
                  <Link to="/draw-vote" underline='none' className={classes.desktopFont}>
                    <Button color="inherit">
                      내가추첨
                  </Button>
                  </Link>
                </Grid> */}
                {/* 쿠팡주문번호 난수화로 인한 삭제 */}
                {/* <Grid item xs={1}>
                  <Link to="/purchase-cert" underline='none' className={classes.desktopFont}>
                    <Button color="inherit">
                      구매인증
                    </Button>
                  </Link>
                </Grid> */}
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={1}>
                  {tokenContext.token !== undefined ?
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit">
                      <AccountCircle />
                    </IconButton>
                    :
                    <Link to="/login" underline='none' className={classes.desktopFont}>
                      <Button variant = "outlined" color="primary" style={{fontSize:'12px', marginLeft:14, color:'#001353', borderColor:'#001353'}}>
                        로그인
                      </Button>
                    </Link>
                  }
                </Grid>
              </Grid>
            </div>
            <div className={classes.sectionMobile}>
              <Grid container spacing={0} alignItems="center" justify="center" >
                <Grid item xs={2}>
                  <NavMenu />
                </Grid>
                <Grid item xs={8}>
                <Link to="/">
                  <img src={logoMobile} alt='경품달로고' className={classes.imgMobile} />
                </Link>
                </Grid>
                <Grid item xs={2}>
                  {tokenContext.token !== undefined ?
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit">
                      <AccountCircle className={classes.accountCircleColor}/>
                    </IconButton>
                    :
                    <Link to="/login" underline='none' className={classes.desktopFont}>
                      <div style={{padding:0, marginBottom:'-7px'}}>
                    <LockOpenIcon style={{color:'white', fontSize:'1.6rem', paddingTop:3}}/>
                      </div>
                      <div style={{padding:0}}>
                      <span style={{fontSize:'9px', color:'white'}}>로그인/가입</span>
                      </div>
                    </Link>
                  }
                </Grid>
              </Grid>
            </div>
          </Container>
  );

  //AppBar 모바일용 리다이렉트
  const redirectWithAppBar = (event, newValue) => {
    setValue(newValue);
    // console.log(newValue)
    history.history.push(newValue);
  }; 

  return (
    <div className={classes.grow}>
      {/* 백그라운드 컬러 적용 AppBar <AppBar position="static" style={{backgroundColor: '#ffffff'}} > */}
      {isIE===true ?
        desktopMenu
      :
      <AppBar position="static" color='inherit' className={classes.AppBarColor} >
        {desktopMenu}
      </AppBar>
      }
      
      {renderMobileMenu}
      {renderMenu}

      {nowPath === '/' && 
      <div className={classes.sectionMobile}>
      <AppBar position="static" color="inherit" style={{backgroundColor:"#001353"}} >
        <Tabs
          value={value}
          onChange={redirectWithAppBar}
          // indicatorColor="primary"
          classes={{ indicator: classes.indicator }}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable tabs"
        >
          <Tab label={'HOME'}  style={{color:'white'}} value='/'/> 
          {/* <Tab label={'이용방법'} style={{color:'#191919'}} value='/how-to-use'/> */}
          {/* <Tab label={'경품가액'} style={{color:'#191919'}} value='/prize-event'/>  */}
          {/* <Tab label={'쿠팡관'} style={{color:'white'}} value='/coupang'/>  */}
          <Tab label={'국내몰'} style={{color:'white'}} value='/domestic-shop'/> 
          <Tab label={'해외몰'} style={{color:'white'}} value='/overseas-shop'/> 
          <Tab label={'여행/티켓'} style={{color:'white'}} value='/tour'/> 
          <Tab label={'할인코드'} style={{color:'white'}} value='/discount-code'/> 
          <Tab label={'추첨행사'} style={{color:'white'}} value='/prize-event'/> 
          {/* <Tab label={'내가추첨'} style={{color:'white'}} value='/draw-vote'/>  */}
        </Tabs>
        </AppBar>
      </div>
      }
    </div>
  );
})
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import {Link as RouterLink}  from 'react-router-dom' 
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CommonAPI from '../components/CommonAPI'
import cookie from 'react-cookies'
import HomeLogo from '../assets/img/logo.png';
// import TokenContext from '../components/ContextAPI'
import { Helmet } from 'react-helmet'
import FindAccount from '../components/body/LoginFindMyAccount';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https:/by.com"> */}
      병맛사람들
      {/* </Link>{' '} */}
      {' ' + new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  socialLoginButton: {
    [theme.breakpoints.up('md')]: {
      textAlign:'right'
    },
    [theme.breakpoints.down('md')]: {
      textAlign:'center'
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'inline',
    },
  },
  sectionMobile: {
      display: 'inline',
      [theme.breakpoints.up('md')]: {
          display: 'none',
      },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLogin, setIsLogin] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function findMyAccount() {
    setOpen(true);
  }

  //이메일 핸들러
  const handleChangeEmail = event => {
    setEmail(event.target.value);
  };

  //패스워드 핸들러
  const handleChangePassword = event => {
    setPassword(event.target.value);
  };

  //토큰 GET API CALL
  function httpPostLogin(jsonObj) {
    const api = new CommonAPI("/api/authenticate-login");
    api.setHttpPostParam(jsonObj)
    return api.httpPostAxios();
  }

  const getToken = () => {
    if (email !== '' && password !== '') {
      let requestObj = {}
      requestObj.username = email;
      requestObj.password = password;
      // console.log(requestObj);
      httpPostLogin(JSON.stringify(requestObj)).then(
        (response) => {
          // console.log(response)
          if (response.data.token === "INVALID_CREDENTIALS") {
            setEmail('');
            setPassword('');
            setIsLogin(false);
          } else {
            if (response.data.token !== undefined && response.data.token !== null && response.data.token !== "") {
              const jwtToken = cookie.load('token');
              if (jwtToken !== undefined) {
                cookie.remove("token");
              }
              
              const secureType = process.env.REACT_APP_TOKEN_SECURE_TYPE === 'true' ? true : false;
              cookie.save('token', response.data.token, { secure: secureType, path:'/' });
              
              setIsLogin(true);
              window.location.href = '/';
            }
          }
        }
      ).catch();
    }
  }


  useEffect(() => {
    //Naver ID Login Oauth 2.0
    // var naverLogin = new window.naver.LoginWithNaverId(
    //   {
    //     clientId: "9IBqvyFk27nIad4kgTzW",
    //     callbackUrl: "http://127.0.0.1:3000/login",
    //     isPopup: false, /* 팝업을 통한 연동처리 여부 */
    //     loginButton: {color: "white", type: 3, height: 36} /* 로그인 버튼의 타입을 지정 */
    //   }
    // );
    
    // /* 설정정보를 초기화하고 연동을 준비 */
    // naverLogin.init();

    // window.addEventListener('load', function () {
		// 	naverLogin.getLoginStatus(function (status) {
		// 		if (status) {
		// 			/* (5) 필수적으로 받아야하는 프로필 정보가 있다면 callback처리 시점에 체크 */
		// 			var email = naverLogin.user.getEmail();
		// 			if( email === undefined || email === null) {
		// 				alert("이메일은 필수정보입니다. 정보제공을 동의해주세요.");
		// 				/* (5-1) 사용자 정보 재동의를 위하여 다시 네아로 동의페이지로 이동함 */
		// 				naverLogin.reprompt();
		// 				return;
		// 			}

		// 			// window.location.replace("http://" + window.location.hostname + ( (window.location.port===""||window.location.port===undefined)?"":":" + window.location.port) + "/sign-up");
		// 		} else {
		// 			alert("로그인 중 에러가 발생했습니다")
		// 		}
		// 	});
		// });

  }, []);

  


    return (
        <Container component="main" maxWidth="xs">
          <Helmet>
          <title>로그인 | 경품달(경품을 품은 달)</title>
          <meta name="description" content="경품달 로그인 페이지입니다 | 아이폰급 철통 보안유지 | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
          <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
          <meta name="author" content="병맛사람들" />
          </Helmet>
          <CssBaseline />
          <div className={classes.paper}>
            <RouterLink to='/'>
              <img src={HomeLogo} alt='경품달로고' width="190px" style={{padding:'10px', marginBottom:'10px'}} className={classes.sectionDesktop}/>
              <img src={HomeLogo} alt='경품달로고' width="180px" style={{padding:'10px', marginBottom:'10px'}} className={classes.sectionMobile}/>
            </RouterLink> 
            {isLogin===null ?
            <Typography component="h1" variant="body1">
              안녕하세요 ! 환영합니다 :)
            </Typography> :
            isLogin === true ?
            '' :
            <Typography component="h1" variant="body1">
              아이디와 비밀번호를 확인해주세요.
            </Typography>
            }
            
            <ValidatorForm onSubmit={getToken}>
              <TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="이메일 주소"
                name="email"
                autoComplete="email"
                // autoFocus
                value={email}
                onChange={handleChangeEmail}
                validators={['required', 'isEmail']}
                errorMessages={['이 필드는 필수 입니다.', '이메일 주소가 아닙니다.']}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={password}
                onChange={handleChangePassword}
                name="password"
                label="비밀번호"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{backgroundColor:"#263880", borderColor:"#263880"}}
              >
                로그인
            </Button>

              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={() => findMyAccount()} style={{color:"#344ba7"}}>
                    아이디/비밀번호 찾기
                  </Link>
                </Grid>
                <Grid item xs>
                  <RouterLink to="/sign-up" variant="body2" style={{color:"#344ba7"}} >
                    {"회원가입하기"}
                  </RouterLink>
                </Grid>
              </Grid>
            </ValidatorForm>
            <br/>
            <Grid container className={classes.socialLoginButton}>
              <Grid item xs={12} md={12}>
              {/* <div id="naverIdLogin"></div> */}
              </Grid>
            </Grid>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>

          <Dialog
            className={classes.root}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">{"아이디 / 비밀번호 찾기"}</DialogTitle> */}
            <DialogContent>
              <div style={{textAlign:'right'}}>
            <Button size="medium" color="primary" style={{color:"#616161",textAlign:'right'}} onClick={() => handleClose()}>닫기</Button>
            <br/><br/>
            </div>
              <FindAccount />
            </DialogContent>
          </Dialog>
        </Container>
    );
  }

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CommonAPI from '../components/CommonAPI';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Container from '@material-ui/core/Container';
import { Helmet } from 'react-helmet'
// import Divder from '@material-ui/core/Divider'


const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      // padding: theme.spacing(4, 0),
      paddingBottom:20
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0),
      paddingBottom: '10px',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  sectionMobile: {
    display: 'inline',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  media: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding:'6px',
  },
  [theme.breakpoints.down('md')]: {
      width: '100%',
      padding:'6px',
  },
  },
  title: {
    padding: theme.spacing(1, 2),
    paddingBottom: 20
  },
  content: {
    padding: theme.spacing(1, 1),
  },
  contentMobile: {
    padding: theme.spacing(0, 0),
    paddingBottom: 8
  },
  card: {
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
    },
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      minHeight: '218px',
    },
    //   [theme.breakpoints.down('md')]: {
    //     paddingTop:'5px',
    // },
  },
}));

function getCouponCode() {
  const api = new CommonAPI("/api/kyungpumdal/get-coupon-codes");
  return api.httpGetAxios();
}

function loading() {
  return (
    <Grid item xs={12}>
      <CircularProgress color="primary" />
    </Grid>
  );

}

export default function DiscountCode() {

  const classes = useStyles();
  const [data, setData] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    getCouponCode().then(
      (response) => {
        if (response.data.result === 0) {
          setData(response.data.dataList);
        }
      }
    ).catch();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  function getCouponInfo(prop) {
    setCoupon(prop);
    setOpen(true);
  }

  
//텍스트가 길어지면 자르기
function textLengthOverCut(txt, len) {
  const lastTxt = "...";
  if (len === "" || len === null) { // 기본값
    len = 13;
  }
  if (txt.length > len) {
    txt = txt.substr(0, len) + lastTxt;
  }
  return txt;
}

  function couponListCard(props, classes) {
      return (
        props.map(prop => (
          <Grid item md={3} xs={6} key={prop.couponCode}>
            <Card className={classes.card} onClick={() => getCouponInfo(prop)}>
              <CardActionArea>
                <img src={prop.imageURL} alt={prop.couponName} className={classes.media}
                />
                <CardContent className={classes.content}>
                  <Typography style={{ fontSize: 14 }}>
                    {textLengthOverCut(prop.couponName,11)}
                  </Typography>
                  <Typography variant="body2" style={{color:'#3e4c9d'}} component="p">
                    {prop.remainDays === 0 ? '오늘마감' : '남은일수 : ' + prop.remainDays + '일'}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))
      )
    }

  return (
<React.Fragment>
      <Helmet>
      <title>할인코드 | 경품달(경품을 품은 달)</title>
      <meta name="description" content="경품달 쇼핑몰 할인코드 모음! | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
      <meta name="keywords" content="할인코드,쇼핑쿠폰,쿠폰,쿠폰모음,할인쿠폰,경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
      <meta name="author" content="병맛사람들" />
      </Helmet>
      <CssBaseline />
      <Paper elevation={0} className={classes.root} justify='center'>
        <Container maxWidth="md" style={{padding:0}}>
          <CssBaseline />
            <Paper elevation={0} className={classes.sectionDesktop}>
              <Typography variant="h5" component="h3" style={{backgroundColor:'#001353', color:'white'}}>
                  <br/>할인코드
              </Typography>
              <Typography variant="body2" component="h3" style={{backgroundColor:'#001353', color:'white'}}>
                  경품달 할인코드로 알뜰쇼핑 <br/><br/><br/>
              </Typography>
              </Paper>
            <div className={classes.sectionDesktop} >
              <Grid container spacing={1} alignItems="center" style={{marginTop:4}}>
                {data == null ? loading() : couponListCard(data, classes)}
              </Grid>
            </div>
            <div className={classes.sectionMobile}>
              <Grid container spacing={1} style={{paddingLeft:3,paddingRight:3,marginTop:2}} alignItems="center">
                {data == null ? loading() : couponListCard(data, classes)}
              </Grid>
            </div>
            </Container>
          </Paper>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{coupon !== null ? coupon.couponName : 'loading'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {coupon !== null ? '적용일 : ' + (coupon.beginApply + '~' + coupon.endApply) : 'loading'}<br/><br/>
                {coupon !== null ? '쿠폰코드 : '+ (coupon.couponCode) : 'loading'}<br/><br/>
                {coupon !== null ? 
                coupon.notice.split('\r\n').map( line => {
                  return (<span key={line}>{line}<br/></span>)
                })
                :
                'loading'}<br/><br/>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  닫기
                </Button>
            </DialogActions>
          </Dialog>
    </React.Fragment>
  );
}

import React from 'react';
import cookie from 'react-cookies'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles';
import {Redirect} from 'react-router-dom'
// import { Helmet } from 'react-helmet'

export default function Logout() {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3, 2),
    },
  }));

  const classes = useStyles();


  const removeToeknCookie = () => {
    const jwtToken = cookie.load('token');

    if (jwtToken !== undefined) {
      cookie.remove("token");
      window.location.href = '/';
    } else {
      return <Redirect to ='/' />
    }
  }

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Container maxWidth="md">
            <div>
              <CircularProgress />
            </div>
        </Container>
      </Paper>
      {removeToeknCookie()}
    </React.Fragment>
  )

}
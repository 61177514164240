import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import { Helmet } from 'react-helmet'


const useStyles = makeStyles(theme => ({
  innerPaper: {
    textAlign:'left',
    lineHeight: '2em',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 6),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1),
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  head: {
    fontSize: '20px',
    fontWeight: 'bold'
  }
}));

export default function TermsOfLocation() {
  const classes = useStyles();
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
          <Helmet>
          <title>위치정보 이용약관 | 경품달(경품을 품은 달)</title>
          <meta name="description" content="경품달 위치정보 이용약관 | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
          <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
          <meta name="author" content="병맛사람들" />
          </Helmet>
      <CssBaseline />
          <Paper elevation={2} className={classes.innerPaper}>
        <Container maxWidth="md">
            <span className={classes.head}>위치정보 이용약관</span>
            <br />
            <br />
            <span className={classes.title}>제 1 조 목적</span>
            <br />이 약관은 병맛사람들(이하 “회사”)가 제공하는 위치기반서비스와 관련하여 회사와 개인위치정보주체와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
            <br />
            <br /><span className={classes.title}>제 2 조 이용약관의 효력 및 변경</span>
            <br />①본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에 동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생합니다.
            <br />②회원이 온라인에서 본 약관의 "동의하기" 체크박스를 선택(클릭)하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.
            <br />③회사는 위치정보의 보호 및 이용 등에 관한 관련법령을 위배하지 않는 범위에서 회사의 업무상 약관을 변경해야 할 사유가 있다고 판단 될 경우 본 약관을 변경할 수 있습니다.
            <br />④회사가 약관을 변경할 경우에는 그 사유를 이용자에 통지 가능한 수단으로 즉시 통지하고 변경된 약관과 사유를 적용일자 7일 전까지 공지합니다.
            <br />⑤회사가 전항에 따라 회원에게 통지하면서 공지 또는 공지 고지일로부터 시행일 7일 후까지 거부의사를 표시하지 않거나 이 서비스를 이용할 경우 이용약관을 승인한 것으로 봅니다.
            <br />
            <br /><span className={classes.title}>제 3 조 약관 외 준칙</span>
            <br />본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신기본법, 전기통신사업법 등 관계법령과 회사의 이용약관 및 개인정보처리방침, 회사가 별도로 정한 지침 등에 의합니다.
            <br />
            <br /><span className={classes.title}>제 4 조 서비스 내용 및 요금</span>
            <br />①회사는 직접 위치정보를 수집하거나 위치정보사업자인 이동통신사로부터 위치정보를 전달받아 아래와 같은 위치기반서비스를 제공합니다.
            <br />위치기반 제공 서비스 명 : 경품달
            <br />1.현재 위치를 활용한 검색결과 제공 서비스: 정보 검색 요청 시 개인위치정보주체의 현 위치를 이용한 검색결과 및 주변결과(맛집, 뷰티, 문화, 여행 등)등을 제시합니다.
            <br />②제1항 위치기반서비스의 이용요금은 무료입니다.
            <br />
            <br /><span className={classes.title}>제 5 조 개인위치정보주체의 권리</span>
            <br />①개인위치정보주체는 개인위치정보 수집 범위 및 이용약관의 내용 중 일부 또는 개인위치정보의 이용ㆍ제공 목적, 제공받는 자의 범위 및 위치기반서비스의 일부에 대하여 동의를 유보할 수 있습니다.
            <br />②개인위치정보주체는 개인위치정보의 수집ㆍ이용ㆍ제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다.
            <br />③개인위치정보주체는 언제든지 개인위치정보의 수집ㆍ이용ㆍ제공의 일시적인 중지를 요구할 수 있습니다.
            <br />이 경우 회사는 요구를 거절하지 아니하며, 이를 위한 기술적 수단을 갖추고 있습니다.
            <br />④개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다.
            <br />이 경우 회사는 정당한 이유 없이 요구를 거절하지 아니합니다.
            <br />1.개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료
            <br />2.개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법령의 규정에 의하여 제3자에게 제공된 이유 및 내용
            <br />⑤회사는 개인위치정보주체가 동의의 전부 또는 일부를 철회한 경우에는 지체 없이 수집된 개인위치정보 및 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를 파기합니다.
            <br />단, 동의의 일부를 철회하는 경우에는 철회하는 부분의 개인위치정보 및 위치정보 수집ㆍ이용ㆍ제공사실 확인자료에 한합니다.
            <br />⑥개인위치정보주체는 제1항 내지 제3항의 권리행사를 위하여 이 약관 제13조의 연락처를 이용하여 회사에 요구할 수 있습니다.
            <br />
            <br /><span className={classes.title}>제 6 조 법정대리인의 권리</span>
            <br />①회사는 14세 미만의 회원에 대해서는 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의를 당해 회원과 당해 회원의 법정대리인으로부터 동의를 받아야 합니다. 이 경우 법정대리인은 제5조에 의한 회원의 권리를 모두 가집니다.
            <br />②회사는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용 제공사실 확인자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세미만의 아동과 그 법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는 제외합니다.
            <br />1. 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용, 제공사실 확인자료가 필요한 경우
            <br />2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우
            <br />
            <br /><span className={classes.title}>제 7 조 위치정보 이용ㆍ제공사실 확인자료 보유근거 및 보유기간</span>
            <br />회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료를 위치정보시스템에 자동으로 기록할 수 있으며, 1년 이상 보관할 수 있습니다.
            <br />
            <br /><span className={classes.title}>제 8 조 서비스의 변경 및 중지</span>
            <br />①회사는 위치정보사업자의 정책변경 등과 같이 회사의 제반 사정 또는 법률상의 장애 등으로 서비스를 유지할 수 없는 경우, 서비스의 전부 또는 일부를 제한, 변경하거나 중지할 수 있습니다.
            <br />②제1항에 의한 서비스 중단의 경우에는 회사는 사전에 인터넷 등에 공지하거나 개인위치정보주체에게 통지합니다.
            <br />
            <br /><span className={classes.title}>제 9 조 개인위치정보 제3자 제공시 즉시 통보</span>
            <br />①회사는 개인위치정보주체의 동의 없이 당해 개인위치정보주체의 개인위치정보를 제3자에게 제공하지 아니하며, 제3자 제공 서비스를 제공하는 경우에는 제공 받는 자 및 제공목적을 사전에 개인위치정보주체에게 고지하고 동의를 받습니다.
            <br />②회사는 개인위치정보를 개인위치정보주체가 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신단말장치로 매회 개인위치정보주체에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다.
            <br />③다만, 아래에 해당하는 경우에는 개인위치정보주체가 미리 특정하여 지정한 통신단말장치 또는 전자우편주소 등으로 통보합니다.
            <br />1.개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우
            <br />2.개인위치정보주체가 개인위치정보를 수집한 당해 통신단말장치 외의 통신단말장치 또는 전자우편주소 등으로 통보할 것을 미리 요청한 경우
            <br />
            <br /><span className={classes.title}>제 10 조 위치정보관리책임자의 지정</span>
            <br />①회사는 위치정보를 적절히 관리, 보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.
            <br />②위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.
            <br />
            <br /><span className={classes.title}>제 11 조 손해배상</span>
            <br />개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에 대하여 손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는 과실이 없음을 입증하지 아니하면 책임을 면할 수 없습니다.
            <br />
            <br /><span className={classes.title}>제 12 조 분쟁의 조정</span>
            <br />회사는 위치정보와 관련된 분쟁에 대하여 개인위치정보주체와 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 방송통신위원회에 재정을 신청할 수 있습니다.
            <br />
            <br /><span className={classes.title}>제 13 조 사업자 정보</span>
            <br />회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같습니다.
            <br />상호 : 병맛사람들
            <br />대표 : 권동영
            <br />주소 : 전라남도 장성군 진원면 덕주 1길 21-5
            <br />이메일 주소 : help@byeongmat.com
            {/* <br />전화번호 : 010-4053-4919 */}
        </Container>
          </Paper>
    </React.Fragment>
  );
}
import React from 'react'
// import React, { useContext } from 'react'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import Typography from '@material-ui/core/typography'
// import ApartmentIcon from '@material-ui/icons/Apartment';
// import FaceIcon from '@material-ui/icons/Face';
// import HowToVoteIcon from '@material-ui/icons/HowToVote';
// import SingleBedIcon from '@material-ui/icons/SingleBed';
// import PanToolIcon from '@material-ui/icons/PanTool';
// import GavelIcon from '@material-ui/icons/Gavel';
import AwesomeSliderStyles from '../../assets/css/HomeCarousel.css';
import { makeStyles } from '@material-ui/core/styles';
// import TokenContext from '../ContextAPI'
import Confetti from 'react-confetti'
// import { createMuiTheme } from '@material-ui/core/styles';
 require('../../assets/css/HomeCarousel.css');

// 스타일 객체
const useStyles = makeStyles(theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
        },
    },
    text: {
        color:'white',
    },
}));

export default function HomeCarousel() {

    // window.addEventListener('resize', getCssWithBreakpoints);

    // 스타일 객체 불러오기
    const classes = useStyles();
    // 토큰 컨텍스트 불러오기
    // const tokenContext = useContext(TokenContext);
    // const nickName = tokenContext.nickName;
    // //CSS 홈캐러셀 CSS 불러오기
    // function getCssWithBreakpoints() {
    //     const theme = createMuiTheme({
    //     });
    //     console.log("리사이즞됨")
    //     if( document.querySelector('.awssld') != null){
    //     if (theme.breakpoints.values.md < window.innerWidth) {
    //         document.querySelector('.awssld').setAttribute('style',"--slider-height-percentage:95%")
    //     } else {
    //         document.querySelector('.awssld').setAttribute('style',"--slider-height-percentage:24%")
    //         // return require('../../assets/css/HomeCarouselMobile.css');
    //     }}
    // }

    // 오토 플레이 슬라이더
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const deskConfet = (
        <Confetti
            numberOfPieces={100}
            gravity={0.07}
        />
    );
    const mobileConfet = (
        <Confetti
            numberOfPieces={20}
            gravity={0.03}
        />
    );

    return (
        <div>
            <div className={classes.sectionDesktop}>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false}
                    bullets={false}
                    interval={2500}
                    cssModule={AwesomeSliderStyles}
                >
                    <div style={{backgroundColor:'#263880'}}>
                        {deskConfet}
                        <Typography variant="h3" component="h3" className={classes.text}>
                            밑지는 복권! 본전인 쇼핑!
                        </Typography>
                    </div>
                    {/* <div style={{backgroundColor:'#263980'}}>
                        <Confetti
                            width={width}
                            height={height}
                        />
                        <Typography variant="h3" component="h3" className={classes.text}>
                            가입하고! 알림수신! 경품추첨!
                        </Typography>
                    </div> */}
                    <div style={{backgroundColor:'#263880'}}>
                    {deskConfet}
                        <Typography variant="h3" component="h3" className={classes.text}>
                            평소처럼! 쇼핑하면! 경품추첨!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {deskConfet}
                        <Typography variant="h3" component="h3" className={classes.text}>
                            실시간! 추첨방송! 경품골라!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {deskConfet}
                        <Typography variant="h3" component="h3" className={classes.text}>
                            당첨금액! 안에서면! 뭐든 OK!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {deskConfet}
                        <Typography variant="h3" component="h3" className={classes.text}>
                            내가될까? 진짜랜덤! 공정하다!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {deskConfet}
                        <Typography variant="h3" component="h3" className={classes.text}>
                            고객님의! 개인정보! 철저하게!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {deskConfet}
                        <Typography variant="h3" component="h3" className={classes.text}>
                            매달추첨! 스마트쇼핑! 참 쉽죠?
                        </Typography>
                    </div>
                    
                    {/* <div >
                        <Confetti
                            width={width}
                            height={height}
                        />
                        <Typography variant="h3" component="h3" >
                            주작없는! 공정한! 경품문화!
                        </Typography>
                    </div> */}
                </AutoplaySlider>
            </div>

            <div className={classes.sectionMobile}>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false}
                    bullets={false}
                    interval={2500}
                    cssModule={AwesomeSliderStyles}
                >
                    <div style={{backgroundColor:'#263880'}}>
                        {mobileConfet}
                        <Typography variant="h5" component="h3" className={classes.text}>
                            밑지는 복권! 본전인 쇼핑!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {mobileConfet}
                        <Typography variant="h5" component="h3" className={classes.text}>
                            평소처럼! 쇼핑하면! 경품추첨!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {mobileConfet}
                        <Typography variant="h5" component="h3" className={classes.text}>
                            실시간! 추첨방송! 경품골라!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {mobileConfet}
                        <Typography variant="h5" component="h3" className={classes.text}>
                            당첨금액! 안에서면! 뭐든 OK!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {mobileConfet}
                        <Typography variant="h5" component="h3" className={classes.text}>
                            내가될까? 진짜랜덤! 공정하다!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {mobileConfet}
                        <Typography variant="h5" component="h3" className={classes.text}>
                            고객님의 개인정보! 철저하게!
                        </Typography>
                    </div>
                    <div style={{backgroundColor:'#263880'}}>
                        {mobileConfet}
                        <Typography variant="h5" component="h3" className={classes.text}>
                            매달추첨! 스마트쇼핑! 참 쉽죠 ?
                        </Typography>
                    </div>
                    
                    {/* <div >
                        <Confetti
                            width={width}
                            height={height}
                        />
                        <Typography variant="h3" component="h3" >
                            주작없는!<br /><br />공 정 한 !<br /><br />경품문화!
                        </Typography>
                    </div> */}
                </AutoplaySlider>
            </div>
        </div>
    )
}
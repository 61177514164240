import React from 'react';
import NavBar from '../navbar/NavBar';
import { withRouter, Redirect } from 'react-router-dom'

class Header extends React.Component {
    render() {

        const isIE = /*@cc_on!@*/false || !!document.documentMode;

        if (this.props.token === 'expiredToken') {
            return <Redirect to='/logout' />
        } else {
            if (this.props.history.location.pathname === '/login' || this.props.history.location.pathname === '/sign-up') {
                return null;
            } else {
                if(isIE === true){
                    return (
                    <div>
                        <div >
                            <NavBar/>
                        </div>
                    </div>
                    );
                }else{
                return (
                <div style={{position:'sticky',top: 0, zIndex:400}}>
                        <div>
                            <NavBar/>
                        </div>
                    </div>
                );
                }
            }
        }
    }
}
export default withRouter(Header);
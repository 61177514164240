import React from 'react';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link'
// import Paper from '@material-ui/core/Paper'

export default function HomeSetupChromeNotification() {

    return (
        <React.Fragment>
            <Grid container spacing={0} style={{paddingTop:'10px', backgroundColor:'#263980', color:'#ffda61'}}>
                <Grid item xs={12}>
                    <Typography variant="h6"  component="h3">
                        경품달은, 크롬 브라우저에 최적화 되어있습니다.<br/>
                        쾌적한 서비스 이용을 위해, <Link style={{color:'white'}} underline='none' target="_blank" href ='https://www.google.com/chrome/'>크롬 브라우저</Link>를 사용 해주세요.
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}



import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CommonAPI from '../CommonAPI';
// import Link from '@material-ui/core/Link';
import { Link as LinkRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TokenContext from '../ContextAPI';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
// import { Helmet } from 'react-helmet'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
// import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4, 0),
            backgroundColor:'#f8f8f8',
            borderRadius:0,
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 0),
            paddingBottom: '10px',
            backgroundColor:'white',
            borderRadius:0,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'inline',
        },
    },
    sectionMobile: {
        display: 'inline',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    media: {
        [theme.breakpoints.up('md')]: {
            width: '120px',
            height: '60px',
        },
        [theme.breakpoints.down('md')]: {
            width: '90px',
            height: '40px',
        },
    },
    title: {
        padding: theme.spacing(1, 2),
        // paddingBottom: 34
    },
    content: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1, 1),
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 0),
        },
    },
    contentMobile: {
        padding: theme.spacing(0, 0),
        paddingBottom: 8
    },
    card: {
        [theme.breakpoints.down('md')]: {
          // boxShadow: 'none',
          borderRadius: 3,
          marginBottom: 6,
          marginTop : 10,
          marginLeft:8,
          marginRight:8,
        },
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(1,0.5),
        },
      }, appBarTab: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
    appBarColor: {
        // [theme.breakpoints.down('md')]: {
            backgroundColor:'#263880'
        // },
    },
    searchField: {
        [theme.breakpoints.down('md')]: {
          width:'94%'
        },
        [theme.breakpoints.up('md')]: {
          width:'100%',
        },
      },

}));

function getLinkPriceMerchants() {
    const api = new CommonAPI("/api/kyungpumdal/get-linkprice-merchants");
    api.setHttpGetParam("?imageSize=none&merchantType=none")
    return api.httpGetAxios();
}

function getLinkPriceCategory() {
    const api = new CommonAPI("/api/kyungpumdal/get-linkprice-merchant-category");
    api.setHttpGetParam("?merchantType=none")
    return api.httpGetAxios();
}

// baseCommissioe: 0.0315
// imageSize: "120x60"
// baseImageURL: "https://img.linkprice.com/files/stlink/072com/0281"
// baseURL: "https://linkmoa.kr/click.php?m=072com&a=A100660940&l=0000"
// imageURL: "http://img.linkprice.com/files/stlink/072com/0151"
// merchantCategory: "여행/티켓"
// merchantID: "072com"
// merchantName: "땡처리닷컴"

function getUserIDParam(userKey, merchantID) {
    if (merchantID !== 'coupang') {
        return '&u_id=' + userKey
    } else {
        return '';
    }
}

function loading() {
    return (
        <Grid item xs={12}>
            <CircularProgress color="primary" />
        </Grid>
    );

}

export default function HomeMerchantCategory() {
    const classes = useStyles();
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);
    const [clickedURL, setClickedURL] = useState('');
    const [filteredList, setFilteredList] = useState(undefined);
    const tokenContext = useContext(TokenContext);
    const userKey = tokenContext.userKey;
    const [appBarFilteredList, setAppBarFilteredList] = useState(undefined);
    const [category, setCategory] = useState(null);
    const [value, setValue] = React.useState(0);
    const [selectedCategory, setSelectedCategory] = React.useState('주요쇼핑몰');

    useEffect(() => {
        let unmounted = false;

        getLinkPriceCategory().then(
            (response) => {
                if (!unmounted) {
                if (response.data.result === 0) {
                    response.data.dataList.unshift({ 'categoryName': '주요쇼핑몰' });
                    setCategory(response.data);
                }
                getLinkPriceMerchants().then(
                    (response) => {
                        if (response.data.result === 0) {
                            /* 쿠팡주문번호 난수화로 인한 삭제 */
                            // const coupangObject = {
                            //     'baseCommissionRate': process.env.REACT_APP_CUP_BASE_COMMISSION_RATE,
                            //     'baseImageURL': process.env.REACT_APP_CUP_BASE_IMAGE_URL,
                            //     'baseURL': process.env.REACT_APP_CUP_BASE_URL,
                            //     'imageURL': process.env.REACT_APP_CUP_IMAGE_URL,
                            //     'merchantCategory': process.env.REACT_APP_CUP_MERCHANT_CATEGORY,
                            //     'majorStatus': true,
                            //     'merchantID': process.env.REACT_APP_CUP_MERCHANT_ID,
                            //     'merchantName': process.env.REACT_APP_CUP_MERCHANT_NAME
                            // }
                            // response.data.dataList.unshift(coupangObject);
                            setData(response.data);
                            setAppBarFilteredList(getCategoryFilterList(selectedCategory, response.data.dataList))
                        }
                        // document.getElementById('category-tab-home-0').click();
                    }
                ).catch();
            }
        }
        ).catch();

        return () => { unmounted = true };

    }, [selectedCategory]);


    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseAndOpenLink = () => {
        setOpen(false);
        if (clickedURL !== '') {
            window.open(clickedURL, "_blank")
        }
    };

    function notifyNotLoggedin(baseURL) {
        setOpen(true);
        setClickedURL(baseURL);
    }

    function filterList(event) {
        let dataList = undefined;
        // console.log(mobileFilteredList)
        if (appBarFilteredList !== undefined) {
            dataList = appBarFilteredList;
            // console.log(dataList);
        }

        if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
            setFilteredList(undefined);
        } else {
            let filteredByName = dataList.filter(function (item) {
                return item.merchantName.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase().search(
                    event.target.value.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase()) !== -1;
            });
            let filteredByCategory = dataList.filter(function (item) {
                return item.merchantCategory.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase().search(
                    event.target.value.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase()) !== -1;
            });
            if (filteredByName.length > 0) {
                setFilteredList(filteredByName);
            }
            if (filteredList !== undefined && filteredByCategory.length > 0) {
                const concatFiltered = filteredList.concat(filteredByCategory);
                const filteredDeduplication = concatFiltered.filter((item, idx, array) => {
                    return array.indexOf(item) === idx;
                });

                setFilteredList(filteredDeduplication);
            } else if (filteredList === undefined && filteredByCategory.length > 0) {
                setFilteredList(filteredByCategory);
            }
        }
    }


    function getCategoryFilterList(appBarCategory, dataList) {
        let filteredByCategory = null;
        if (appBarCategory === '' || appBarCategory === undefined || appBarCategory === null) {
            return undefined;
        } else if (appBarCategory === '주요쇼핑몰' && dataList !== null) {
            const majorList = [];
            dataList.forEach(element => {
                if (element.majorStatus === true) (
                    majorList.push(element)
                )
            });
            if (majorList.length > 0) {
                return majorList
            }
        } else {
            filteredByCategory = dataList.filter(function (item) {
                return item.merchantCategory.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase().search(
                    appBarCategory.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase()) !== -1;
            });
        }
        if (filteredByCategory !== null) {
            return filteredByCategory;
        }
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <div>{children}</div>}
            </Typography>
        );
    }

    function merchantListCard(dataProps, categoryProps) {
        console.log(dataProps)
        console.log(categoryProps)
        if (userKey === undefined) {
            return (
                categoryProps && categoryProps.dataList.map((categoryProp, index) => (
                    //const categoryFilterList = getCategoryFilterList(categoryProp.categoryName, dataProps.dataList);
                    <TabPanel value={value} index={index} key={index} className={classes.appBarTab}>
                        <Grid container alignItems="center">
                            {getCategoryFilterList(categoryProp.categoryName, dataProps.dataList) && getCategoryFilterList(categoryProp.categoryName, dataProps.dataList).map(prop => (
                                <Grid item md={3} xs={6} key={prop.merchantID} >
                                    {prop.merchantID === 'coupang' ?
                                        <LinkRouter to='/coupang'>
                                            <Card className={classes.card} onClick={() => notifyNotLoggedin(prop.baseURL)}>
                                                <CardActionArea>
                                                    <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                                                    />
                                                    <CardContent className={classes.content}>
                                                        <Typography style={{ fontSize: 14, color: 'black' }}>
                                                            {prop.merchantName}
                                                        </Typography>
                                                        <Typography variant="body2" style={{fontSize:11}} color="textSecondary" component="p">
                                                            ({prop.merchantCategory}) 
                                                        </Typography>
                                                        <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </LinkRouter>
                                        :
                                        <Card className={classes.card} onClick={() => notifyNotLoggedin(prop.baseURL)}>
                                            <CardActionArea>
                                                <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                                                />
                                                <CardContent className={classes.content}>
                                                    <Typography style={{ fontSize: 14, color:'black' }}>
                                                        {prop.merchantName}
                                                    </Typography>
                                                    <Typography variant="body2" style={{fontSize:11}} color="textSecondary" component="p">
                                                        ({prop.merchantCategory})
                                                    </Typography>
                                                    <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </TabPanel>
                ))
            )
        } else {
            return (
                categoryProps.dataList.map((categoryProp, index) => (
                    <TabPanel value={value} key={index} index={index}>
                        <Grid container alignItems="center">
                            {getCategoryFilterList(categoryProp.categoryName, dataProps.dataList).map(prop => (
                                <Grid item md={3} xs={6} key={prop.merchantID}>
                                    <Card className={classes.card}>
                                        {prop.merchantID === 'coupang' ?
                                            <LinkRouter to='/coupang'>
                                                <CardActionArea>
                                                    <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                                                    />
                                                    <CardContent className={classes.content}>
                                                        <Typography style={{ fontSize: 14, color:'black' }}>
                                                            {prop.merchantName}
                                                        </Typography>
                                                        <Typography variant="body2" style={{fontSize:11}} color="textSecondary" component="p">
                                                            ({prop.merchantCategory})
                                                        </Typography>
                                                        <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </LinkRouter>
                                            :
                                            // <Link href={prop.baseURL + getUserIDParam(userKey, prop.merchantID)} target="_blank" underline="none">
                                                <CardActionArea onClick={() => window.open(prop.baseURL + getUserIDParam(userKey, prop.merchantID), '_blank')}>
                                                    <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                                                    />
                                                    <CardContent className={classes.content}>
                                                        <Typography style={{ fontSize: 14, color:'black' }}>
                                                            {prop.merchantName}
                                                        </Typography>
                                                        <Typography variant="body2" style={{fontSize:11}} color="textSecondary" component="p">
                                                            ({prop.merchantCategory})
                                                        </Typography>
                                                        <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            // </Link>
                                        }
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </TabPanel>
                ))
            )
        }
    }

    function merchantListCardWithSearch(dataProps) {
        // console.log(dataProps)
        // console.log(categoryProps)
        if (userKey === undefined) {
            return (
                <Grid container alignItems="center">
                    {dataProps.map(prop => (
                        <Grid item md={3} xs={6} key={prop.merchantID} >
                            {prop.merchantID === 'coupang' ?
                                <LinkRouter to='/coupang'>
                                    <Card className={classes.card} onClick={() => notifyNotLoggedin(prop.baseURL)}>
                                        <CardActionArea>
                                            <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                                            />
                                            <CardContent className={classes.content}>
                                                <Typography style={{ fontSize: 14, color: 'black' }}>
                                                    {prop.merchantName}
                                                </Typography>
                                                <Typography variant="body2" style={{fontSize:11}} color="textSecondary" component="p">
                                                    ({prop.merchantCategory})
                                                        </Typography>
                                                        <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </LinkRouter>
                                :
                                <Card className={classes.card} onClick={() => notifyNotLoggedin(prop.baseURL)}>
                                    <CardActionArea>
                                        <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                                        />
                                        <CardContent className={classes.content}>
                                            <Typography style={{ fontSize: 14 }}>
                                                {prop.merchantName}
                                            </Typography>
                                            <Typography variant="body2" style={{fontSize:11}} color="textSecondary" component="p">
                                                ({prop.merchantCategory})
                                                    </Typography>
                                                    <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            }
                        </Grid>
                    ))}
                </Grid>
            )
        } else {
            return (
                <Grid container alignItems="center">
                    {dataProps.map(prop => (
                        <Grid item md={3} xs={6} key={prop.merchantID}>
                            <Card className={classes.card}>
                                {prop.merchantID === 'coupang' ?
                                    <LinkRouter to='/coupang'>
                                        <CardActionArea>
                                            <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                                            />
                                            <CardContent className={classes.content}>
                                                <Typography style={{ fontSize: 14 }}>
                                                    {prop.merchantName}
                                                </Typography>
                                                <Typography variant="body2" style={{fontSize:11}} color="textSecondary" component="p">
                                                    ({prop.merchantCategory})
                                                        </Typography>
                                                        <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </LinkRouter>
                                    :
                                    // <Link href={prop.baseURL + getUserIDParam(userKey, prop.merchantID)} target="_blank" underline="none">
                                        <CardActionArea onClick={() => window.open(prop.baseURL + getUserIDParam(userKey, prop.merchantID), '_blank')}>
                                            <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                                            />
                                            <CardContent className={classes.content}>
                                                <Typography style={{ fontSize: 14 }}>
                                                    {prop.merchantName}
                                                </Typography>
                                                <Typography variant="body2" style={{fontSize:11}} color="textSecondary" component="p">
                                                    ({prop.merchantCategory})
                                                        </Typography>
                                                        <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    // </Link>
                                }
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function makeCategoryListTab(props) {
        // console.log(props)
        return (
            props.map((prop, index) => (
                <Tab {...a11yProps(index)} style={{ color: 'white' }} onClick={() => setSelectedCategory(prop.categoryName)} label={prop.categoryName} value={index} key={prop.categoryName} />
            ))
        )
    }

    function filterAppBar(event, newValue) {
        if (selectedCategory !== undefined) {
            setValue(newValue);
            setAppBarFilteredList(getCategoryFilterList(selectedCategory, data.dataList))
        }
        // mobileFilterList(newValue);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Paper className={classes.root} >
                <CssBaseline />
                <Paper elevation={0} className={classes.root} style={{justifyContent:"center"}}>
                    <Container maxWidth="md" style={{ padding: 0 }}>
                        <div>
                            {category === null || data === null ? loading() :
                                <div className={classes.appBarTab}>
                                    <AppBar position="static" className={classes.appBarColor} >
                                    {/* <AppBar position="static"  style={{backgroundColor:'#5b32ba'}}> */}
                                        <Tabs
                                            value={value}
                                            onChange={filterAppBar}
                                            indicatorColor="secondary"
                                            // textColor="black"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable tabs"
                                        >
                                            {makeCategoryListTab(category.dataList)}
                                        </Tabs>
                                    </AppBar>

                                </div>
                            }
                        </div>
                        {/* <div className={classes.title}> */}
                        {/* </div> */}
                            <TextField margin="dense" className={classes.searchField} variant="outlined" id="standard-search" label="카테고리별 쇼핑몰 이름을 검색해보세요" type="search" onChange={filterList} />
                        <div>
                            {data === null || category === null ? loading() : filteredList !== undefined ? merchantListCardWithSearch(filteredList) : merchantListCard(data, category)}
                        </div>
                    </Container>
                </Paper>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"로그인 없이 이용하시려구요 ?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            로그인 없이도 구매가 가능하지만,<br />
                            구매형 추첨대상에 포함되지 않아요.<br />
                            로그인 후 구매 시, 당첨 가능성 업 !
              </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" style={{color:"#616161"}}>
                            닫기
            </Button>
                        <Button onClick={handleCloseAndOpenLink} color="secondary" style={{color:"#616161"}}>
                            그냥 이용할래요
            </Button>
                        <LinkRouter to='/login'>
                            <Button onClick={handleClose} color="primary" autoFocus>
                                로그인
                </Button>
                        </LinkRouter>
                    </DialogActions>
                </Dialog>
            </Paper>
        </React.Fragment>
    );
}
import React from 'react';
import HowToUseMain from '../components/body/HowToUseMain';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import { Helmet } from 'react-helmet'


const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingBottom:20
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0),
      // paddingBottom: '10px',
    },
  },
}));

export default function HowToUse() {
  const classes = useStyles();
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <Helmet>
        <title>이용방법 | 경품달(경품을 품은 달)</title>
        <meta name="description" content="경품달 이용방법 | 추첨대상이 되는방법 | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
        <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
        <meta name="author" content="병맛사람들" />
      </Helmet>
      <CssBaseline />
      <Paper className={classes.root}>
        <Container maxWidth="md" style={{padding:0}}>
          <HowToUseMain />
        </Container>
      </Paper>
    </React.Fragment>
  );
}
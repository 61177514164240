import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import NotFound404 from '../assets/img/undraw/404_not_found.png'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  innerPaper: {
    paddingTop:20,
    paddingBottom:20,
  },
}));

export default function CelebrateSingUp() {
  const classes = useStyles();
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <Helmet>
          <title>404 페이지가 없습니다 | 경품달(경품을 품은 달)</title>
          <meta name="description" content="404 NOT FOUND" />
          <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
          <meta name="author" content="병맛사람들" />
          <meta name="prerender-status-code" content="404"/>
      </Helmet>
      <CssBaseline />
      <Paper className={classes.root}>
        <Container maxWidth="md">
          <Paper elevation={2} className={classes.innerPaper}>
            <div>
              <img style={{width:'60%'}} src={NotFound404} alt="404_페이지없음_이미지" />
              <br/>
              <Typography variant="h6" component="h3" >
                이 페이지는 존재하지 않습니다.
              </Typography>
            </div>
          </Paper>
        </Container>
      </Paper>
    </React.Fragment>
  );
}
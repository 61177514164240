import React from 'react';
import HomeMainPrize from '../components/body/HomeMainPrize';
import HomeHowToUse from '../components/body/HomeHowToUse';
/* 쿠팡주문번호 난수화로 인한 삭제 */
// import HomeMobileProduct from '../components/body/HomeMobileProduct'
// import HomeMobileAboutKPD from '../components/body/HomeMobileAboutKPD'
import HomeMerchantCategory from '../components/body/HomeMerchantCategory'
// import HomeEventModal from '../components/body/HomeEventModal'
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import HomeCarousel from '../components/body/HomeCarousel'
import HomeSetupChromeNotification from '../components/body/HomeSetupChromeNotification'
import HomeCarouselForIE from '../components/body/HomeCarouselForIE'
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
// import Fade from "@material-ui/core/Fade";
import { Helmet } from 'react-helmet'
import MobileSplashImg from '../assets/img/kyungpumdal_mobile_splash.jpg'
import cookie from 'react-cookies'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 0),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: 'white',
    padding: 0,
    width: "100%",
    height: "100%",
  }
}));

export default function Home() {
  const classes = useStyles();

  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isMobile = (window.innerWidth <= 760);
  const [open, setOpen] = React.useState(isMobile);

  function closeModal(){
    setOpen(false)
  }

    const structuredJSON = 
    JSON.stringify({
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": "https://kyungpumdal.com/",
        "name": "경품달 HOME | 밑지는 복권 본전인 쇼핑 | 매달추첨 경품을 품은 달",
        "author": {
        "@type": "Organization",
        "name": "병맛사람들"
        },
        "description": "가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 드리는 그날까지 ! | 투표기반 공정 추첨 | 140여개 쇼핑몰(쿠팡포함) | 해외직구도 ! | HOME",
        "sameAs" : [
        "https://www.facebook.com/kyungpumdal",
        "https://www.instagram.com/dykwon_",
        "https://www.youtube.com/channel/UCeK-HCBpMozxnf-r7-RAM9Q",
        "https://blog.naver.com/kyungpumdal"
        ]
      });

  function HomeMain(){
    window.scrollTo(0, 0);
    return (
    <Paper className={classes.root}>
        {/* <HomeEventModal /> */}
        {isIE === true || isEdge === true ? <HomeSetupChromeNotification /> : ''}
        {/* {((isIE === true || isEdge === true))? <HomeCarouselForIE /> : isMobile!==true && <HomeCarousel />} */}
        {((isIE === true || isEdge === true))? <HomeCarouselForIE /> : <HomeCarousel />}
        {/* 쿠팡주문번호 난수화로 인한 삭제 */}
        {/* {(isMobile === true && isIE !== true && isEdge !== true) && <HomeMobileProduct/>} */}
        {/* {(isMobile === true && isIE !== true && isEdge !== true) && <HomeMobileAboutKPD/>} */}
        <HomeMainPrize />
        <HomeHowToUse />
        <HomeMerchantCategory />
      </Paper>
    )
  }

  if(isMobile===true && cookie.load("mobileSplashCookie") !== "true" ){
    setTimeout(splashCookieSave,2000);
    setTimeout(closeModal, 2000);
  }
  
  function splashCookieSave(){
    cookie.save('mobileSplashCookie', true)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>경품달 HOME | 밑지는 복권 본전인 쇼핑 | 매달추첨 경품을 품은 달</title>
        <meta name="description" content="가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 드리는 그날까지 ! | 투표기반 공정 추첨 | 140여개 쇼핑몰(쿠팡포함) | 해외직구도 !" />
        <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
        <meta name="author" content="병맛사람들" />
        <script className='structured-data-list' type="application/ld+json">{structuredJSON}</script>
      </Helmet>
      <CssBaseline />

      {(isMobile === true && open === true && cookie.load("mobileSplashCookie") !== "true") ?
      // {(isMobile === true && open === true) ?
        <Modal
          aria-labelledby="transition-modal"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
            <div className={classes.paper}>
              <img style={{width:'100%', height:'100%'}} alt='경품달_모바일_스플래시' src={MobileSplashImg} />
            </div>
        </Modal>
    : <HomeMain/>
    }
      
      
    </React.Fragment>
  );
}
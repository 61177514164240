import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Celebrate from '../assets/img/undraw/celebration.png'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  innerPaper: {
    paddingTop:0,
    paddingBottom:20,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  sectionMobile: {
    display: 'inline',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function CelebrateSingUp() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet>
      <title>회원가입을 축하합니다 | 경품달(경품을 품은 달)</title>
      <meta name="description" content="경품달 회원가입을 축하합니다. | 가입만해도 매달 경품이벤트 추첨대상 | 쿠팡을 포함한 140여개 쇼핑몰이 이곳에 | 집과 땅을 경품으로 드리는 그날까지 !" />
      <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
      <meta name="author" content="병맛사람들" />
      </Helmet>
      <CssBaseline />
      <Paper className={classes.root}>
          <Paper elevation={0} className={classes.innerPaper}>
        <Container maxWidth="md">
            <div>
              <img src={Celebrate} alt='회원가입_축하' style={{ width: '50%', paddingTop: 0, paddingBottom: 10 }} />
              <div className={classes.sectionDesktop}>
              <Typography variant="h6" component="h3" >
                회원가입을 진심으로 축하드립니다
              </Typography>
              <br/>
              <Typography variant="h6" component="h3" >
                마이페이지에서 선택정보입력 및 알림 수신동의 시,<br />
                매달 무료로 경품 추첨대상(가입형)이 되실 수 있어요 !<br /><br/>
                항상 공정한 경품행사를 위해, 노력하겠습니다.
              </Typography>
              </div>
              <div className={classes.sectionMobile}>
              <Typography variant="body2" component="h3" >
                회원가입을 진심으로 축하드립니다
              </Typography>
              <br/>
              <Typography variant="body2" component="h3" >
                선택정보입력 및 알림 수신동의 시,<br />
                매달 무료로 경품 추첨대상!(가입형)<br /><br/>
                공정한 경품문화를 위해, 노력하겠습니다.<br/>
              </Typography>
              </div>
            </div>
            <br />
            <div>
              <Link to='/how-to-use'>
                <Button color="primary" variant="contained" style={{backgroundColor:'#3f51b5'}}>
                  <Typography className={classes.sectionDesktop}>
                    이용방법 보기
                  </Typography>
                  <Typography className={classes.sectionMobile} style={{fontSize:'12px'}}>
                    이용방법 보기
                  </Typography>
                </Button>
              </Link>
              <br/>
            </div>
        </Container>
          </Paper>
      </Paper>
    </React.Fragment>
  );
}
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import { Container } from '@material-ui/core';
import AdBlockImg from '../../assets/img/adblock.png'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0),
  },
  adblockText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.4rem',
    },
    color:'#f7faff',
  },
  AdblockImg: {
    width: '100%',
  }
}));

export default function AdBlockMessage() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper elevation={5} className={classes.root} style={{backgroundColor:"grey"}}>
        <Container maxWidth='md'>
          <Grid container spacing={0} alignItems="center" justify="center">
            <Grid item xs={1}>
              <img src={AdBlockImg} alt='adblock_허용요청' className={classes.AdblockImg} />
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h7" component="h3" className={classes.adblockText}>
                저도 AdBlock을 참 좋아하는데요 :) 그렇지만..<br />
                AdBlock에서 경품달 사이트를 꼭 허용 해주세요 !<br/>
                추첨점수 적립 및 일부기능이 동작하지 않을 수 있어요.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper >
    </React.Fragment >
  );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import TokenContext from '../ContextAPI'
import Typography from '@material-ui/core/Typography'
// import ApartmentIcon from '@material-ui/icons/Apartment';
// import FaceIcon from '@material-ui/icons/Face';
// import HowToVoteIcon from '@material-ui/icons/HowToVote';
// import SingleBedIcon from '@material-ui/icons/SingleBed';
// import PanToolIcon from '@material-ui/icons/PanTool';
// import GavelIcon from '@material-ui/icons/Gavel';
import Container from '@material-ui/core/Container'
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'inline',
        justifyContent: 'center',
    },
}));

export default function HomeCarouselForIE() {
    const classes = useStyles();
    // const tokenContext = React.useContext(TokenContext);
    // const nickName = tokenContext.nickName;
    const { width } = useWindowSize();

    React.useEffect(() => {
        let isMounted = true;
        let slideIndex = 0;

        function showSlides() {
            let slideTimeOut;
            if (window.location.pathname === "/") {
                let i;
                let slides = document.getElementsByClassName("mySlides");
                for (i = 0; i < slides.length; i++) {
                    slides[i].style.display = "none";
                }
                slideIndex++;
                if (slideIndex > slides.length) { slideIndex = 1 }

                slides[slideIndex - 1].style.display = "block";
                slideTimeOut = setTimeout(showSlides, 2500); // Change image every 2 seconds
            } else {
                clearTimeout(slideTimeOut)
            }
        }

        if (isMounted === true && window.location.pathname === "/") {
            showSlides();
        }

        return () => isMounted = false;
    }, []);

    return (
        <div style={{backgroundColor:'#263880'}}>
             <Confetti
                            width={width}
                            height={'420px'}
                            numberOfPieces={50}
                            gravity={0.07}
                        />
        <Container justify='center' className={classes.container}>              
            <div className="slideshow-container">
                <div className="mySlides fade" style={{ minHeight: '90px', padding: '90px', color:'white' }}>
                    <Typography variant="h3" component="h3" >
                    밑지는 복권! 본전인 쇼핑!
                </Typography>
                </div>
                <div className="mySlides fade" style={{ minHeight: '90px', padding: '90px', color:'white' }}>
                <Typography variant="h3" component="h3" >
                평소처럼! 쇼핑하면! 경품추첨!
                </Typography>
                </div>
                <div className="mySlides fade" style={{ minHeight: '90px', padding: '90px', color:'white' }}>
                <Typography variant="h3" component="h3" >
                실시간! 추첨방송! 경품골라!
                        </Typography>
                </div>
                <div className="mySlides fade" style={{ minHeight: '90px', padding: '90px', color:'white' }}>
                        <Typography variant="h3" component="h3" >
                        당첨금액! 안에서면! 뭐든 OK!
                        </Typography>
                </div>
                <div className="mySlides fade" style={{ minHeight: '90px', padding: '90px', color:'white' }}>
                        <Typography variant="h3" component="h3" >
                        내가될까? 진짜랜덤! 공정하다!
                        </Typography>
                </div>
                <div className="mySlides fade" style={{ minHeight: '90px', padding: '90px', color:'white' }}>
                        <Typography variant="h3" component="h3" >
                        고객님의! 개인정보! 철저하게!
                        </Typography>
                </div>
                <div className="mySlides fade" style={{ minHeight: '90px', padding: '90px', color:'white' }}>
                        <Typography variant="h3" component="h3" >
                        매달추첨! 스마트쇼핑! 참 쉽죠?
                        </Typography>
                </div>
            </div>
        </Container>
            </div>

    );
}
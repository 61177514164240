import React from 'react';
import '../assets/css/App.css';
import dotenv from 'dotenv';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer"
import Box from '@material-ui/core/Box'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import AdBlockDetect from 'react-ad-block-detect';
import AdBlockMessage from '../components/header/AdBlockMessage';
import cookie from 'react-cookies';
import TokenContext from '../components/ContextAPI';
import { createBrowserHistory as createHistory } from 'history';
//PAGE IMPORT
import Home from "./Home";
import TermsOfPrivacy from "./TermsOfPrivacy";
import TermsOfUse from "./TermsOfUse";
import TermsOfLocation from "./TermsOfLocation";
// import PurchaseCert from "./PurchaseCert";
import Login from './Login';
import Logout from './Logout';
import SignUp from './SignUp';
import MyPage from './MyPage';
import DomesticShop from './DomesticShop';
import OverseasShop from './OverseasShop';
import Tour from './Tour';
import HowToUse from './HowToUse';
import PrizeEvent from './PrizeEvent';
// import DrawVote from './DrawVote';
    /* 쿠팡주문번호 난수화로 인한 삭제 */
// import Coupang from './Coupang';
import CelebrateSingUp from './CelebrateSingUp'
import DiscountCode from './DiscountCode'
import NoMatch from './NoMatch'
import ShopMaintenance from './ShopMaintenance'

dotenv.config();

const theme = createTheme({
  typography: {
    fontFamily: 'Godo',
  },
});

function isExpiredToken(jwtToken){
  var jwtDecode = require('jwt-decode');
  var jwtTokenBodyDecoded = jwtDecode(jwtToken)
  // console.log(jwtTokenBodyDecoded)
  
  const curDateTime = new Date();
  const expireDateTime = new Date(jwtTokenBodyDecoded.exp*1000);

  if(expireDateTime < curDateTime){
    return true;
  }else{
    return false;
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token : cookie.load('token') !== undefined ? cookie.load('token') : undefined,
      userKey : undefined,
      nickName : undefined,
      userID:undefined,
    };
  }

  setObjectFromToken = (jwtToken) => {
    var jwtDecode = require('jwt-decode');
    var jwtTokenBodyDecoded = jwtDecode(jwtToken)
  
    // const base64 = require('base-64');
    // const utf8 = require('utf8');
    // const jwtTokenArr = jwtToken.split('.');
    // const jwtTokenBodyDecoded = JSON.parse(utf8.decode(base64.decode(jwtTokenArr[1])));
    this.setState({userKey:jwtTokenBodyDecoded.jti});
    this.setState({nickName:jwtTokenBodyDecoded.nickName})
    this.setState({userID:jwtTokenBodyDecoded.sub})
  }

  componentDidMount(){
    // 쿠키값이 있는지 확인 후, 있으면 토큰을 state에 세팅
    if(cookie.load('token') !== undefined){
      //만일 토큰이 Expired 되었다면 logout 페이지로 이동
      if(isExpiredToken(cookie.load('token'))){
        this.setState({token : 'expiredToken'});
      }else{
        this.setState({token : cookie.load('token')});
        this.setObjectFromToken(cookie.load('token'))
      }
    // 없으면 undefined를 state에 세팅
    }else{
      this.setState({token : undefined})
    }
  }

  render() {
    const history = createHistory();   
    const { token } = this.state;
    const { userKey } = this.state;
    const { nickName } = this.state;
    const { userID } = this.state;
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        token !== undefined ? <Component {...props} /> : <Redirect to='/login' />
      )} />
    )

    return (
      <div className="App">
        <div>
          <Box>
            <TokenContext.Provider value={{token, userKey, nickName, userID}}>
              <MuiThemeProvider theme={theme}>
                <Router history={history}>
                  <span id="back-to-top-anchor"></span>
                  <Header token={token}  />
                  <AdBlockDetect><AdBlockMessage /></AdBlockDetect>
                  <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/logout" component={Logout} />
                  <Route exact path="/sign-up" component={SignUp} />
                  {/* 쿠팡주문번호 난수화로 인한 삭제 */}
                  {/* <Route exact path="/coupang" component={Coupang} /> */}
                  <PrivateRoute exact path="/mypage" component={MyPage} />
                  <Route exact path="/domestic-shop" component={DomesticShop} />
                  <Route exact path="/overseas-shop" component={OverseasShop} />
                  <Route exact path="/tour" component={Tour} />
                  <Route exact path="/how-to-use" component={HowToUse} />
                  <Route exact path="/prize-event" component={PrizeEvent} />
                  {/* 추첨 자동화로 인한 삭제 */}
                  {/* <Route exact path="/draw-vote" component={DrawVote} /> */}
                  {/* 쿠팡주문번호 난수화로 인한 삭제 */}
                  {/* <PrivateRoute exact path="/purchase-cert" component={PurchaseCert} /> */}
                  <Route exact path="/terms-of-use" component={TermsOfUse} />
                  <Route exact path="/terms-of-privacy" component={TermsOfPrivacy} />
                  <Route exact path="/terms-of-location" component={TermsOfLocation} />
                  <Route exact path="/celebrate-sign-up" component={CelebrateSingUp} />
                  <Route exact path="/discount-code" component={DiscountCode} />
                  <Route exact path="/temporary-maintenance" component={ShopMaintenance} />
                  <Route component={NoMatch} />
                  </Switch>
                  <Footer />
                </Router>
              </MuiThemeProvider>
            </TokenContext.Provider>
          </Box>
        </div>
      </div>
    );
  }
}

export default App;

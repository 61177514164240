import React,{useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
// import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
// import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
// import tutorialImg from '../../assets/img/undraw/tutorial_main.png'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
// import HowToDrawVoteImgMobile1 from '../../assets/img/how_to_use/drawvote/how_to_draw_vote_mobile1.png'
// import HowToDrawVoteImgMobile2 from '../../assets/img/how_to_use/drawvote/how_to_draw_vote_mobile2.png'
// import HowToDrawVoteImg1 from '../../assets/img/how_to_use/drawvote/how_to_draw_vote1.png'
// import HowToDrawVoteImg2 from '../../assets/img/how_to_use/drawvote/how_to_draw_vote2.png'
// import HowToDrawVoteImg3 from '../../assets/img/how_to_use/drawvote/how_to_draw_vote3.png'
// import HowToDrawVoteImg4 from '../../assets/img/how_to_use/drawvote/how_to_draw_vote4.png'
import HowToPurchaseDrawImg1 from '../../assets/img/how_to_use/purchase_draw/how_to_purchase_draw1.png'
import HowToPurchaseDrawImg2 from '../../assets/img/how_to_use/purchase_draw/how_to_purchase_draw2.png'
import HowToPurchaseDrawImgMobile1 from '../../assets/img/how_to_use/purchase_draw/how_to_purchase_draw_mobile1.png'
import HowToPurchaseDrawImgMobile2 from '../../assets/img/how_to_use/purchase_draw/how_to_purchase_draw_mobile2.png'
import HowToJoinDraw1 from '../../assets/img/how_to_use/join_draw/how_to_join_draw1.png'
import HowToJoinDraw2 from '../../assets/img/how_to_use/join_draw/how_to_join_draw2.png'
import HowToJoinDrawMobile1 from '../../assets/img/how_to_use/join_draw/how_to_join_draw_mobile1.png'
import HowToJoinDrawMobile2 from '../../assets/img/how_to_use/join_draw/how_to_join_draw_mobile2.png'
import Divder from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TokenContext from '../ContextAPI'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SendGiftOnePerson from '../../assets/img/undraw/send_gift_by_one_person.png';
// import VotingWithPeople from '../../assets/img/undraw/voting_with_people.png';
import OnlineMedeia from '../../assets/img/undraw/online_media.png';
import TrngFairLogic from '../../assets/img/undraw/encrypted_fair_logic_trng.png';
import MyPeopleWillBuyHouse from '../../assets/img/undraw/kyungpumdal_customer_will_get_house_for_prize.png';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
      <Typography
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          <Box p={3}>{children}</Box>
      </Typography>
  );
}

const useStyles = makeStyles(theme => ({
    
    prizeTextHead: {
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'inline',
      },
    },
    sectionMobile: {
      display: 'inline',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    howToUseImageSize: {
      [theme.breakpoints.up('md')]: {
        marginTop: '30px',
        width: '80%',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: '10px',
          width: '80%',
      },
    },
    howToUseImageSizeBit: {
      marginTop: '40px',
      [theme.breakpoints.up('md')]: {
          width: '40%',
      },
      [theme.breakpoints.down('md')]: {
          width: '50%',
      },
    },
    topSpacing: {
      marginTop: '20px'
    },
    appBarColor: {
      // [theme.breakpoints.down('md')]: {
          backgroundColor:'#263880'
      // },
    },
    prizeTextForMobile13: {
      [theme.breakpoints.down('md')]: {
        fontSize:'13px',
      },
    },
  }));
  
  const isMobile = (window.innerWidth <= 760);

export default function HowToUseMain() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const tokenContext = useContext(TokenContext);

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    const handleChangeOpen = () => {
      setOpen(true);
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    // const handleClick = (newValue) => {
    //   setValue(newValue);
    // };
    window.scrollTo(0, 0);

    function howToJoinType() {
      return (
        <div>
          <br/>
          <Typography className={classes.sectionDesktop} variant="body1" component="h3">
          '가입형 추첨대상' 이란,<br/><span style={{ fontWeight: 'bold'}}>알림 수신동의 및 선택정보를 입력</span>하시면<br />
            매달 무료로 경품 추첨대상이 되는 것을 말합니다.<br/>
          </Typography>
          <Typography style={{textAlign:'left'}} className={classes.sectionMobile} variant="body2" component="h3">
            '가입형 추첨대상' 이란,<br/><span style={{ fontWeight: 'bold', fontSize: '14px' }}>알림 수신동의 및 선택정보를 입력</span>하시면<br />
            매달 무료로 경품 추첨대상이 되는 것을 말합니다.<br/>
          </Typography>
     
          <br/>
          <Divder/>
          <br/>
          <Typography className={classes.sectionDesktop} variant="body1" component="h3" >
            1. 로그인 후, 마이페이지 메뉴를 클릭합니다.
          </Typography>
          <Typography className={classes.sectionMobile} variant="body2" component="h3" >
            1. 로그인 후, 마이페이지 메뉴 클릭!
          </Typography>
          <div className={classes.sectionDesktop}>
          <img src={HowToJoinDraw1} alt='경품달_가입형추첨_설명1' className={classes.howToUseImageSize}/>
          </div>
          <div className={classes.sectionMobile}>
          <img src={HowToJoinDrawMobile1} alt='경품달_가입형추첨_설명1' className={classes.howToUseImageSize}/>
          </div>
          <div>
            <br/><br/><Divder/>
          <Typography className={classes.sectionDesktop} variant="body1" component="h3" >
            <br/><br/>2. "알림 수신 및 선택정보 수집 동의" 에 체크 후,<br/>선택정보를 입력하시고, 수정하기 버튼을 눌러주세요.
          </Typography>
          </div>
          <Typography className={classes.sectionMobile} variant="body2" component="h3" >
          <br/>2. "알림 수신 및 선택정보 수집 동의" 체크!<br /> 선택정보를 입력 후, 수정하기를 클릭 !
          </Typography>
          <div className={classes.sectionDesktop}>
            <img src={HowToJoinDraw2} alt='경품달_가입형추첨_설명2' className={classes.howToUseImageSizeBit}/>
          </div>
          <div className={classes.sectionMobile}>
            <img src={HowToJoinDrawMobile2} alt='경품달_가입형추첨_설명2' className={classes.howToUseImageSize}/>
          </div>
          <br/><br/>
        </div>
      )
    }

    function howToPurchaseType() {
      return (
        <div>
          <Typography className={classes.sectionDesktop} variant="body1" component="h3">
            <br/>'구매형 추첨대상' 이란,<br />
            로그인 후, <span style={{ fontWeight: 'bold' }}>경품달을 통해 제품을 구매</span> 했을 때<br />
            추첨점수 적립 및 경품 추첨대상이 되는 것을 말합니다.<br />
          </Typography>
          <Typography className={classes.sectionMobile} variant="body2" component="h3">
            <br/>'구매형 추첨대상' 이란,<br />
            로그인 후, <span style={{ fontWeight: 'bold', fontSize: '14px' }}>경품달을 통해 제품 구매</span> 시, <br />
            추첨점수 적립 및 경품 추첨대상이 되는 것을 말합니다.<br />
          </Typography>
          <br/>
          <Divder/>
          {/* <Button variant="outlined" size="medium" color="primary" value={value} index={0} onClick={() => handleClick(0)}>가입형 추첨 방법 보기</Button> */}
          <Typography className={classes.sectionDesktop} variant="body1" component="h3" >
          <br/><br/>1. 로그인 후, 쇼핑몰메뉴를 클릭합니다.<br/>(국내몰, 해외몰, 여행/티켓)<br/>
          </Typography>
          <Typography className={classes.sectionMobile} variant="body2" component="h3" >
          <br/>1. 로그인 후, 쇼핑메뉴 클릭 !<br/>(국내몰, 해외몰, 여행/티켓)<br/>
          </Typography>
          <div className={classes.sectionDesktop}>
          <img src={HowToPurchaseDrawImg1} alt='경품달_구매형추첨_설명1' className={classes.howToUseImageSize}/><br/><br/>
          </div>
          <div className={classes.sectionMobile}>
          <img src={HowToPurchaseDrawImgMobile1} alt='경품달_구매형추첨_설명1' className={classes.howToUseImageSize}/><br/><br/>
          </div>
          <Divder/>

          <Typography className={classes.sectionDesktop} variant="body1" component="h3" >
          <br/><br/> 2. 원하는 쇼핑몰을 클릭 하신 후, 제품을 구매하시면 끝 !<br/><br/>
          </Typography>
          <div className={classes.sectionDesktop}>
          <Typography  variant="body2" component="h3" >
            ※ 쇼핑몰별로 추첨점수 적립률이 다릅니다<br/>
            {/* 쿠팡주문번호 난수화로 인한 삭제 */}
            {/* ※ 쿠팡의 경우 꼭! 구매인증을 진행해주세요<br/> */}
            ※ 월별 총경품가액은 익익월 7일에 확정됩니다<br/>
          </Typography>
          </div>
          <Typography className={classes.sectionMobile} variant="body2" component="h3" >
          <br/> 2. 원하는 쇼핑몰을 선택 후, 제품 구매 ! 끝 !<br/>
          </Typography>
          <div className={classes.sectionDesktop}>
            <img src={HowToPurchaseDrawImg2} alt='경품달_구매형추첨_설명2' className={classes.howToUseImageSizeBit}/>
          </div>
          <div className={classes.sectionMobile}>
            <img src={HowToPurchaseDrawImgMobile2} alt='경품달_구매형추첨_설명2' className={classes.howToUseImageSize}/>
          </div>
          <br/>

                <div className={classes.sectionDesktop}>
                <br/>
            {tokenContext.token !== undefined && tokenContext.token !== null && tokenContext.token !== '' ?
               <RouterLink to='/mypage'>
               <Button variant="outlined" style={{color:'#263880', borderColor:"#263880"}}>
             <Typography variant="body1" component="h3" style={{color:'#263880'}}>
               내 추첨점수(순위)
             </Typography>
             </Button>
             </RouterLink>
              :
              <Button variant="outlined"  onClick={handleChangeOpen} style={{color:'#263880', borderColor:"#263880"}}>
             <Typography variant="body1" component="h3" style={{color:'#263880'}}>
              내 추첨점수(순위)
             </Typography>
             </Button>
            }
                <br/>
                </div>

          <div className={classes.sectionMobile}>
          <Typography className={classes.topSpacing} style={{fontSize:'12px'}} component="h3" >
            ※ 쇼핑몰별로 추첨점수 적립률이 다릅니다<br/>
            {/* 쿠팡주문번호 난수화로 인한 삭제 */}
            {/* ※ 쿠팡의 경우 꼭! 구매인증을 진행해주세요<br/> */}
            ※ 월별 총경품가액은 익익월 7일에 확정됩니다<br/>
          </Typography>
          </div>

          <br/>
        </div>
      )
    }

    function howToPurchaseCert() {
      return (
        <div>
          <Divder/><br/>
          <Typography className={classes.sectionDesktop} variant="body1" component="h3">
            국내몰, 해외몰, 여행/티켓 구매 시,<br />
            <span style={{ fontWeight: 'bold'}}>자동으로 구매인증 및 추첨점수 적립 !</span><br />
          </Typography>
          <Typography className={classes.sectionMobile} variant="body2" component="h3">
            국내몰, 해외몰, 여행/티켓 구매 시,<br />
            <span style={{ fontWeight: 'bold', fontSize: '14px' }}>자동으로 구매인증 및 추첨점수 적립 !</span><br />
          </Typography>
          <br/>
          <Typography variant="body2" align="center" className={classes.prizeTextForMobile12}>
              ※ 단, 쇼핑몰별로 자동인증 시간이 상이하여<br/>
              추첨점수 갱신까지 일정 시간이 소요됩니다.<br/>
              ※ 월별 추첨점수는 익익월 7일에 확정됩니다.
            </Typography>
            {/* <br/>
            <Divder/> */}

            {/* 쿠팡주문번호 난수화로 인한 삭제 */}
          {/* <Typography className={classes.sectionDesktop} variant="body1" component="h3">
            <br/><span style={{ fontWeight: 'bold' }}>쿠팡</span>에서 구매 시,
            추첨대상이 되기위해<br/> 꼭! <span style={{ fontWeight: 'bold' }}>수동 구매인증</span>을 진행해주셔야 합니다.<br />
          </Typography> */}
          <Typography variant="body2" align="center" className={classes.sectionDesktop}>
           
            </Typography>
            {/* 쿠팡주문번호 난수화로 인한 삭제 */}
          {/* <Typography className={classes.sectionMobile} variant="body2" component="h3">
          <br/><span style={{ fontWeight: 'bold', fontSize: '14px' }}>쿠팡 </span>구매 시,
            추첨대상이 되기위해<br/> 꼭! <span style={{ fontWeight: 'bold', fontSize: '14px' }}>수동 구매인증</span>을 진행해주셔야 합니다.<br />
          </Typography><br/> */}
          {/* {tokenContext.token !== undefined && tokenContext.token !== null && tokenContext.token !== '' ?
               <RouterLink to='/purchase-cert'>
               <Button variant="outlined" style={{color:'#263880',borderColor:'#263880'}}>
               <Typography variant="body1" className={classes.prizeTextForMobile13}>
                  수동인증 방법
                </Typography>
             </Button>
             </RouterLink>
              :
              <Button variant="outlined"  onClick={handleChangeOpen} style={{color:'#263880',borderColor:'#263880'}}>
                <Typography variant="body1" className={classes.prizeTextForMobile13}>
                  수동인증 방법
                </Typography>
             </Button>
            } */}
          <br/>
          <br/>
        </div>
      )
    }

    function howToDrawEventInfo() {
      return (
        <div>
            <br/>
            <Grid container justify='center'>
              <Grid item md={9} xs={12}>
                <img style={{width:'40%'}} src={SendGiftOnePerson} alt='경품달_추첨행사_설명1' />
              </Grid>
              <Grid item md={9} xs={12}>
                <div className={classes.sectionDesktop}>
                <Typography variant="body1" component="h3" >
                  조사결과 경품행사 공정성 하락의 주원인은 인적사고
                </Typography>
                {/* <Typography variant="body2" component="h3" >
                  [주작[做作] :  주최자가 추첨권한을 갖기 때문에 문제발생]
                </Typography> */}
                </div>
                <div className={classes.sectionMobile}>
                <Typography variant="body2" component="h3" >
                  공정성 하락의 주원인은 인적사고 !
                </Typography>
                </div>
                {/* <Typography variant="body2" component="h3" >
                  (유튜버, 마트, 나쁜기업 등 씁쓸한 현실 <SentimentVeryDissatisfiedIcon style={{fontSize:'13px'}}/>)
                </Typography> */}
                <br/>
              </Grid>
              {/* <Grid item md={9} xs={12}>
              <Divider />
                <img style={{width:'55%'}} src={VotingWithPeople} alt='경품달_추첨행사_설명2' />
              </Grid>
              <Grid item md={9} xs={12}>
                <div className={classes.sectionDesktop}>
                <Typography variant="body1" component="h3" >
                  그러나, 경품달은 회원간 투표를 통해 추첨진행자를 선정 !
                </Typography>
                <Typography variant="body1" component="h3" >
                  추첨진행자에 선정된 회원님은, 사은품과 추첨권한을 부여 !
                </Typography>
                <Typography variant="body2" component="h3" >
                  [여러분의 참여가 공정한 경품문화를 만듭니다]
                  <br/> <br/>
                </Typography>
                </div>
                <div className={classes.sectionMobile}>
                <Typography variant="body2" component="h3" >
                  But, 경품달은 투표를 통해 추첨진행자를 선정 !
                </Typography>
                <Typography variant="body2" component="h3" >
                  선정된 회원님은, 사은품과 추첨권한 부여!
                </Typography>
                <Typography variant="body2" component="h3" >
                  (여러분이 공정한 경품달을 만듭니다)<br/><br/>
                </Typography>
                </div>
              </Grid> */}
              <Grid item md={9} xs={12}>
              <Divider />
                <img style={{width:'55%'}} src={OnlineMedeia} alt='경품달_추첨행사_설명3' />
              </Grid>
              <Grid item md={9} xs={12}>
                <div className={classes.sectionDesktop}>
                <Typography variant="body1" component="h3" >
                  사람이 아닌 컴퓨터가 자동추첨 !<br/>
                  매월 10일 20시에 당첨결과 확인 !
                </Typography>
                {/* <Typography variant="body2" component="h3" >
                  [잘아시는 복권처럼 녹화방송 노노 !]
                </Typography> */}
                </div>
                <div className={classes.sectionMobile}>
                <Typography variant="body2" component="h3" >
                  사람이 아닌 컴퓨터가 자동추첨 !<br/>
                  매월 10일 20시에 당첨결과 확인 !
                </Typography>
                {/* <Typography variant="body2" component="h3" >
                  [잘아시는 복권처럼 녹화방송 노노 !]
                </Typography> */}
                </div>
                <br/>
              </Grid>
              <Grid item md={9} xs={12}>
              <Divider />
                <img style={{width:'55%'}} src={MyPeopleWillBuyHouse} alt='경품달_추첨행사_설명4' />
              </Grid>
              <Grid item md={9} xs={12}>
                <div className={classes.sectionDesktop}>
                <Typography variant="body1" component="h3" >
                  당첨 고객님은 개별연락 드립니다<br/>
                  원하는 경품을 직접 선택하실 수 있어요 !
                </Typography>
                <Typography variant="body2" component="h3" >
                  [경품가액내라면, 집도 땅도 비트코인도 가능하죠]
                </Typography>
                </div>
                <div className={classes.sectionMobile}>
                <Typography variant="body2" component="h3" >
                  당첨 고객님은 개별연락 드립니다<br/>
                  원하는 경품을 직접 선택하실 수 있어요 !
                </Typography>
                <Typography variant="body2" component="h3" >
                  [경품가액내면, 집도 땅도 비트코인도 OK!]
                </Typography>
                </div>
                <br/>
              </Grid>
              <Grid item md={9} xs={12}>
              <Divider />
                <img style={{width:'50%'}} src={TrngFairLogic} alt='경품달_추첨행사_설명5' />
              </Grid>
              <Grid item md={9} xs={12}>
                <div className={classes.sectionDesktop}>
                <Typography variant="body1" component="h3" >
                  경품달의 난수생성 방식은 믿을 수 있는<br/>
                  아일랜드 회사의 TRNG 방식을 사용합니다.
                </Typography>
                <Typography variant="body2" component="h3" >
                  [아일랜드 TRNG 덕에 경품달은 조작 불가]
                </Typography>
                </div>
                <div className={classes.sectionMobile}>
                <Typography variant="body2" component="h3" >
                  경품달의 난수생성 방식은 믿을 수 있는<br/>
                  아일랜드 회사의 TRNG 방식을 사용합니다.
                </Typography>
                <Typography variant="body2" component="h3" >
                  [아일랜드 TRNG 덕에 경품달은 조작 불가]
                </Typography>
                </div>
                <br/>
              </Grid>
              <Grid item md={9} xs={12}>
              <Divider />
              </Grid>
              <Grid item md={9} xs={12}>
                <div className={classes.sectionDesktop}>
                <Typography variant="body1" component="h3" >
                  <br/>우리는 부조리, 불공정에 민감합니다<br />
                  공정한 경품문화를 선도하겠습니다 :D
                </Typography>
                {/* <Typography variant="h6" component="h3" >
                  - 경품달 -
                </Typography> */}
                </div>
                <div className={classes.sectionMobile}>
                <Typography variant="body2" component="h3" >
                  <br/>우리는 부조리, 불공정에 민감합니다<br />
                  공정한 경품문화를 선도하겠습니다 :D
                </Typography>
                {/* <Typography variant="body1" component="h3" >
                  - 경품달 -
                </Typography> */}
                </div>
              </Grid>
            </Grid>
          
          <br/>
          <br/>
        </div>
      )
    }


    // function howToDrawVote() {
    //   return (
    //     <div>
    //       <Typography className={classes.sectionDesktop} variant="body1" component="h3">
    //         {/* <br/> */}
    //         {/* <span style={{ fontWeight: 'bold' }}>경품행사 공정성하락의 주원인은 주작!</span> */}
    //         <br/>
    //         <span style={{ fontWeight: 'bold' }}>누구나 추첨 진행자</span>가 될 수 있는 기회를 제공하고,<br />
    //         투표를 통해 선정함으로써 <span style={{ fontWeight: 'bold' }}>공정성</span>을 유지하기 위한 시스템입니다.<br />
    //         {/* <span style={{ fontWeight: 'bold', fontSize: '20px' }}>100% 공개, 100% 공정 추첨</span> 시스템입니다.<br /> */}
    //         추첨진행자로 선정되시고, 함께 행사를 진행하시면 사은품을 드려요 :D<br/>
    //       </Typography>
    //       <Typography className={classes.sectionMobile} variant="body2" component="h3">
    //       {/* <br/>
    //       <span style={{ fontWeight: 'bold', fontSize: '14px' }}>경품행사 공정성하락의 주원인은 주작!</span> */}
    //       <br/>
    //         고객간 투표를 통해 진행자 선정 !<br />
    //         <span style={{ fontWeight: 'bold', fontSize: '14px' }}>누구나 추첨 진행자</span>가 될 수 있는 기회 제공 !<br />
    //         {/* <span style={{ fontWeight: 'bold', fontSize: '20px' }}>100% 공개, 100% 공정 추첨</span> 시스템입니다.<br /> */}
    //         함께 추첨을 진행하시면 사은품을 드려요 :D<br/>
    //       </Typography>
    //       <br/><Divder/><br/>

    //       <Typography className={classes.sectionDesktop} variant="body1" component="h3" >
    //         <br/>1. 로그인 후 '내가추첨' 메뉴를 클릭합니다.<br/>
    //       </Typography>
    //       <Typography className={classes.sectionMobile} variant="body2" component="h3" >
    //       1. 로그인 후 '내가추첨' 메뉴를 클릭 !<br/>
    //       </Typography>
    //       <div className={classes.sectionDesktop}>
    //       <img src={HowToDrawVoteImg1} alt='경품달_내가추첨_참여방법1' className={classes.howToUseImageSize}/><br/>
    //       </div>
    //       <div className={classes.sectionMobile}>
    //       <img src={HowToDrawVoteImgMobile1} alt='경품달_내가추첨_참여방법1' className={classes.howToUseImageSize}/><br/>
    //       </div>
    //       <br/><Divder/><br/>

    //       <Typography className={classes.sectionDesktop} variant="body1" component="h3" >
    //       <br/>2. 지원글을 작성한 후 '지원하기' 버튼을 클릭합니다.<br/>
    //       </Typography>
    //       <Typography className={classes.sectionMobile} variant="body2" component="h3" >
    //       2. 지원글 작성 후, '지원하기' 버튼을 클릭 !<br/>
    //       </Typography>
    //       <div className={classes.sectionDesktop}>
    //       <img src={HowToDrawVoteImg2} alt='경품달_내가추첨_참여방법2' className={classes.howToUseImageSize}/><br/>
    //       </div>
    //       <div className={classes.sectionMobile}>
    //       <img src={HowToDrawVoteImgMobile2} alt='경품달_내가추첨_참여방법2' className={classes.howToUseImageSize}/><br/>
    //       </div>          <br/>
    //       <Divder /><br/>
    //       <div className={classes.sectionDesktop} >
    //       <Typography variant="body1" component="h3" >
    //         <br/>3. 지원 글이 입력되었어요 ! <br/>누군가 벌써 내 글에 좋아요를 눌렀네요 :)<br/>
    //       </Typography>
    //       <img src={HowToDrawVoteImg3} alt='경품달_내가추첨_참여방법3' className={classes.howToUseImageSize}/>
    //       <br/><Divder/><br/>
    //       <Typography  variant="body1" component="h3" >
    //       <br/> 4. 지원하기 버튼이 '수정하기'로 바뀌어 있네요 ?<br />
    //         지원 글을 수정하고 싶으시면, '수정하기'를 클릭 해주세요.<br/>
    //       </Typography>
    //       <img src={HowToDrawVoteImg4} alt='경품달_내가추첨_참여방법4' className={classes.howToUseImageSize}/>
    //       <br/> <br/> 
    //       </div>
    //       <RouterLink to='/draw-vote'>
    //         <Button variant="outlined" style={{color:'#263880',borderColor:'#263880', marginTop:10}} size="medium" color="primary">
    //           <Typography className={classes.prizeTextForMobile13}>
    //               내가추첨 가기
    //             </Typography>
    //           </Button>
    //       </RouterLink>
    //       <br/> <br/>
    //     </div>
    //   )
    // }

    return (
        <React.Fragment>
            <CssBaseline />
            <Paper elevation={0} className={classes.sectionDesktop}>
              <Typography variant="h5" component="h3" style={{backgroundColor:'#001353', color:'white'}}>
                  <br/>이용방법<br/><br/>
              </Typography>
              {/* <Typography variant="body2" component="h3" style={{backgroundColor:'#001353', color:'white'}}>
                  경품달과 함께 알뜰쇼핑하기 ! <br/><br/><br/>
              </Typography> */}
              </Paper>
                {/* <Typography variant="h6" className={classes.sectionMobile}>
                    이용방법
                </Typography> */}
                {/* <Grid container justify='center'>
                    <Grid item md={9} xs={8}>
                            <img style={{width:'50%'}} className={classes.sectionDesktop} src={tutorialImg} alt='tutorial' />
                            <img style={{width:'80%'}} className={classes.sectionMobile} src={tutorialImg} alt='tutorial' />
                    </Grid> */}
                {/* <Typography variant="body2" component="h3" className={classes.sectionDesktop}>
                  경품달 이용방법에 대해 안내 해드릴게요<br /><br/>
                </Typography> */}
                {/* <Typography variant="body2" component="h3" className={classes.sectionMobile}>
                  경품달 이용방법을 알려드릴게요 :D<br />
                </Typography> */}
                {/* </Grid> */}

              <AppBar position="static" className={classes.appBarColor}>
                {isMobile===true ? <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable_how_to_use_tabs"
                >
                  <Tab label="추첨행사" />
                  <Tab label="가입형 추첨" />
                  <Tab label="구매형 추첨" />
                  <Tab label="구매인증" />
                  {/* <Tab label="내가추첨" /> */}
                </Tabs> :
                <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                centered
                aria-label="scrollable_how_to_use_tabs"
              >
                <Tab label="추첨행사" />
                <Tab label="가입형 추첨" />
                <Tab label="구매형 추첨" />
                <Tab label="구매인증" />
                {/* <Tab label="내가추첨" /> */}
              </Tabs>
              }
                
                </AppBar>
                <TabPanel value={value} index={0}>
                  {howToDrawEventInfo}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {howToJoinType}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {howToPurchaseType}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  {howToPurchaseCert}
                </TabPanel>
                {/* <TabPanel value={value} index={4}>
                  {howToDrawVote}
                </TabPanel> */}
            {/* <Typography variant="body1" component="h3" >
            그러나, 경품달은 <RouterLink to='/draw-vote'>회원간 투표</RouterLink>를 통해 추첨진행자를 선정 !
            </Typography> */}

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"로그인 해주세요"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                이 메뉴는 로그인이 필요해요.<br />
                로그인 후, 이용하시겠어요?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit">
                  닫기
                </Button>
              <RouterLink to="/sign-up">
                <Button onClick={handleClose} color="primary">
                  회원가입
                </Button>
              </RouterLink>
              <RouterLink to='/login'>
                <Button onClick={handleClose} color="primary" autoFocus>
                  로그인
                </Button>
              </RouterLink>
            </DialogActions>
          </Dialog>

        </React.Fragment>

    )
}
import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CommonAPI from '../components/CommonAPI';
// import Link from '@material-ui/core/Link';
import { Link as LinkRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TokenContext from '../components/ContextAPI';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { Helmet } from 'react-helmet'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      // padding: theme.spacing(2, 0),
      paddingBottom:20,

    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0),
      paddingBottom: '10px',
      borderRadius:0,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  sectionMobile: {
    display: 'inline',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  media: {
    [theme.breakpoints.up('md')]: {
        width: '120px',
        height: '60px',
    },
    [theme.breakpoints.down('md')]: {
        width: '90px',
        height: '40px',
    },
},
  title: {
    padding: theme.spacing(1, 2),
    // paddingBottom: 34
  },
  content: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 1),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0),
    },
  },
  contentMobile: {
    padding: theme.spacing(0, 0),
    paddingBottom: 8
  },
  card: {
    [theme.breakpoints.down('md')]: {
      // boxShadow: 'none',
      borderRadius: 3,
      marginBottom: 6,
      marginTop : 10,
      marginLeft:8,
      marginRight:8,
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1,0.5),
    },
  },
  searchField: {
    [theme.breakpoints.down('md')]: {
      width:'94%'
    },
    [theme.breakpoints.up('md')]: {
      width:'100%',
    },
  },
  categoryBar: {
    // [theme.breakpoints.down('md')]: {
      backgroundColor:'#263880'
    // },
    // [theme.breakpoints.up('md')]: {
    // },
  },
}));

function getLinkPriceMerchants() {
  const api = new CommonAPI("/api/kyungpumdal/get-linkprice-merchants");
  api.setHttpGetParam("?imageSize=none&merchantType=O")
  return api.httpGetAxios();
}

function getLinkPriceCategory() {
  const api = new CommonAPI("/api/kyungpumdal/get-linkprice-merchant-category");
  api.setHttpGetParam("?merchantType=O")
  return api.httpGetAxios();
}

// baseCommissioe: 0.0315
// imageSize: "120x60"
// baseImageURL: "https://img.linkprice.com/files/stlink/072com/0281"
// baseURL: "https://linkmoa.kr/click.php?m=072com&a=A100660940&l=0000"
// imageURL: "http://img.linkprice.com/files/stlink/072com/0151"
// merchantCategory: "여행/티켓"
// merchantID: "072com"
// merchantName: "땡처리닷컴"

function getUserIDParam(userKey, merchantID) {
  if (merchantID !== 'coupang') {
    return '&u_id=' + userKey
  } else {
    return '';
  }
}

function loading() {
  return (
    <Grid item xs={12}>
      <CircularProgress color="primary" />
    </Grid>
  );

}

export default function OverseasShop() {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [clickedURL, setClickedURL] = useState('');
  const [filteredList, setFilteredList] = useState(undefined);
  const tokenContext = useContext(TokenContext);
  const userKey = tokenContext.userKey;
  const [mobileFilteredList, setMobileFilteredList] = useState(undefined);
  const [category, setCategory] = useState(null);
  const [value, setValue] = React.useState('주요쇼핑몰');

  useEffect(() => {
    window.scrollTo(0, 0);

    getLinkPriceCategory().then(
      (response) => {
        if (response.data.result === 0) {
          response.data.dataList.unshift({ 'categoryName': '주요쇼핑몰' });
          setCategory(response.data);
        }
        getLinkPriceMerchants().then(
          (response) => {
            if (response.data.result === 0) {
              setData(response.data);
            }
            document.getElementById('category-tab-0').click();
          }
          ).catch();
      }
    ).catch();
  }, []);


  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndOpenLink = () => {
    setOpen(false);
    if (clickedURL !== '') {
      window.open(clickedURL, "_blank")
    }
  };

  function notifyNotLoggedin(baseURL) {
    setOpen(true);
    setClickedURL(baseURL);
  }

  function filterList(event) {
    let dataList = undefined;
    // console.log(mobileFilteredList)
    if (mobileFilteredList !== undefined) {
      dataList = mobileFilteredList;
    }
    // console.log(dataList);

    if (event.target.value === '' || event.target.value === undefined || event.target.value === null) {
      setFilteredList(undefined);
    } else {
      let filteredByName = dataList.filter(function (item) {
        return item.merchantName.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase().search(
          event.target.value.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase()) !== -1;
      });
      let filteredByCategory = dataList.filter(function (item) {
        return item.merchantCategory.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase().search(
          event.target.value.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase()) !== -1;
      });
      if (filteredByName.length > 0) {
        setFilteredList(filteredByName);
      }
      if (filteredList !== undefined && filteredByCategory.length > 0) {
        const concatFiltered = filteredList.concat(filteredByCategory);
        const filteredDeduplication = concatFiltered.filter((item, idx, array) => {
          return array.indexOf(item) === idx;
        });

        setFilteredList(filteredDeduplication);
      } else if (filteredList === undefined && filteredByCategory.length > 0) {
        setFilteredList(filteredByCategory);
      }
    }
  }


  function mobileFilterList(appBarCategory) {
    let filteredByCategory = null;
    if (appBarCategory === '' || appBarCategory === undefined || appBarCategory === null) {
      setMobileFilteredList(undefined);
    } else if (appBarCategory === '주요쇼핑몰' && data.dataList !== null) {
      const majorList = [];
      data.dataList.forEach(element => {
        if (element.majorStatus === true) (
          majorList.push(element)
        )
      });
      if (majorList.length > 0) {
        setMobileFilteredList(majorList)
      }
    } else {
      filteredByCategory = data.dataList.filter(function (item) {
        return item.merchantCategory.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase().search(
          appBarCategory.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '_').toLowerCase()) !== -1;
      });
    }
    if (filteredByCategory !== null) {
      setMobileFilteredList(filteredByCategory);
    }
  }

  function merchantListCard(props) {
    if (userKey === undefined) {
      return (
        props.map(prop => (
          <Grid item md={3} xs={6} key={prop.merchantID}>
            <Card className={classes.card} onClick={() => notifyNotLoggedin(prop.baseURL)}>
              <CardActionArea>
                <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                />
                <CardContent className={classes.content}>
                  <Typography style={{ fontSize: 14, color:'black' }}>
                    {prop.merchantName}
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: 11 }} color="textSecondary" component="p">
                    ({prop.merchantCategory})
                  </Typography>
                  <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))
      )
    } else {
      return (
        props.map(prop => (
          <Grid item md={3} xs={6} key={prop.merchantID}>
            <Card className={classes.card}>
              {/* <Link href={prop.baseURL + getUserIDParam(userKey, prop.merchantID)} target="_blank" underline="none"> */}
              <CardActionArea onClick={() => window.open(prop.baseURL + getUserIDParam(userKey, prop.merchantID), '_blank')}>
                  <img src={prop.baseImageURL} alt={prop.merchantName} className={classes.media}
                  />
                  <CardContent className={classes.content}>
                    <Typography style={{ fontSize: 14, color:'black' }}>
                      {prop.merchantName}
                    </Typography>
                    <Typography variant="body2" style={{ fontSize: 11 }} color="textSecondary" component="p">
                      ({prop.merchantCategory})
                    </Typography>
                    <Typography variant="body2" style={{backgroundColor:'#263880', color:'white', fontSize:11, padding:'2px'}} color="textSecondary" component="p">
                                                        최대만원당 {prop.scorePerTenThousand}점
                                                    </Typography>
                  </CardContent>
                </CardActionArea>
              {/* </Link> */}
            </Card>
          </Grid>
        ))
      )
    }
  }

  function a11yProps(index) {
    return {
      id: `category-tab-${index}`,
      'aria-controls': `category-tabpanel-${index}`,
    };
  }

  function makeCategoryListTab(props) {
    // console.log(props)
    return (
      props.map((prop, index) => (
        <Tab {...a11yProps(index)} style={{ color: 'white' }} label={prop.categoryName} value={prop.categoryName} key={prop.categoryName} />
      ))
    )
  }

  function filterAppBar(event, newValue) {
    setValue(newValue);
    mobileFilterList(newValue);
  }

  return (

    <React.Fragment>
      <Helmet>
      <title>해외쇼핑몰 | 경품달(경품을 품은 달)</title>
      <meta name="description" content="알리익스프레스, 뱅굿, 월마트 모두 여기에 ! | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
      <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
      <meta name="author" content="병맛사람들" />
      </Helmet>
      <CssBaseline />
      <Paper elevation={0} className={classes.root}>
        <CssBaseline />
        <Paper elevation={0} className={classes.root} justify='center'>
          <Container maxWidth="md" style={{ padding: 0 }} >
          <Paper elevation={0} className={classes.sectionDesktop}>
              <Typography variant="h5" component="h3" style={{backgroundColor:'#001353', color:'white'}}>
                  <br/>해외몰
              </Typography>
              <Typography variant="body2" component="h3" style={{backgroundColor:'#001353', color:'white'}}>
                  상품 구매 시, 추첨점수 자동적립<br/><br/><br/>
              </Typography>
              </Paper>
              {/* <div className={classes.sectionDesktop}>
                <Typography variant="body2" component="h3">
                모든 해외 쇼핑몰은 로그인 후 경품달을 통해<br />구매 시, 자동으로 추첨대상(구매형)이 됩니다.
                </Typography><br/>
              </div> */}
            <div>
              {category == null ? loading() :
                <AppBar position="static" className={classes.categoryBar}>
                  <Tabs
                    value={value}
                    onChange={filterAppBar}
                    indicatorColor="secondary"
                    // textColor="black"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable tabs"
                  >
                    {makeCategoryListTab(category.dataList)}
                  </Tabs>
                </AppBar>
              }
            </div>
              
              <TextField  margin="dense" variant='outlined'  className={classes.searchField} id="standard-search" label="카테고리별 해외몰 이름을 검색해보세요" type="search" onChange={filterList} />
            <div>
              <Grid container spacing={0} alignItems="center">
                {data == null ? loading() : mobileFilteredList !== undefined && filteredList === undefined ? merchantListCard(mobileFilteredList, classes, userKey) : mobileFilteredList !== undefined && filteredList !== undefined && merchantListCard(filteredList, classes, userKey)}
              </Grid>
            </div>
          </Container>
        </Paper>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"로그인 없이 이용하시려구요 ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              로그인 없이도 구매가 가능하지만,<br />
              구매형 추첨대상에 포함되지 않아요.<br />
              로그인 후 구매 시, 당첨 가능성 업 !
                </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClose} color="secondary" style={{color:"#616161"}}>
                            닫기
            </Button>
            <Button onClick={handleCloseAndOpenLink} color="secondary" style={{color:"#616161"}}>
              그냥 이용할래요
                </Button>
            <LinkRouter to='/login'>
              <Button onClick={handleClose} color="primary" autoFocus>
                로그인
                    </Button>
            </LinkRouter>
          </DialogActions>
        </Dialog>
      </Paper>
    </React.Fragment>
  );
}

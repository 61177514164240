import React from 'react';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MoneyIcon from '@material-ui/icons/Money';
import GavelIcon from '@material-ui/icons/Gavel';
import CloseIcon from '@material-ui/icons/Close';
import Brightness4Icon from '@material-ui/icons/Brightness4';

const optionsHome = [
    { name: 'HOME', url: '/' },
    { name: '이용방법', url: '/how-to-use' },
];
const optionsShop = [
    /* 쿠팡주문번호 난수화로 인한 삭제 */
    // { name: '쿠팡관', url: '/coupang' },
    { name: '국내몰', url: '/domestic-shop' },
    { name: '해외몰', url: '/overseas-shop' },
    { name: '여행/티켓', url: '/tour' },
    { name: '할인코드', url: '/discount-code' },
];
const optionsDraw = [
    { name: '추첨행사', url: '/prize-event' },
    /* 추첨 자동화로 인한 삭제 */
    // { name: '내가추첨', url: '/draw-vote' },
    /* 쿠팡주문번호 난수화로 인한 삭제 */
    // { name: '구매인증', url: '/purchase-cert' },
];
const optionsTerm = [
    { name: '서비스이용', url: '/terms-of-use' },
    { name: '위치정보이용', url: '/terms-of-location' },
    { name: '개인정보처리', url: '/terms-of-privacy' },
];

const ITEM_HEIGHT = 100;

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(0)
    },
}));

export default function NavMenu() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={handleClick}
                style={{color:'white'}}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '100%',
                    },
                }}
            >
                <Typography style={{color:'#383838',  textAlign:'right', paddingRight:'16px', paddingTop:'10px'}}>
                    <CloseIcon onClick={handleClose} style={{fontSize:'16px'}}/>
                </Typography>
                <Typography style={{color:'#383838', paddingLeft:'16px', fontSize:'12px'}}>
                    <Brightness4Icon style={{fontSize:'22px'}}/> 경품달
                </Typography>
                
                <Grid container>
                {optionsHome.map(option => (
                    <Grid item xs={4}  key={option.name}>
                    <Link to={option.url} key={option.name}>
                        <MenuItem key={option.name} selected={option.url === '/'} onClick={handleClose} style={{backgroundColor:'white'}}>
                            <Typography style={{color:'#2a2a2a', fontSize:'12px'}}>
                                {option.name}
                            </Typography>
                        </MenuItem>
                    </Link>
                    </Grid>
                ))}
                </Grid>
                <Divider/>
                <Typography style={{color:'#383838', paddingLeft:'16px', paddingTop:'10px', fontSize:'12px'}}>
                    <ShoppingBasketIcon style={{fontSize:'22px'}}/> 쇼핑
                </Typography>
                <Grid container>
                {optionsShop.map(option => (
                    <Grid item xs={4}  key={option.name}>
                    <Link to={option.url} key={option.name}>
                        <MenuItem key={option.name} onClick={handleClose} style={{backgroundColor:'white'}}>
                            <Typography style={{color:'#2a2a2a', fontSize:'12px'}}>
                                {option.name}
                            </Typography>
                        </MenuItem>
                    </Link>
                    </Grid>
                ))}
                </Grid>
                <Divider/>
                <Typography style={{color:'#383838',  paddingLeft:'16px', paddingTop:'10px', fontSize:'12px'}}>
                    <MoneyIcon style={{fontSize:'24px'}}/> 추첨
                </Typography>
                <Grid container>
                {optionsDraw.map(option => (
                    <Grid item xs={4}  key={option.name}>
                    <Link to={option.url} key={option.name}>
                        <MenuItem key={option.name} onClick={handleClose} style={{backgroundColor:'white'}}>
                            <Typography style={{color:'#2a2a2a', fontSize:'12px'}}>
                                {option.name}
                            </Typography>
                        </MenuItem>
                    </Link>
                    </Grid>
                ))}
                </Grid>
                <Divider/>
                <Typography style={{color:'#383838', paddingLeft:'16px', paddingTop:'10px', fontSize:'12px'}}>
                    <GavelIcon stlye={{ fontSize:'22px',}}/> 약관
                </Typography>
                <Grid container spacing={0}>
                {optionsTerm.map(option => (
                    <Grid item xs={4}  key={option.name}>
                    <Link to={option.url} key={option.name}>
                        <MenuItem key={option.name} onClick={handleClose} style={{backgroundColor:'white'}}>
                            <Typography style={{color:'#2a2a2a', fontSize:'12px'}}>
                                {option.name}
                            </Typography>
                        </MenuItem>
                    </Link>
                    </Grid>
                ))}
                </Grid>
            </Menu>
        </div>
    );
}
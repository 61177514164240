import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/typography'
import Container from '@material-ui/core/Container'
import BusinessIcon from '@material-ui/icons/Business';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link'
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
// import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import MoneyIcon from '@material-ui/icons/Money';
import HelpIcon from '@material-ui/icons/Help';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import ForumIcon from '@material-ui/icons/InsertComment';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import NaverBlogIcon from '../../assets/img/naver_blog_icon.svg'
import FooterLogoGreyScale from '../../assets/img/kyungpumdal_footer_logo.png'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 0,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      padding: theme.spacing(3, 1),
    },
  },
  rootMobile: {
    borderRadius: 0,
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  terms: {
    fontSize: 14,
  },
  notice: {
    fontSize: 13,
  },
  noticeIcon: {
    fontSize: 14,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  sectionMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
    },
  },
  scrollTopButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'inline',
    },
  },
  floatingStyleForTab: {
    [theme.breakpoints.down('md')]: {
      display:'inline',
      margin: 0,
      top: 'auto',
      right: 0,
      bottom: 0,
      width:'100%',
      backgroundColor:'#f8f8f8',
      left: 'auto',
      position: 'fixed',
      zIndex:600
    },
  },
  floatingStyleForTabHidden: {
    display: 'none',
  },
  label: {
    textTransform: 'uppercase',
    marginTop: 5,
  },
  floatingStyleTopButton: {
    display:'inline',
    [theme.breakpoints.down('md')]: {
      display:'inline',
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed',
      backgroundColor:'#001353',
    },
  },
}));

export default function FooterText(props) {
  const classes = useStyles();
  const [prevScrollpos, setPrevScrollPos] = React.useState(window.pageYOffset);
  const [visible, setVisible] = React.useState(true);

  function handleScroll() {
    const currentScrollPos = window.pageYOffset;
    const visiblehandle = prevScrollpos > currentScrollPos;
  
    setPrevScrollPos(currentScrollPos);
    setVisible(visiblehandle);
  };

  window.addEventListener('scroll', handleScroll);

  function ScrollTop(props) {
    const { children } = props;
    // const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    // const trigger = useScrollTrigger({
    //   target: window ? window() : undefined,
    //   disableHysteresis: true,
    //   threshold: 100,
    // });

    const handleClick = event => {
      const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

      if (anchor) {
        anchor.scrollIntoView({ behavior: 'auto' });
      }
    };

    return (
      <Zoom in={!visible} className={classes.scrollTopButton} >
        <div onClick={handleClick} role="presentation" >
          {children}
        </div>
      </Zoom>
    );
  }

  // if (window.innerHeight + window.scrollY > document.body.clientHeight) {
  //     document.getElementById('bottomNav').style.display='none';

  return (
    <React.Fragment>
      <div className={classes.sectionMobile}>
      <Paper elevation={0} style={{padding:0,  borderRadius: 0}}>
        <BottomNavigation
          // value={value}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          // }}
          showLabels
          className={visible ? classes.floatingStyleForTab : classes.floatingStyleForTabHidden}
        >
          <RouterLink to ='/'  style={{ textDecoration: 'none' }}><BottomNavigationAction showLabel classes={{label: classes.label}} label='HOME' icon={<HomeIcon />} /></RouterLink>
          <RouterLink to ='/how-to-use'  style={{ textDecoration: 'none'}}><BottomNavigationAction showLabel classes={{label: classes.label}} label='이용방법' icon={<HelpIcon />} /></RouterLink>
          <RouterLink to ='/prize-event'  style={{ textDecoration: 'none'}}><BottomNavigationAction showLabel classes={{label: classes.label}} label='경품가액' icon={<MoneyIcon />} /></RouterLink>
          <RouterLink to ='/mypage' style={{ textDecoration: 'none' }}><BottomNavigationAction showLabel classes={{label: classes.label}} label='내 점수/순위' icon={<EmojiEventsIcon/>} /></RouterLink>
        </BottomNavigation>
      </Paper>
      <ScrollTop {...props} >
        <Fab color="primary" size="small" aria-label="scroll back to top" className={classes.floatingStyleTopButton}>
          <KeyboardArrowUpIcon style={{marginTop: '5px'}} />
        </Fab>
      </ScrollTop>
      </div>
      <CssBaseline />
      <Paper elevation={2} className={classes.root} style={window.location.pathname === '/' ? {backgroundColor:'white'}: {backgroundColor:'#f8f8f8'}}>
        <Container maxWidth="md" style={{padding:0}}>
        <div className={classes.sectionDesktop}>
        <Grid container spacing={0} alignItems="center" justify="center" >
          <Grid item md={3} style={{textAlign:"center"}}>
          <Link href='https://www.youtube.com/channel/UCeK-HCBpMozxnf-r7-RAM9Q' target="_blank">
              <Button color="primary">
                <Typography className={classes.terms}>
                  <YouTubeIcon style={{ color:'black', textAlign: 'center'}}/>
                </Typography>
              </Button>
            </Link>
            <Link href='https://www.facebook.com/kyungpumdal/' target="_blank">
              <Button color="primary">
                <Typography className={classes.terms}>
                  <FacebookIcon style={{ color:'black', textAlign: 'center'}}/>
            </Typography>
              </Button>
            </Link>
            {/* <Link href='https://www.instagram.com/dykwon_/' target="_blank">
              <Button color="primary">
                <Typography className={classes.terms}>
                  <InstagramIcon style={{ color:'black', textAlign: 'center'}}/>
              </Typography>
              </Button>
            </Link> */}
            <Link href='https://blog.naver.com/kyungpumdal' target="_blank">
              <Button color="primary">
                <Typography className={classes.terms}>
                <img src={NaverBlogIcon} alt="네이버_블로그_아이콘" />
              </Typography>
              </Button>
            </Link>
          <br/>
            <img style={{width:"90%", opacity:"0.45", marginTop:16}} src={FooterLogoGreyScale} alt="홈페이지_푸터_경품달_로고" />
          </Grid>
          <Grid item md={9} style={{textAlign:'right'}}>
            <RouterLink to='/terms-of-use'>
              <Button color="primary" style={{color:"#344ba7"}}>
                <Typography className={classes.terms}>
                  서비스 이용약관
                </Typography>
              </Button>
            </RouterLink>
            <RouterLink to='/terms-of-location'>
              <Button color="primary" style={{color:"#344ba7"}}>
                <Typography className={classes.terms}>
                  위치정보 이용약관
            </Typography>
              </Button>
            </RouterLink>
            <RouterLink to='/terms-of-privacy'>
              <Button color="primary" style={{color:"#344ba7"}}>
                <Typography className={classes.terms}>
                  개인정보처리방침
              </Typography>
              </Button>
            </RouterLink>
            <Typography className={classes.notice} style={{textAlign:'right'}}>
              <br />
              경품달은 제휴마케팅 수익금을 통해 추첨행사를 진행하며, 사행성을 조장하지 않습니다.
              <br />
              상품에 직접 관여하지 않으며 상품 주문, 배송 및 환불의 의무와 책임은 각 판매자에게 있습니다.
              
          </Typography>
            <Typography className={classes.notice} style={{textAlign:'right'}}>
              <BusinessIcon className={classes.noticeIcon} /> : 전라남도 장성군 진원면 덕주 1길 21-5
              &nbsp;<ContactSupportIcon className={classes.noticeIcon} /> : help@byeongmat.com
              <br/>
              입점 및 광고 : help@byeongmat.com &nbsp;© 2019. 병맛사람들 Inc. All Rights Reserved.
          </Typography>
          </Grid>
          </Grid>
          </div>
        </Container>
      </Paper>
      {window.location.pathname === '/' || window.location.pathname === '/celebrate-sign-up' ?
        <Paper elevation={0} className={classes.rootMobile} style={{ backgroundColor: '#f8f8f8' }}>
          {/* <CssBaseline /> */}
          <Container maxWidth="md" style={{padding:0}} >
          <Link href='https://www.youtube.com/channel/UCeK-HCBpMozxnf-r7-RAM9Q' target="_blank">
              <Button color="inherit" size="small">
                <Typography style={{ color:'black',textAlign: 'center', fontSize: '0.6rem' }}>
                  <YouTubeIcon/>
                </Typography>
              </Button>
            </Link>
            <Link href='https://www.facebook.com/kyungpumdal/' target="_blank">
              <Button color="inherit" size="small">
                <Typography style={{ color:'black',textAlign: 'center', fontSize: '0.6rem'}}>
                  <FacebookIcon/>
            </Typography>
              </Button>
            </Link>
            {/* <Link href='https://www.instagram.com/dykwon_/' target="_blank">
              <Button color="inherit" size="small">
                <Typography style={{ color:'black', textAlign: 'center', fontSize: '0.6rem' }}>
                  <InstagramIcon/>
              </Typography>
              </Button>
            </Link> */}
            <Link href='https://blog.naver.com/kyungpumdal' target="_blank">
              <Button color="inherit" size="small">
              <Typography style={{ color:'black', textAlign: 'center', fontSize: '0.6rem' }}>
                <img src={NaverBlogIcon} alt="네이버_블로그_아이콘" />
              </Typography>
              </Button>
            </Link>
            
            <Typography style={{ textAlign: 'center', fontSize: '0.6rem' }}>
              경품달은 수익금을 통해 추첨행사를 진행하며, 사행성을 조장하지 않습니다.
              <br />
              상품에 관여하지 않으며 주문, 배송 및 환불의 의무와 책임은 판매자에게 있습니다.
              {/* 또한, 경품달은 파트너스 활동을 통해 일정액의 수수료를 제공받을 수 있습니다.<br /><br /> */}
              <br />
            </Typography>
            <Typography style={{ textAlign: 'center', fontSize: '0.6rem' }}>
              <BusinessIcon style={{ fontSize: '0.8rem' }} /> : 전라남도 장성군 진원면 덕주 1길 21-5  <ContactSupportIcon style={{ fontSize: '0.8rem' }} /> : help@byeongmat.com
              <br />
              입점/광고 : help@byeongmat.com © 2019. 병맛사람들. All Rights Reserved.
          </Typography>
          </Container>
        </Paper>
        : ''
      }
    </React.Fragment>
  );
}
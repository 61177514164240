import React from 'react';
import axios from "axios";

class CommonAPI extends React.Component {
    
    url = '';
    endpoints = '';
    param = '';
    auth = '';

    //API 클래스 생성자 endpoints와 함께 생성
    constructor(endpoints) {
        super();
        // .env 에 설정된 SERVER_TYPE 에 따라서, url 을 달리 설정함
        // live : (AJP) 실제 아파치 WEB 서버 => 실제 톰캣 API 서버
        // dev : (HTTP) NODE JS WEB 서버 => 개발(이클립스) 톰캣 API 서버
        // local : (AJP) 개발 아파치 WEB 서버 => 개발(이클립스) 톰캣 API 서버
        if(process.env.REACT_APP_SERVER_TYPE==='live'){
            this.url = "https://kyungpumdal.com";
        }else if(process.env.REACT_APP_SERVER_TYPE==='dev'){
            this.url = "http://localhost:9001";
        }else if(process.env.REACT_APP_SERVER_TYPE==='local'){
            this.url = "http://localhost";
        }else{
            this.url = "http://localhost:9001";
        }
        this.endpoints = endpoints;
        this.param = '';
        this.authToken = '';
    };

    // EndPoint 세팅
    setEndpoint(endpoint) {
        this.endpoints = endpoint;
    };

    // HTTP GET 파라미터 세팅
    setHttpGetParam(urlTextParam) {
        this.param = urlTextParam;
    };

    // HTTP POST 파라미터 세팅
    setHttpPostParam(jsonTextParam) {
        this.param = jsonTextParam;
    };

    // HTTP POST AuthToken 세팅
    setAuthToken(authToken) {
        this.authToken = authToken;
    };

    //HTTP GET CALL
    httpGetAxios() {
        if (this.url !== undefined && this.endpoints !== undefined && this.url !== '' && this.endpoints !== '') {
            const resourceURL = this.url + this.endpoints + encodeURI(this.param);
            return axios({
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'text/plain;charset=UTF-8'
                },
                url: resourceURL,
                method: 'get'
            })
        }
    }
    //HTTP POST CALL
    httpPostAxios() {
        if (this.url !== undefined && this.endpoints !== undefined && this.url !== '' && this.endpoints !== '' && this.param !== undefined && this.param !== '') {
            const resourceURL = this.url + this.endpoints;
            const param = this.param;

            return axios.post(resourceURL, param, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            })
        }
    }

    //HTTP POST CALL WITH TOKEN
    httpPostAxiosWithJWT() {
        if (this.url !== undefined && this.endpoints !== undefined && this.url !== '' && this.endpoints !== '' && this.authToken !== undefined && this.authToken !== '') {
            const resourceURL = this.url + this.endpoints;
            const authToken = this.authToken;
            const param = this.param;

            return axios.post(resourceURL, param, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Authorization": authToken
                }
            })
        }
    }

}
export default CommonAPI;
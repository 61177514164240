import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import CommonAPI from '../CommonAPI'
import Grid from '@material-ui/core/Grid';
import KyungpumdalWillGoToTheMoon from '../../assets/img/undraw/kyungpumdal_will_go_to_the_moon.png'
import Loading from '../assets/Loading';
// import PetsIcon from '@material-ui/icons/Pets';
import Container from '@material-ui/core/Container'
// import Slide from '@material-ui/core/Slide';
// import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import CountUp from 'react-countup';

// import KoreanStew from '../../assets/img/korean_stew.png'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
    // marginBottom: 12,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 0),
    },
  },
  prizeText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  prizeText2: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
      marginTop:'5px'
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  sectionMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inline',
    },
  },
}));

export default function HomeMainPrize() {
  const classes = useStyles();
  const [roundNumber, setRoundNumber] = useState('');
  const [curMonthOrderCounts, setCurMonthOrderCounts] = useState(0);
  // const [curMonthAverageScore, setCurMonthAverageScore] = useState(0);
  // const [beginApplyDate, setBeginApplyDate] = useState('');
  // const [endApplyDate, setEndApplyDate] = useState('');
  // const [prizeDateTime, setPrizeDateTime] = useState('');
  const [priceOpenDateTime, setPriceOpenDateTime] = useState('');
  const [prize, setPrize] = useState(0);
  const [canOpenPrize, setCanOpenPrize] = useState(false);
  // const [start, setStart] = useState(false);
  // const [month, setMonth] = useState('');
  // const [checked, setChecked] = React.useState(false);
  
  // const handleChange = () => {
  //   setChecked(prev => !prev);
  // };

  // 경품정보 가져오기 API
  function getPrizeInfoForHome() {
    const api = new CommonAPI("/api/kyungpumdal/get-prize-info-for-home");
    return api.httpGetAxios();
  }

  // comma 찍기 함수
  // function comma(number) {
  //   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // };

  useEffect(() => {
    let unmounted = false;

    // const startDate = new Date('2020', '00', '01', '00', '00', '00');
    // if (startDate < new Date()) {
    //   setStart(true);
    // }

    getPrizeInfoForHome().then(
      (response) => {
        if (!unmounted) {
        if (response.data.result === 0) {
          const prizeInfo = response.data.data;
          // console.log(prizeInfo)

          // setCurMonthAverageScore(prizeInfo.averageScore);
          setCurMonthOrderCounts(prizeInfo.curMonthOrderCounts)
          setRoundNumber(prizeInfo.roundNumber);
          // setBeginApplyDate(prizeInfo.beginApplyDate);
          // setEndApplyDate(prizeInfo.endApplyDate);
          // setPrizeDateTime(prizeInfo.prizeDateTime);

          const yyyy = prizeInfo.priceOpenDateTime.substr(0, 4);
          const MM = prizeInfo.priceOpenDateTime.substr(5, 2);
          const dd = prizeInfo.priceOpenDateTime.substr(8, 2);
          const hh = prizeInfo.priceOpenDateTime.substr(11, 2);
          const mm = prizeInfo.priceOpenDateTime.substr(14, 2);
          const ss = prizeInfo.priceOpenDateTime.substr(17, 2);
          // setMonth(MM);
          setPriceOpenDateTime(yyyy + "." + MM + "." + dd);

          const priceOpenDt = new Date(yyyy, MM - 1, dd, hh, mm, ss);
          if (priceOpenDt > new Date()) {
            setCanOpenPrize(false);
          } else {
            setCanOpenPrize(true);
            setPrize(Math.floor(prizeInfo.prize));
          }
        }
      }
      }
    ).catch()

    return () => { unmounted = true };

  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper elevation={0} className={classes.root} style={{backgroundColor:"#f8f8f8"}}>
        <Container maxWidth="md">
          {roundNumber !== '' ?
            <div>
              {canOpenPrize === true ?
                <div>
                    <div>
                      {/* <Typography className={classes.prizeText2}>
                        ({beginApplyDate} ~ {endApplyDate})
              </Typography> */}
                      {/* <Typography variant="h5" className={classes.prizeText}>
                        이번 달
              </Typography> */}
                      {curMonthOrderCounts !== 0 ?
                      <Typography variant="h5" className={classes.prizeText}>
                        이달 구매건수 {curMonthOrderCounts} 건
                      </Typography> : ''
                      }
                      <Typography variant="h4" className={classes.prizeText}>
                         총경품가액 <CountUp separator="," start={0} duration={2} end={prize} /> 원
                      </Typography>
                      {/* {curMonthAverageScore !== 0 &&
                      <Typography variant="h6" className={classes.prizeText}>
                        평균 추첨점수 {comma(curMonthAverageScore)} 점
                      </Typography>
                      } */}
                    </div>
                  <Grid container justify='center'>
                    <Grid item md={8} xs={12} className={classes.sectionDesktop}>
                      <img style={{ width: '70%', marginBottom:5 }} src={KyungpumdalWillGoToTheMoon} alt="경품달_홈_경품당첨_일러스트"/>
                    </Grid>
                  </Grid>
                  <Typography variant="body1" className={classes.sectionDesktop}>
                    실적에 따라 경품가액은 매일 업데이트 됩니다<br/>
                    필요한 상품 구매하시고, 추첨대상 되세요 !<br />
                    {/* 경품달 수익만큼 경품가액은 업!<br /><br/> */}
                  </Typography>
                  <div>
                    <Link to='/prize-event' >
                      <Button color="primary" className={classes.sectionDesktop} variant="outlined" style={{marginTop:20}}>
                      <Typography>
                          자세히보기
                     </Typography>
                     </Button>
                      <Button color="primary" style={{padding:0,paddingTop:'3px'}} className={classes.sectionMobile}>
                      <Typography style={{fontSize:'14px'}}>
                          경품가액 확인하기
                     </Typography>
                     </Button>
                    </Link>
                    {/* <br /><br />
                    <FormControlLabel 
                      control={<Switch color="primary" checked={checked} onChange={handleChange} />}
                      label="복권 사세요 ? 쇼핑하세요 !"  className={classes.sectionDesktop}
                    /> */}
                  </div>
                  {/* <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
                  <Typography variant="body1" className={classes.sectionDesktop}>
                    쇼핑하면 적립되는 추첨점수
                    <br/>로그인 하고, 
                    <Link to='/mypage' ><Button color="primary" style={{color:"#344ba7"}}>내 점수 확인</Button></Link>
                  </Typography>
                  </Slide> */}
                </div>
                :
                <div>
                    <div>
                      {/* <Typography className={classes.prizeText2}>
                        ({beginApplyDate} ~ {endApplyDate})
              </Typography> */}
                      {/* <Typography variant="h5" className={classes.prizeText}> */}
                        {/* {roundNumber} 회차 */}
                        {curMonthOrderCounts !== 0 ?
                        <Typography variant="h5" className={classes.prizeText}>
                          이달 구매건수 {curMonthOrderCounts} 건
                        </Typography> : 
                        <Typography variant="h4" className={classes.prizeText}>
                          이달 총경품가액
                        </Typography>
                        }
                        {/* {curMonthAverageScore !== 0 &&
                        <Typography variant="h6" className={classes.prizeText}>
                          평균 추첨점수 {comma(curMonthAverageScore)} 점
                        </Typography>
                        } */}
              {/* </Typography> */}
                      <Typography variant="h4" className={classes.prizeText}>
                        공개일 "{priceOpenDateTime}"
              </Typography>
                    </div>
                  <Grid container justify='center'>
                    <Grid item md={8} xs={12} className={classes.sectionDesktop}>
                      <img style={{ width: '70%', marginBottom:5 }} src={KyungpumdalWillGoToTheMoon} alt="경품달_홈_경품당첨_일러스트"/>
                    </Grid>
                  </Grid>
                  <Typography variant="h6" className={classes.sectionDesktop}>
                    실적에 따라 경품가액은 매일 업데이트 됩니다<br/>
                    필요한 상품 구매하시고, 추첨대상 되세요 !<br />
                  </Typography>
                  <div>
                    <Link to='/prize-event' >
                    <Button color="primary" className={classes.sectionDesktop} variant="outlined" style={{marginTop:20}}>
                      <Typography>
                          지난달 경품가액
                     </Typography>
                     </Button>
                      <Button color="primary" className={classes.sectionMobile}>
                      <Typography style={{fontSize:'14px'}}>
                      지난달 경품가액 보기
                     </Typography>
                     </Button>
                    </Link>
                    <br className={classes.sectionDesktop}/><br className={classes.sectionDesktop}/>
                    {/* <FormControlLabel
                      control={<Switch color="primary" checked={checked} onChange={handleChange} />}
                      label="복권 사세요 ? 쇼핑하세요 !" className={classes.sectionDesktop}
                    /> */}
                  </div>
                  {/* <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
                    <Typography variant="body1" className={classes.sectionDesktop}>
                    쇼핑하면 적립되는 추첨점수
                    <br/>로그인 하고, 
                    <Link to='/mypage' ><Button color="primary" style={{color:"#344ba7"}}>내 점수 확인</Button></Link>
                  </Typography>
                  </Slide> */}
                </div>
              }
            </div> : <Loading />
          }

        </Container>
      </Paper>
    </React.Fragment>
  );
}
import 'date-fns';
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import {Link as RouterLink, withRouter}  from 'react-router-dom' 
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
import TermsOfUse from './TermsOfUse'
import TermsOfLocation from './TermsOfLocation'
import TermsOfPrivacy from './TermsOfPrivacy'
import CommonAPI from '../components/CommonAPI'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
// import { Redirect } from 'react-router-dom';
import HomeLogo from '../assets/img/logo.png';
import { Helmet } from 'react-helmet'
import DateFnsUtils from '@date-io/date-fns';
import koLocale from "date-fns/locale/ko";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';


// 스타일 객체
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  dateField: {
    width: 150,
  },
  formControlOption: {
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'inline',
    },
  },
  sectionMobile: {
      display: 'inline',
      [theme.breakpoints.up('md')]: {
          display: 'none',
      },
  },
}));

// 카피라이트
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https://material-ui.com/"> */}
      병맛사람들
      {/* </Link>{' '} */}
      {' ' + new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// 오늘날짜 구하기 (yyyy-MM-dd)
function getCurDate() {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) {
    month = '0' + month
  } else if (day.length < 2) {
    day = '0' + day
  };
  return [year, month, day].join('-');
}

function isChild(birthDate) {
  // console.log(birthDate)
  birthDate = birthDate.replace(/-/gi, "");

  let today = new Date();
  let yyyy = today.getFullYear();
  let mm = today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1); // getMonth()
  let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
  let n = parseInt(String(yyyy) + String(mm) + String(dd)) - parseInt(birthDate) - 140000;
  
  if(n < 0){
    return true
  }else{
    return false;
  }
}

// SELECT 메뉴 생성
function getSelectMenu(props, menuName) {
  if (menuName === 'job') {
    return (
      props.map(prop => (
        <MenuItem key={prop.jobCode} value={prop.jobCode}>{prop.jobName}</MenuItem>
      ))
    )
  } else if (menuName === 'category') {
    return (
      props.map(prop => (
        <MenuItem key={prop.categoryCode} value={prop.categoryCode}>{prop.categoryName}</MenuItem>
      ))
    )
  } else if (menuName === 'pet') {
    return (
      props.map(prop => (
        <MenuItem key={prop.petCode} value={prop.petCode}>{prop.petName}</MenuItem>
      ))
    )
  }
}

// // 자녀 객체 최초생성
// const defaultChildrenList = [
//   {
//     'sexType': '',
//     'birthYMD': ''
//   }
// ]

export default withRouter(function Register(history) {
  // console.log(history)
  //스타일 객체 생성
  const classes = useStyles();

  //직업코드, 반려동물 코드, 카테고리 코드에 대한 State 생성
  const [jobCode, setJobCode] = React.useState(null);
  const [petCode, setPetCode] = React.useState(null);
  const [categoryCode, setCategoryCode] = React.useState(null);

  //직업코드, 반려동물 코드, 카테고리 코드 가져오기
  useEffect(() => {
    window.scrollTo(0, 0);

    getJobCode().then(
      (response) => {
        if (response.data.result === 0) {
          setJobCode(response.data.dataList);
        }
      }
    ).catch();

    getPetCode().then(
      (response) => {
        if (response.data.result === 0) {
          setPetCode(response.data.dataList);
        }
        
      }
    ).catch();

    getCategoryCode().then(
      (response) => {
        if (response.data.result === 0) {
          setCategoryCode(response.data.dataList);
        }
      }
    ).catch();
  }, []);


  // 직업코드 가져오기 API
  function getJobCode() {
    const api = new CommonAPI("/api/kyungpumdal/get-code-name");
    api.setHttpGetParam("?codeType=job")
    return api.httpGetAxios();
  }

  // 반려동물 코드 가져오기 API
  function getPetCode() {
    const api = new CommonAPI("/api/kyungpumdal/get-code-name");
    api.setHttpGetParam("?codeType=pet")
    return api.httpGetAxios();
  }

  // 관심카테고리 코드 가져오기 API
  function getCategoryCode() {
    const api = new CommonAPI("/api/kyungpumdal/get-code-name");
    api.setHttpGetParam("?codeType=category")
    return api.httpGetAxios();
  }

  // 아이디 중복확인 API
  const getIsExistUser = event => {
    if (/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email)) {
      const api = new CommonAPI("/api/get-is-exist-user");
      api.setHttpGetParam("?userID=" + email)
      api.httpGetAxios().then(
        (response) => {
          if (response.data.result === 0) {
            // console.log(response.data.data.isExistUser);
            if (response.data.data.isExistUser === true) {
              setEmail('')
              setEmailReal('')
              setEmailCheck(false);
            } else {
              setEmailReal(email)
              setEmailCheck(true);
            }
          }
        }
      ).catch();
    }
  }

  // 닉네임 중복확인 API
  const getIsExistNickName = event => {
    const api = new CommonAPI("/api/get-is-exist-nick");
    api.setHttpGetParam("?nickName=" + nickName)
    api.httpGetAxios().then(
      (response) => {
        if (response.data.result === 0) {
          // console.log(response.data.data.isExistNickName);
          if (response.data.data.isExistNickName === true) {
            setNickName('')
            setNickNameReal('')
            setNickNameCheck(false);
          } else {
            setNickNameReal(nickName)
            setNickNameCheck(true);
          }
        }
      }
    ).catch();
  }

  // 휴대폰 중복확인 API
  const getIsExistPhoneNumber = event => {
    if (phoneNumber1.length < 2 || phoneNumber1.length > 4) {
      setPhoneNumber1('')
    } else if (phoneNumber2.length < 3 || phoneNumber2.length > 4) {
      setPhoneNumber2('')
    } else if (phoneNumber3.length < 4 || phoneNumber3.length > 4) {
      setPhoneNumber3('')
    } else {
      const phoneNumber = phoneNumber1 +"-"+ phoneNumber2 +"-"+ phoneNumber3;
      const api = new CommonAPI("/api/get-is-exist-phone-number");
      api.setHttpGetParam("?phoneNumber=" + phoneNumber)
      api.httpGetAxios().then(
        (response) => {
          if (response.data.result === 0) {
            // console.log(response);
            if (response.data.data.isExistPhoneNumber === true) {
              setPhoneNumber1('')
              setPhoneNumber2('')
              setPhoneNumber3('')
              setPhoneNumberCheck(false);
            } else {
              setPhoneNumber1(phoneNumber1)
              setPhoneNumber2(phoneNumber2)
              setPhoneNumber3(phoneNumber3)
              setPhoneNumberCheck(true);
            }
          }
        }
      ).catch();
    }
  }

  //회원가입 API CALL
  function httpPostRegister(jsonObj) {
    const api = new CommonAPI("/api/register-lovely-user");
    api.setHttpPostParam(jsonObj)
    return api.httpPostAxios();
  }

  //이메일(State)
  const [email, setEmail] = React.useState('');
  //이메일 중복확인 완료(State)
  const [emailReal, setEmailReal] = React.useState('');
  //이메일 확인 결과(State)
  const [emailCheck, setEmailCheck] = React.useState(false);
  //닉네임(State)
  const [nickName, setNickName] = React.useState('');
  //닉네임 중복확인 완료(State)
  const [nickNameReal, setNickNameReal] = React.useState('');
  //닉네임 확인 결과(State)
  const [nickNameCheck, setNickNameCheck] = React.useState(false);
  //전화번호 확인 결과(State)
  const [phoneNumberCheck, setPhoneNumberCheck] = React.useState(false);
  //패스워드 (State)
  const [password, setPassword] = React.useState('');
  //선택된 카테고리 코드(State)
  const [selectedCategoryCode, setSelectedCategoryCode] = React.useState('');
  //생일(State)
  const [birthYMD, setBirthYMD] = React.useState(getCurDate);
  //선택된 직업 코드(State)
  const [selectedJobCode, setSelectedJobCode] = React.useState('');
  //선택된 반려동물 코드(State)
  const [selectedPetCode, setSelectedPetCode] = React.useState('');
  //성별(State)
  const [sex, setSex] = React.useState('');
  //혼인여부(State)
  const [marital, setMarital] = React.useState('');
  //자녀여부(State)
  const [children, setChildren] = React.useState('');
  //자차소유여부(State)
  const [car, setCar] = React.useState('');
  //마케팅 수신 여부(State)
  // const [marketing, setMarketing] = React.useState(false);
  const [marketing] = React.useState(false);
  //자녀 성별(State)
  const [childrenSex, setChildrenSex] = React.useState('M');
  //자녀 생일(State)
  const [childrenBirthYMD, setChildrenBirthYMD] = React.useState(getCurDate);
  //휴대폰 번호 1(State)
  const [phoneNumber1, setPhoneNumber1] = React.useState('');
  //휴대폰 번호 1(State)
  const [phoneNumber2, setPhoneNumber2] = React.useState('');
  //휴대폰 번호 1(State)
  const [phoneNumber3, setPhoneNumber3] = React.useState('');
  //회원가입 후, 리다이렉트(state)
  // const [redirect, setRedirect] = React.useState(false);

  //모달(다이얼로그) 객체 생성
  const [openUse, setOpenUse] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const [openLocation, setOpenLocation] = React.useState(false);

  //이메일 핸들러
  const handleChangeEmail = event => {
    setEmail(event.target.value);
  };
  //닉네임 핸들러
  const handleChangeNickName = event => {
    setNickName(event.target.value);
  };
  //패스워드 핸들러
  const handleChangePassword = event => {
    setPassword(event.target.value);
  };
  //성별 핸들러
  const handleChangeSex = event => {
    setSex(event.target.value);
  };
  //직업코드 핸들러
  const handleChangeJobCode = event => {
    setSelectedJobCode(event.target.value);
  }
  //카테고리 코드 핸들러
  const handleChangeCategoryCode = event => {
    setSelectedCategoryCode(event.target.value);
  }
  //반려동물 코드 핸들러
  const handleChangePetCode = event => {
    setSelectedPetCode(event.target.value);
  }
  //결혼여부 핸들러
  const handleChangeMarital = event => {
    setMarital(event.target.value);
  };
  //자녀여부 핸들러
  const handleChangeChildren = event => {
    setChildren(event.target.value);
  };
  //자차여부 핸들러
  const handleChangeCar = event => {
    setCar(event.target.value);
  };
  //마케팅 수신동의여부 핸들러
  // const handleChangeTermsMarketing = event => {
  //   setMarketing(event.target.checked);
  // };
  //자녀 생일 핸들러
  const handleChangeChildrenBrithYMD = date => {
    setChildrenBirthYMD(date);
  }
  //자녀 성별 핸들러
  const handleChangeChildrenSex = event => {
    setChildrenSex(event.target.value);
  }
  //휴대폰 번호 첫번째 핸들러
  const handleChangePhoneNumber1 = event => {
    setPhoneNumber1(event.target.value);
  }
  //휴대폰 번호 두번째 핸들러
  const handleChangePhoneNumber2 = event => {
    setPhoneNumber2(event.target.value);
  }
  //휴대폰 번호 세번째 핸들러
  const handleChangePhoneNumber3 = event => {
    setPhoneNumber3(event.target.value);
  }
  //생일 핸들러
  const handleChangeBirthYMD = date  => {
    setBirthYMD(formatDate(date));
  }

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
  }

  // 다이얼로그 Open 핸들러
  const handleClickOpenTemrs = (number) => {
    if (number === 1) {
      setOpenUse(true);
    } else if (number === 2) {
      setOpenLocation(true);
    } else if (number === 3) {
      setOpenPrivacy(true);
    }
  };

  // 다이얼로그 Close 핸들러
  const handleClickCloseTemrs = (number) => {
    if (number === 1) {
      setOpenUse(false);
    } else if (number === 2) {
      setOpenLocation(false);
    } else if (number === 3) {
      setOpenPrivacy(false);
    }
  };

  // 회원가입 핸들러
  const signUp = () => {
    if (isChild(birthYMD) !== true) {
      if (emailCheck && nickNameCheck && phoneNumberCheck) {
        let requestObj = {};
        let categoryArray = [];
        let childrenArray = [];

        requestObj.userID = emailReal;
        requestObj.userPWD = password;
        requestObj.nickName = nickNameReal;
        requestObj.phoneNumber = phoneNumber1 +"-"+ phoneNumber2 +"-"+ phoneNumber3;
        requestObj.birthYMD = birthYMD;

        if (marketing === true) {
          // console.log(sex, marital, children, car, selectedPetCode, selectedCategoryCode, selectedJobCode);
          requestObj.sexType = sex;
          requestObj.maritalStatus = marital;
          requestObj.childrenStatus = children;
          requestObj.carStatus = car;
          requestObj.jobCode = selectedJobCode;
          requestObj.petCode = selectedPetCode;
          requestObj.mailReceiveStatus = marketing;

          let categoryObj = {};
          categoryObj.categoryCode = selectedCategoryCode;

          categoryArray.push(categoryObj);
          requestObj.lovelyUserCategories = categoryArray;

          if (children === 'Y') {
            let childrenObj = {};
            childrenObj.sexType = childrenSex;
            childrenObj.birthYMD = childrenBirthYMD;
            childrenArray.push(childrenObj);

            requestObj.lovelyChildren = childrenArray;
          }
        }

        // console.log(JSON.stringify(requestObj))
        httpPostRegister(JSON.stringify(requestObj)).then(
          (response) => {
            if (response.data.result === 0) {
              // console.log(response.data.result)
              history.history.push('/celebrate-sign-up');
            }
          }
        ).catch();
      }
    }
  }

  // Validation 체크 후 회원가입 버튼 활성화
  const registerValidation = () => {
      if (marketing === false) {
        if (emailReal !== '' && password !== '' && nickName !== '' && phoneNumber1 !== '' && phoneNumber2 !== '' && phoneNumber3 !== '' && phoneNumberCheck) {
          if(isChild(birthYMD)!==true){
            return true;
          }else{
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (emailReal !== '' && password !== '' && nickName !== '' && phoneNumber1 !== '' && phoneNumber2 !== '' && phoneNumber3 !== '' && sex !== '' && marital !== '' && children !== '' && car !== '' && selectedPetCode !== '' && selectedCategoryCode !== '' && selectedJobCode !== '' && phoneNumberCheck) {
          if(isChild(birthYMD)!==true){
            return true;
          }else{
            return false;
          }
        } else {
          return false;
        }
      }
  }

  // 자녀 메뉴
  function childrenPanel() {
    return (
      // childrenList.map(prop => (
      <React.Fragment key={'index'}>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <FormControl className={classes.formControlOption}>
              <InputLabel id="register-children-sex-select-label">자녀 성별</InputLabel>
              <Select
                labelId="register-children-sex-select"
                id="register-children-sex-select"
                value={childrenSex}
                onChange={handleChangeChildrenSex}
                required
              >
                <MenuItem value={'M'}>남</MenuItem>
                <MenuItem value={'F'}>여</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="date"
              label="자녀 생년월일"
              type="date"
              required
              value={childrenBirthYMD}
              className={classes.dateField}
              onChange={handleChangeChildrenBrithYMD}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
      // ))
    )
  }

  // 옵션 필드 메뉴
  const optionalFiled = () => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <FormControl className={classes.formControlOption}>
            <InputLabel id="register-sex-select-label">성별</InputLabel>
            <Select
              required
              labelId="register-sex-select"
              id="register-sex-select"
              value={sex}
              onChange={handleChangeSex}
            >
              <MenuItem value={'M'}>남</MenuItem>
              <MenuItem value={'F'}>여</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControlOption}>
            <InputLabel id="register-marital-select-label">결혼여부</InputLabel>
            <Select
              required
              labelId="register-marital-select"
              id="register-marital-select"
              value={marital}
              onChange={handleChangeMarital}
            >
              <MenuItem value={'Y'}>기혼</MenuItem>
              <MenuItem value={'N'}>미혼</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControlOption}>
            <InputLabel id="register-children-select-label">자녀여부</InputLabel>
            <Select
              required
              labelId="register-children-select"
              id="register-children-select"
              value={children}
              onChange={handleChangeChildren}
            >
              <MenuItem value={'Y'}>있음</MenuItem>
              <MenuItem value={'N'}>없음</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {children === 'Y' ? childrenPanel() : ''}

        <Grid item xs={6}>
          <FormControl className={classes.formControlOption}>
            <InputLabel id="register-car-select-label">자차소유여부</InputLabel>
            <Select
              required
              labelId="register-car-select"
              id="register-car-select"
              value={car}
              onChange={handleChangeCar}
            >
              <MenuItem value={'Y'}>있음</MenuItem>
              <MenuItem value={'N'}>없음</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControlOption}>
            <InputLabel id="register-pet-select-label">반려동물</InputLabel>
            <Select
              required
              labelId="register-pet-select"
              id="register-pet-select"
              value={selectedPetCode}
              onChange={handleChangePetCode}
            >
              {petCode === null ? 'loading' : getSelectMenu(petCode, 'pet')}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControlOption}>
            <InputLabel id="register-category-select-label">관심 카테고리</InputLabel>
            <Select
              required
              labelId="register-category-select"
              id="register-category-select"
              value={selectedCategoryCode}
              onChange={handleChangeCategoryCode}
            >
              {categoryCode === null ? 'loading' : getSelectMenu(categoryCode, 'category')}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControlOption}>
            <InputLabel id="register-job-select-label">직업</InputLabel>
            <Select
              required
              labelId="register-job-select"
              id="register-job-select"
              value={selectedJobCode}
              onChange={handleChangeJobCode}
            >
              {jobCode === null ? 'loading' : getSelectMenu(jobCode, 'job')}
            </Select>
          </FormControl>
        </Grid>
      </React.Fragment>
    )
  }

  // 메인 리턴
  // if (redirect) {
  //   return <Redirect to='/celebrate-sign-up' />;
  // } else {
    return (
      <Container component="main" maxWidth="xs">
        <Helmet>
          <title>회원가입 | 경품달(경품을 품은 달)</title>
          <meta name="description" content="경품달 회원가입 페이지입니다 | 아이폰급 철통 보안유지 | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
          <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
          <meta name="author" content="병맛사람들" />
          </Helmet>
        <React.Fragment>
          <CssBaseline />
          <div className={classes.paper}>
            <RouterLink to='/'>
            <img src={HomeLogo} alt='경품달로고' width="190px" style={{padding:'10px', marginBottom:'10px'}} className={classes.sectionDesktop}/>
              <img src={HomeLogo} alt='경품달로고' width="180px" style={{padding:'10px', marginBottom:'10px'}} className={classes.sectionMobile}/>
            </RouterLink> 
            {/* <Typography component="h1" variant="h6">
              공정한 경품문화를 선도합니다
        </Typography> */}
            <Typography component="h3" variant="body2">
              잘못된 정보 입력 시, 당첨대상에서 제외될 수 있습니다.<br/>
              추첨 및 당첨 안내를 위해, 정확한 정보를 입력해주세요 !
        </Typography>
            <br />
            <ValidatorForm onSubmit={signUp}>
             
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={9}>
                  <TextValidator
                    disabled={emailCheck}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="이메일 주소"
                    name="email"
                    value={email}
                    type='email'
                    autoComplete="email"
                    validators={['required', 'isEmail']}
                    errorMessages={['이 필드는 필수 입니다.', '이메일 주소가 아닙니다.']}
                    onChange={handleChangeEmail}
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: 13 }}>
                  {!emailCheck ? <Button color="primary" variant='contained' style={{backgroundColor:"#263880", borderColor:"#263880"}} onClick={getIsExistUser}>확인</Button> : <Button color="primary" disabled>완료</Button>}
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="비밀번호(특수문자, 숫자 포함 8자 이상)"
                    type="password"
                    inputProps={{ pattern: "^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$" }}
                    id="password"
                    value={password}
                    autoComplete='current-password'
                    onChange={handleChangePassword}
                    margin="dense"

                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={9}>
                  <TextField
                    disabled={nickNameCheck}
                    variant="outlined"
                    required
                    fullWidth
                    name="nickname"
                    label="닉네임"
                    id="nickname"
                    value={nickName}
                    type="text"
                    onChange={handleChangeNickName}
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: 13 }}>
                  {!nickNameCheck ? <Button color="primary" variant='contained' onClick={getIsExistNickName} style={{backgroundColor:"#263880", borderColor:"#263880"}}>확인</Button> : <Button color="primary" disabled>완료</Button>}
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    disabled={phoneNumberCheck}
                    name="phoneNumber1"
                    label="휴대폰"
                    type="number"
                    value={phoneNumber1}
                    InputProps={{ inputProps: { min: 0, max: 9999 } }}
                    id="phoneNumber1"
                    onChange={handleChangePhoneNumber1}
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    disabled={phoneNumberCheck}
                    name="phoneNumber2"
                    label=""
                    value={phoneNumber2}
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 9999 } }}
                    id="phoneNumber2"
                    onChange={handleChangePhoneNumber2}
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    disabled={phoneNumberCheck}
                    value={phoneNumber3}
                    name="phoneNumber3"
                    label=""
                    type="number"
                    InputProps={{ inputProps: { min: 0, max: 9999 } }}
                    id="phoneNumber3"
                    onChange={handleChangePhoneNumber3}
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: 13 }}>
                  {!phoneNumberCheck ? <Button color="primary" variant='contained' onClick={getIsExistPhoneNumber} style={{backgroundColor:"#263880", borderColor:"#263880"}}>확인</Button> : <Button color="primary" disabled>완료</Button>}
                </Grid>
              </Grid>
              <Grid item xs={12} >
                <br/>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog-birthYmd"
                  label="생년월일(만 14세 미만 가입불가)"
                  invalidDateMessage="생년월일 형식이 아닙니다"
                  invalidLabel="알수 없는 오류"
                  format="yyyy-MM-dd"
                  value={birthYMD}
                  onChange={handleChangeBirthYMD}
                  KeyboardButtonProps={{
                    'aria-label': 'change date birth ymd',
                  }}
                  required
                  style={{ minWidth: 300 }}
                />
                </MuiPickersUtilsProvider>

                {/* <TextField
                  id="date"
                  label="생년월일(만 14세 미만 가입불가)"
                  type="date"
                  required
                  value={birthYMD}
                  className={classes.dateField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeBirthYMD}
                  style={{ minWidth: 300 }}
                /> */}
              </Grid>
              <Grid item xs={12} className={classes.sectionMobile} >
                <Typography component="h1" variant="body2" style={{color:'black', paddingBottom: 22}}>
                 달력에서 연도를 클릭하시면, 연도변경이 가능합니다
              </Typography>
              </Grid>
              <Grid item xs={12}>
                {/* <Typography component="h1" variant="h6">
                  선택정보
              </Typography> */}
                <Typography component="h1" variant="body2" style={{color:'#eaa200'}}>
                  가입 후, 알림 수신동의와 선택정보 입력 시,<br/>
                  매달 무료로 추첨대상(가입형)이 되실 수 있습니다.
              </Typography>
              {/* 회원가입 후, 원하는 사람만 선택정보 입력할 수 있도록 변경(사용자들이 부담스러워 함) */}
                {/* <FormControlLabel
                  control={<Checkbox value="allowTermsMarketing" color="primary" onChange={handleChangeTermsMarketing} />}
                  label="마케팅 수신동의 / 선택 정보입력 (가입형 추첨)"
                /> */}
                <br/>
              </Grid>
              <Grid container item xs={12}>
                {marketing === true ? optionalFiled() : ''}
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={9} style={{ padding: 0 }}>
                  <FormControlLabel
                    control={<Checkbox value="allowTermsUse" color="primary" required />}
                    label="서비스 이용약관 (필수)&nbsp;&nbsp;&nbsp;"
                  />
                </Grid>
                <Grid item xs={3} style={{ padding: 4 }}>
                  <Button size="small" variant="outlined" color="inherit" onClick={() => handleClickOpenTemrs(1)}>
                    전문보기
              </Button>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={9} style={{ padding: 0 }}>
                  <FormControlLabel
                    control={<Checkbox value="allowTermsLocation" color="primary" required />}
                    label="위치정보 이용약관 (필수)"
                  />
                </Grid>
                <Grid item xs={3} style={{ padding: 4 }}>
                  <Button size="small" variant="outlined" color="inherit" onClick={() => handleClickOpenTemrs(2)}>
                    전문보기
                </Button>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={9} style={{ padding: 0 }}>
                  <FormControlLabel
                    control={<Checkbox value="allowTermsPrivacy" color="primary" required />}
                    label="개인정보 취급방침 (필수)"
                  />
                </Grid>
                <Grid item xs={3} style={{ padding: 4 }}>
                  <Button size="small" variant="outlined" color="inherit" onClick={() => handleClickOpenTemrs(3)}>
                    전문보기
                    </Button>
                </Grid>
              </Grid>
              <Button
                disabled={registerValidation() === false}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                type="submit"
              >
                가입하기
          </Button>
            </ValidatorForm>
            <Grid container justify="center">
              <Grid item xs={12}>
                <Link href="/login" style={{color:"#344ba7"}} variant="body2">
                  <br/>
                  이미 계정이 있으신가요 ? 로그인
              </Link>
              </Grid>
            </Grid>
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
          {/* 다이얼로그 모달 */}
          <Dialog
            open={openUse}
            onClose={() => handleClickCloseTemrs(1)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">{"서비스 이용약관"}</DialogTitle> */}
            <DialogContent>
              <TermsOfUse />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClickCloseTemrs(1)} color="primary">
                닫기
            </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openLocation}
            onClose={() => handleClickCloseTemrs(2)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">{"위치정보 이용약관"}</DialogTitle> */}
            <DialogContent>
              <TermsOfLocation />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClickCloseTemrs(2)} color="primary">
                닫기
            </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openPrivacy}
            onClose={() => handleClickCloseTemrs(3)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {/* <DialogTitle id="alert-dialog-title">{"개인정보 처리방침"}</DialogTitle> */}
            <DialogContent>
              <TermsOfPrivacy />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClickCloseTemrs(3)} color="primary">
                닫기
            </Button>
            </DialogActions>
          </Dialog>
          {/* 다이얼로그 모달 종료 */}
        </React.Fragment>
      </Container>
    );
  // }
})
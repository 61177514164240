import React, { useEffect, useState, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CommonAPI from '../components/CommonAPI';
import Loading from '../components/assets/Loading';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import TokenContext from '../components/ContextAPI'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Helmet } from 'react-helmet'
import CountUp from 'react-countup';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  prizeText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
  innerPaper: {
    margin: theme.spacing(1, 1),
    padding: theme.spacing(0, 0),
  },
  prizeTextHead: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
  },
  prizeTextHead2: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
  },
  prizeTextBody: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  sectionMobile: {
    display: 'inline',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  innerVideo: {
    padding: theme.spacing(2, 2),
    // height:'506px',
    [theme.breakpoints.down('md')]: {
      // height: '200px',
    },
  },
  prizeTextForMobile12: {
    [theme.breakpoints.down('md')]: {
      fontSize:'12px',
    },
  },
  prizeTextForMobile13: {
    [theme.breakpoints.down('md')]: {
      fontSize:'13px',
    },
  },
  prizeTextForMobile14: {
    [theme.breakpoints.down('md')]: {
      fontSize:'14px',
    },
  }
}));

// 경품정보 가져오기 API
function getPrizeInfo() {
  const api = new CommonAPI("/api/kyungpumdal/get-prize-info");
  return api.httpGetAxios();
}

// YOUTUBE 스트리밍 LINK 가져오기 API
// function getStreamLink() {
//   const api = new CommonAPI("/api/kyungpumdal/get-youtube-stream-link");
//   return api.httpGetAxios();
// }

// 최근 추첨 결과 가져오기 API
function getRecentPrizeInfo() {
  const api = new CommonAPI("/api/kyungpumdal/get-recent-prize-info");
  return api.httpGetAxios();
}

// comma 찍기 함수
function comma(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default function PrizeEvent() {
  const classes = useStyles();
  const [prizeInfoPur, setPrizeInfoPur] = useState(undefined);
  const [prizeInfoSub, setPrizeInfoSub] = useState(undefined);
  // const [streamLink, setStreamLink] = useState(undefined);
  const [open, setOpen] = useState(false);
  const tokenContext = useContext(TokenContext);
  const [curMonthOrderCounts, setCurMonthOrderCounts] = useState(0);
  const [curMonthAverageScore, setCurMonthAverageScore] = useState(0);
  
  const [recentPrizeInfo, setRecentPrizeInfo] = useState(undefined);
  const [totalBuyCnt, setTotalBuyCnt] = useState(0);
  const [totalPrize, setTotalPrize] = useState(0);

  const handleChangeOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getPrizeInfo().then(
      (response) => {
        if (response.data.result === 0) {
          const dataList = response.data.dataList;
          const curMonthOrderCounts = response.data.data.curMonthOrderCounts; 
          const curMonthAverageScore = response.data.data.averageScore
          let purDataList = [];
          let subDataList = [];

          setCurMonthOrderCounts(curMonthOrderCounts);
          setCurMonthAverageScore(curMonthAverageScore);

          dataList.forEach(element => {
            const yyyy = element.priceOpenDateTime.substr(0, 4);
            const MM = element.priceOpenDateTime.substr(5, 2);
            const dd = element.priceOpenDateTime.substr(8, 2);
            const hh = element.priceOpenDateTime.substr(11, 2);
            const mm = element.priceOpenDateTime.substr(14, 2);
            const ss = element.priceOpenDateTime.substr(17, 2);
            const priceOpenDt = new Date(yyyy, MM-1, dd, hh, mm, ss);

            if (priceOpenDt > new Date()) {
              element.prize = 'NotOpen'
            }
            element.priceOpenDateTime = element.priceOpenDateTime.substr(0, 10);
            element.prizeDateTime = element.prizeDateTime.substr(0, 16);

            if (element.eventType === 'p') {
              purDataList.push(element);
            } else if (element.eventType === 's') {
              subDataList.push(element);
            }
          });

          if (purDataList.length !== 0) {
            setPrizeInfoPur(purDataList);
          }
          if (subDataList.length !== 0) {
            setPrizeInfoSub(subDataList);
          }
        }
      }
    ).catch();

    getRecentPrizeInfo().then(
      (response) => {
        if (response.data.result === 0) {
          console.log(response.data);
          setTotalBuyCnt(response.data.data.totalBuyCnt);
          setTotalPrize(response.data.data.totalPrize);
          setRecentPrizeInfo(response.data.dataList);
        }
      }
    ).catch();

    // getStreamLink().then(
    //   (response) => {
    //     if (response.data.result === 0) {
    //       setStreamLink(response.data.data.val1);
    //     }
    //   }
    // ).catch();
  }, []);

  function getPrizeInfoText(prizeInfoPur, prizeInfoSub) {
    return (
      <Grid container justify='center' >
        <Grid item md={5} xs={6}>
          <Typography variant="h6" component="h3" className={classes.prizeTextHead2}>
            구매형 추첨
          </Typography>
          {/* <Divider /> */}
          {prizeInfoPur.map(prop => (
            <Paper elevation={4} key={prop.roundNumber} className={classes.innerPaper}>
              <Typography variant="body1" className={classes.prizeTextForMobile13} component="h3" >
                [{prop.roundNumber} 회차]
            </Typography>
              <Typography variant="body2" component="h3" className={classes.prizeTextForMobile12}>
                ({prop.beginApplyDate} ~ {prop.endApplyDate})
            </Typography>
              <Typography variant="body1" component="h3" className={classes.prizeTextForMobile12}>
                추첨일시 {prop.prizeDateTime}
              </Typography>
              {prop.prize !== 'NotOpen' ?
                <Typography variant="body1" component="h3" className={classes.prizeTextForMobile13}>
                  총경품가액 {comma(prop.prize)} 원
                </Typography>
                :
                <Typography variant="body1" component="h3" className={classes.prizeTextForMobile12}>
                  총경품가액 공개일 {prop.priceOpenDateTime}
                </Typography>
              }
            </Paper>
          ))}
        </Grid>
        <Grid item md={5} xs={6}>
          <Typography variant="h6" component="h3" className={classes.prizeTextHead2}>
            가입형 추첨
          </Typography>
          {prizeInfoSub.map(prop => (
            <Paper elevation={4} key={prop.roundNumber} className={classes.innerPaper}>
              <Typography variant="body1" component="h3" className={classes.prizeTextForMobile13}>
                [{prop.roundNumber} 회차]
              </Typography>
              <Typography variant="body2" component="h3" className={classes.prizeTextForMobile12}>
                ({prop.beginApplyDate} ~ {prop.endApplyDate})
            </Typography>
              <Typography variant="body1" component="h3" className={classes.prizeTextForMobile12}>
                추첨일시 {prop.prizeDateTime}
              </Typography>
              {prop.prize !== 'NotOpen' ?
                <Typography variant="body1" component="h3" className={classes.prizeTextForMobile13}>
                  총경품가액 {comma(prop.prize)} 원
                </Typography>
                :
                <Typography variant="body1" component="h3" className={classes.prizeTextForMobile12}>
                  총경품가액 공개일 {prop.priceOpenDateTime}
                </Typography>
              }
            </Paper>
          ))}
        </Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
      <title>추첨행사 | 경품달(경품을 품은 달)</title>
      <meta name="description" content="경품달 추첨이 어떻게 이루어지나요 ? | 투표를 통한 유튜브 실시간 추첨! | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
      <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
      <meta name="author" content="병맛사람들" />
      </Helmet>
      <CssBaseline />
          <Paper elevation={0} className={classes.root} style={{backgroundColor:'#f8f8f8'}}>
            <Container maxWidth="md" style={{padding:0}}>
            {/* <Typography variant="h5" component="h3" className={classes.sectionDesktop}>
              추첨행사
            </Typography> */}
            {curMonthOrderCounts!==0 && curMonthAverageScore !== 0 ? 
            <Typography variant="body1" className={classes.prizeTextForMobile14}>
              이달 구매건수 <span style={{fontWeight:'bold'}}>{curMonthOrderCounts}</span> 건<br/>
              평균 추첨점수 <span style={{fontWeight:'bold'}}>{comma(curMonthAverageScore)}</span> 점<br/>
              총경품가액은 매일 업데이트 됩니다<br/><br/>
            </Typography>
            : 
            <Typography variant="body2" className={classes.prizeTextBody}>
              총경품가액은 매일 업데이트 됩니다<br/><br/>
            </Typography>
            }
            
           
            {prizeInfoPur !== undefined && prizeInfoSub !== undefined ?
              getPrizeInfoText(prizeInfoPur, prizeInfoSub)
              :
              <Loading />}
               <br />
              {tokenContext.token !== undefined && tokenContext.token !== null && tokenContext.token !== '' ?
              <RouterLink to='/mypage' underline='none'>
                <Button color="primary" variant="outlined" style={{color:'#263880', borderColor:'#263880'}}>
                  <Typography className={classes.prizeTextForMobile13}>
                   내 추첨점수(순위)
                  </Typography>
                </Button>
              </RouterLink>
              :
              <Button color="primary" variant="outlined" style={{color:'#263880', borderColor:'#263880'}} onClick={handleChangeOpen}>
                <Typography className={classes.prizeTextForMobile13}>
                  내 추첨점수(순위)
                </Typography>
              </Button>
            }
              </Container>
          </Paper>
          
              <Paper elevation={0} className={classes.root} style={{backgroundColor:'white'}}>
              <Container maxWidth="md" style={{padding:0}}>
              <Typography variant="h5" className={classes.prizeText}>
              누적 구매수  <CountUp separator="," start={0} duration={2} end={totalBuyCnt} /> 건
              </Typography>
              <Typography variant="h5" className={classes.prizeText}>
              누적 제공액  <CountUp separator="," start={0} duration={3} end={totalPrize} /> 원
              </Typography>
              <br/>
                <Typography variant="h5" component="h3" className={classes.prizeTextHead}>
                  {/* 추첨방송(LIVE) */}
                  이달 추첨결과
                </Typography>
                <Typography variant="body1" component="h3" className={classes.sectionDesktop}>
                  {recentPrizeInfo !== undefined ? recentPrizeInfo[0].roundNumber+", "+recentPrizeInfo[1].roundNumber+' 회차 최근추첨결과': <Loading/>}
                  <br />
                  {/* 유튜브 구독과 좋아요 부탁드립니다 :)<br /> */}
                </Typography>
                <Typography variant="body2" component="h3" className={classes.sectionMobile}>
                {recentPrizeInfo !== undefined ? recentPrizeInfo[0].roundNumber+", "+recentPrizeInfo[1].roundNumber+' 회차 최근추첨결과': <Loading/>}
                  {/* 유튜브 구독과 좋아요 부탁드립니다 :)<br /> */}
                  <br/>
                </Typography>
                <br/>
                <Grid container justify='center' alignItems='center' alignContent='center'>
                <Grid item md={5} xs={6}>
                  <Typography variant="h6" component="h3" className={classes.prizeTextHead2}>
                    구매형 추첨 당첨자
                  </Typography>
                  {/* <Divider /> */}
                  {recentPrizeInfo !== undefined ? 
                    <Paper elevation={4} className={classes.innerPaper}>
                      <Typography variant="body1" className={classes.prizeTextForMobile13} component="h3" >
                      [{recentPrizeInfo[0].roundNumber} 회차]
                      </Typography>
                      <Typography variant="body1" className={classes.prizeTextForMobile12} component="h3" >
                      {recentPrizeInfo[0].nickName} 님<br/>총 경품가액 {comma(recentPrizeInfo[0].prize)} 원
                      </Typography>
                    </Paper>
                    : <Loading/>
                  }
                    {/* {streamLink !== undefined ? 
                      <iframe title="경품달 실시간 추첨행사 유튜브 스트리밍" width="100%" height="100%" src={streamLink} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    :
                      <Loading />
                    } */}
                  </Grid>
                <Grid item md={5} xs={6}>
                  <Typography variant="h6" component="h3" className={classes.prizeTextHead2}>
                    가입형 추첨 당첨자
                  </Typography>
                  {/* <Divider /> */}
                  {recentPrizeInfo !== undefined ? 
                    <Paper elevation={4} className={classes.innerPaper}>
                      <Typography variant="body1" className={classes.prizeTextForMobile13} component="h3" >
                      [{recentPrizeInfo[1].roundNumber} 회차]
                      </Typography>
                      <Typography variant="body1" className={classes.prizeTextForMobile12} component="h3" >
                      {recentPrizeInfo[1].nickName} 님<br/>총 경품가액 {comma(recentPrizeInfo[1].prize)} 원
                      </Typography>
                    </Paper>
                    : <Loading/>
                  }
                    {/* {streamLink !== undefined ? 
                      <iframe title="경품달 실시간 추첨행사 유튜브 스트리밍" width="100%" height="100%" src={streamLink} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    :
                      <Loading />
                    } */}
                  </Grid>
                </Grid>
              

                <Typography variant="body2" component="h3" className={classes.sectionDesktop}>
                <br/><br/>
              환불, 구매취소, 경품가액 최신화 등으로 인해, 총경품가액에 변동이 생길 수 있습니다.<br/>
              해당월 실적확정은 익익월 7일이며, 추첨점수와 총경품가액이 변동될 수 있습니다.<br/>
              경품달은 경품추첨 시, 최종 결정된 총경품가액 한도내에서 경품을 제공합니다.<br/>
              추첨결과에 표시된 당첨자는 연락처를 통해 개별 연락드립니다.
            </Typography>
            
            <Typography style={{fontSize:'0.6rem'}} component="h3" className={classes.sectionMobile}>
            <br/>
              환불, 구매취소, 경품가액 최신화 등으로 인해, 총경품가액에 변동이 생길 수 있습니다.<br/>
              해당월 실적확정은 익익월 7일이며, 추첨점수와 총경품가액이 변동될 수 있습니다.<br/>
              경품달은 경품추첨 시, 최종 결정된 총경품가액 한도내에서 경품을 제공합니다.<br/>
              추첨결과에 표시된 당첨자는 연락처를 통해 개별 연락드립니다.
            </Typography>
                </Container>
              </Paper>
          {/* 로그인 다이얼로그 */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"로그인 해주세요"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                추첨점수 확인은 로그인이 필요해요.<br />
                로그인 후, 이용하시겠어요?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="inherit">
                  닫기
                </Button>
              <RouterLink to="/sign-up">
                <Button onClick={handleClose} color="primary">
                  회원가입
                </Button>
              </RouterLink>
              <RouterLink to='/login'>
                <Button onClick={handleClose} color="primary" autoFocus>
                  로그인
                </Button>
              </RouterLink>
            </DialogActions>
          </Dialog>

    </React.Fragment>
        );
}
// import React, { useContext } from 'react';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
// import TokenContext from '../ContextAPI';
import Container from '@material-ui/core/Container'

// import ChildCareIcon from '@material-ui/icons/ChildCare';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 0),
      // backgroundColor:'#f8f8f8',
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0),
    },
  },
  registerText: {
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
    },
  },
  sectionMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  innerPaper: {
    padding: theme.spacing(4, 3),
    width: '80%',
  },
}));

export default function HowToUse() {
  const classes = useStyles();
  // const tokenContext = useContext(TokenContext);

  // function registerOrHowToUse() {
  //   if (tokenContext.token !== undefined) {
  //     return (
  //       <Link to='/how-to-use'>
  //         <Button variant="contained"  size="small" color="primary">
  //           <Typography className={classes.registerText}>
  //             "이용방법 GO"
  //           </Typography>
  //         </Button>
  //       </Link>
  //     )
  //   } else {
  //     return (
  //       <Link to='/sign-up'>
  //         <Button size="small" color="primary" >
  //           <Typography className={classes.registerText}>
  //             "추첨대상 되기"
  //           </Typography>
  //         </Button>
  //       </Link>
  //     )
  //   }
  // }

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper elevation={0} className={classes.root}>
      <Container maxWidth="md"> 
        <div className={classes.sectionDesktop}>
          <div>
            <Typography variant="h4" component="h3" >
              추첨 대상이 되는 2가지 방법
          </Typography>
              {/* <ChildCareIcon style={{fontSize:'12pt'}}/> */}
            <Typography variant="body1" component="h3" >
              [ 하나만 해도 추첨대상! ]
           </Typography>
              {/* <ChildCareIcon style={{fontSize:'12pt'}}/> */}
            <br />
            {/* <div>
              {registerOrHowToUse()}
            </div>
            <br /> */}
            <Grid container justify='center'>
              <Paper elevation={4} className={classes.innerPaper}>
              <Grid container justify='center'>
              <Grid item xs={6}>
                <Grid item xs={12}>
                <Typography variant="h6" component="h3" >
                  첫번째 (가입형)
              </Typography>
              </Grid>
              <br/>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h3" >
                      회원가입!<br/>
                      선택정보 입력!<br/>
                      알림 수신동의!
              </Typography>
              </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12}>
                <Typography variant="h6" component="h3" >
                  두번째 (구매형)
                </Typography>
                </Grid>
                <br/>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h3" >
                      경품달 로그인 !<br/>
                      쇼핑몰에 접속 !<br/>
                      평소처럼 쇼핑하기 !
                    </Typography>
                </Grid>
                </Grid>
                </Grid>
              </Paper>
            </Grid>
          </div>
          <br/>
                <Grid container justify='center'>
                  <Grid item xs={12}>
                    <Link to='/how-to-use'>
                      <Button color="primary" variant="outlined" style={{marginTop:10}}>
                        <Typography>
                          자세히보기
                      </Typography>
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
        </div>
        <div className={classes.sectionMobile}>
          <div>
            {/* <Typography variant="h5" component="h3" >
              추첨 대상되는법
            </Typography> */}
            {/* <br/>
            <div>
              {registerOrHowToUse()}
            </div>
            <br />
            <Typography variant="h6" component="h3" >
              첫번째 방법 (가입형)
          </Typography>
            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid item xs>
                <Typography  variant='body1'>
                  회원가입!<br/>선택정보 입력<br />마케팅 수신동의!
              </Typography>
              </Grid>
            </Grid>
            <br />
            <Typography variant="h6" component="h3" >
              두번째 방법 (구매형)
          </Typography>
            <Grid container spacing={1} alignItems="center" justify="center">
              <Grid item xs>
                <Typography variant='body1'>
                경품달 로그인 !<br/>
                쇼핑몰에 접속 !<br/>
                평소처럼 쇼핑하기 !
              </Typography>
              </Grid>
            </Grid>
            <br/> */}
            {/* {tokenContext.token === undefined ?
                <Grid container justify='center'>
                  <Grid item xs={12} className={classes.sectionDesktop}>
                    <Link to='/how-to-use'>
                      <Button color="primary" >
                        <Typography>
                          이용방법 보러가기<br/>
                      </Typography>
                      </Button>
                    </Link>
                  </Grid>
                </Grid> : ''} */}
          </div>
        </div>
        </Container>
      </Paper>
    </React.Fragment>
  );
}
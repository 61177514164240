import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles(theme => ({
  innerPaper: {
    textAlign:'left',
    lineHeight: '2em',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 6),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1),
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  head: {
    fontSize: '20px',
    fontWeight: 'bold'
  }
}));

export default function TermsOfPrivacy() {
  const classes = useStyles();
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
          <Helmet>
          <title>개인정보 처리방침 | 경품달(경품을 품은 달)</title>
          <meta name="description" content="경품달 개인정보 처리방침 | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
          <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
          <meta name="author" content="병맛사람들" />
          </Helmet>
      <CssBaseline />
        <Paper elevation={2} className={classes.innerPaper}>
        <Container maxWidth="md">
        <span className={classes.head}>개인정보 처리방침</span>
        <br />
        <br />
        (병맛사람들)(https://byeongmat.com 이하 '경품을 품은 달(경품달)')은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
        <br/>
        <br/>(병맛사람들)('경품달') 은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
        <br/>
        <br/>○ 본 방침은부터 2020년 01월 01일부터 시행됩니다.
        <br/>
        <br/>
        <br/><span className={classes.title}>1. 개인정보의 처리 목적</span>
        <br/>(병맛사람들)(https://byeongmat.com 이하  '경품을 품은 달(경품달)')은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
        <br/>
        <br/>가. 홈페이지 회원가입 및 관리
        <br/>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
        <br/>
        <br/>나. 민원사무 처리
        <br/>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.
        <br/>
        <br/>다. 재화 또는 서비스 제공
        <br/>물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산, 경품추첨 및 당첨사실 안내 및 지급과 관련된 모든사항 등을 목적으로 개인정보를 처리합니다.
        <br/>
        <br/>라. 마케팅 및 광고에의 활용
        <br/>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
        <br/>
        <br/>마. 기타
        <br/>경품추첨 및 당첨사실 안내 및 지급과 관련된 모든사항 등을 목적으로 개인정보를 처리합니다.
        <br/>
        <br/>
        <br/><span className={classes.title}>2. 개인정보 파일 현황</span>
        <br/>
        <br/>
        <br/><span className={classes.title}>3. 개인정보의 처리 및 보유 기간</span>
        <br/>
        <br/>① (병맛사람들)('경품을 품은 달 (경품달)')은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.
        <br/>
        <br/>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
        <br/>
        <br/><span className={classes.title}>1.(홈페이지 회원가입 및 관리)</span>
        <br/>(홈페이지 회원가입 및 관리)와 관련한 개인정보는 수집.이용에 관한 동의일로부터(3년)까지 위 이용목적을 위하여 보유.이용됩니다.
        <br/>-보유근거 : 정보주체의 동의
        <br/>-관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
        <br/>2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
        <br/>3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
        <br/>4) 계약 또는 청약철회 등에 관한 기록 : 5년
        <br/>
        <br/>
        <br/>
        <br/><span className={classes.title}>2.(민원사무 처리)</span>
        <br/>(민원사무 처리)와 관련한 개인정보는 수집.이용에 관한 동의일로부터(3년)까지 위 이용목적을 위하여 보유.이용됩니다.
        <br/>-보유근거 : 정보주체의 동의
        <br/>-관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
        <br/>2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
        <br/>3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
        <br/>4) 계약 또는 청약철회 등에 관한 기록 : 5년
        <br/>
        <br/>
        <br/>
        <br/><span className={classes.title}>3.(재화 또는 서비스 제공)</span>
        <br/>(재화 또는 서비스 제공)와 관련한 개인정보는 수집.이용에 관한 동의일로부터(3년)까지 위 이용목적을 위하여 보유.이용됩니다.
        <br/>-보유근거 : 정보주체의 동의
        <br/>-관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
        <br/>2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
        <br/>3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
        <br/>4) 계약 또는 청약철회 등에 관한 기록 : 5년
        <br/>
        <br/>
        <br/>
        <br/><span className={classes.title}>4.(마케팅 및 광고에의 활용)</span>
        <br/>(마케팅 및 광고에의 활용)와 관련한 개인정보는 수집.이용에 관한 동의일로부터(3년)까지 위 이용목적을 위하여 보유.이용됩니다.
        <br/>-보유근거 : 정보주체의 동의
        <br/>-관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
        <br/>2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
        <br/>3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
        <br/>4) 계약 또는 청약철회 등에 관한 기록 : 5년
        <br/>
        <br/>
        <br/>
        <br/><span className={classes.title}>5.(기타)</span>
        <br/>(기타)와 관련한 개인정보는 수집.이용에 관한 동의일로부터(3년)까지 위 이용목적을 위하여 보유.이용됩니다.
        <br/>-보유근거 : 정보주체의 동의
        <br/>-관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
        <br/>2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
        <br/>3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
        <br/>4) 계약 또는 청약철회 등에 관한 기록 : 5년
        <br/>
        <br/>
        <br/>
        <br/><span className={classes.title}>4. 개인정보의 제3자 제공에 관한 사항</span>
        <br/>
        <br/>① (병맛사람들)(https://byeongmat.com 이하 '경품을 품은 달(경품달)')은(는) 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
        <br/>
        <br/>② (병맛사람들)(https://byeongmat.com)은(는) 다음과 같이 개인정보를 제3자에게 제공하지 않고 있습니다.
        <br/>
        <br/><span className={classes.title}>1.  (제 3자 제공 : 제공하지 않음)</span>
        <br/>- 개인정보를 제공받는 자 : 제공하지 않음
        <br/>- 제공받는 자의 개인정보 이용목적 : 제공하지 않음
        <br/>- 제공받는 자의 보유.이용기간: 제공하지 않음
        <br/>
        <br/>
        <br/><span className={classes.title}>2.  (개인정보처리 위탁 : 위탁하지 않음)</span>
        <br/>
        <br/>① (병맛사람들)(이하 '경품을 품은 달(경품달)')은(는) 다음과 같이 개인정보 처리업무를 위탁하지 않고 있습니다.
        <br/>
        <br/>1. (위탁하지 않음)
        <br/>- 위탁받는 자 (수탁자) : 위탁하지 않음
        <br/>- 위탁하는 업무의 내용 : 위탁하지 않음
        <br/>- 위탁기간 : 위탁하지 않음
        <br/>
        <br/>② (병맛사람들)(https://byeongmat.com 이하 '경품을 품은 달(경품달)')은(는) 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
        <br/>
        <br/>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
        <br/>
        <br/>
        <br/><span className={classes.title}>5. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.</span>
        <br/>
        <br/>① 정보주체는 병맛사람들에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.
        <br/>② 제1항에 따른 권리 행사는병맛사람들에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 병맛사람들은(는) 이에 대해 지체 없이 조치하겠습니다.
        <br/>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        <br/>④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
        <br/>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        <br/>⑥ 병맛사람들은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
        <br/>
        <br/>
        <br/><span className={classes.title}>6. 처리하는 개인정보의 항목 작성</span>
        <br/>
        <br/>① (병맛사람들)(https://byeongmat.com 이하 '경품을 품은 달(경품달)')은(는) 다음의 개인정보 항목을 처리하고 있습니다.
        <br/>
        <br/>1(홈페이지 회원가입 및 관리)
        <br/>- 필수항목 : 이메일, 휴대전화번호, 비밀번호, 생년월일, 닉네임
        <br/>- 선택항목 : 성별, 직업, 기념일, 결혼여부, 반려동물, 자차소유여부, 자녀여부, 관심카테고리 등
        <br/>
        <br/>
        <br/>2(민원사무 처리)
        <br/>- 필수항목 : 이메일, 휴대전화번호, 비밀번호, 생년월일, 닉네임
        <br/>- 선택항목 : 성별, 직업, 기념일, 결혼여부, 반려동물, 자차소유여부, 자녀여부, 관심카테고리 등
        <br/>
        <br/>
        <br/>3(재화 또는 서비스 제공)
        <br/>- 필수항목 : 이메일, 휴대전화번호, 비밀번호, 생년월일, 닉네임
        <br/>- 선택항목 : 성별, 직업, 기념일, 결혼여부, 반려동물, 자차소유여부, 자녀여부, 관심카테고리 등
        <br/>
        <br/>
        <br/>4(마케팅 및 광고에의 활용)
        <br/>- 필수항목 : 이메일, 휴대전화번호, 비밀번호, 생년월일, 닉네임
        <br/>- 선택항목 : 성별, 직업, 기념일, 결혼여부, 반려동물, 자차소유여부, 자녀여부, 관심카테고리 등
        <br/>
        <br/>
        <br/>5(기타)
        <br/>- 필수항목 : 이메일, 휴대전화번호, 비밀번호, 생년월일, 닉네임
        <br/>- 선택항목 : 성별, 직업, 기념일, 결혼여부, 반려동물, 자차소유여부, 자녀여부, 관심카테고리 등
        <br/>
        <br/>
        <br/><span className={classes.title}>7. 개인정보의 파기</span>
        <br/>(병맛사람들)('경품달')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
        <br/>
        <br/>-파기절차
        <br/>이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
        <br/>
        <br/>-파기기한
        <br/>이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 10일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 10일 이내에 그 개인정보를 파기합니다.
        <br/>
        <br/>-파기방법
        <br/>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
        <br/>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        <br/>
        <br/>
        <br/><span className={classes.title}>8. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</span>
        <br/>
        <br/>① 병맛사람들 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구)인터넷 옵션)개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
        <br/>
        <br/>
        <br/><span className={classes.title}>9. 개인정보 보호책임자 작성</span>
        <br/>
        <br/>① 병맛사람들(https://byeongmat.com 이하 '경품을 품은 달(경품달)') 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        <br/>
        <br/>▶ 개인정보 보호책임자
        <br/>성명 : 양다미
        <br/>직책 : CMO
        <br/>연락처 : dmyang@byeongmat.com
        <br/>※ 개인정보 보호 담당부서로 연결됩니다.
        <br/>
        <br/>▶ 개인정보 보호 담당부서
        <br/>담당자 : 양다미
        <br/>연락처 : dmyang@byeongmat.com
        <br/>② 정보주체께서는 병맛사람들(https://byeongmat.com 이하 '경품을 품은 달(경품달)') 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 병맛사람들(https://byeongmat.com 이하 '경품을 품은 달(경품달)') 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
        </Container>
        </Paper>
    </React.Fragment>
  );
}
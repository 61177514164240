import React from 'react';
import {withRouter} from 'react-router-dom'
import FooterText from '../footer/FooterText';

class Footer extends React.Component {
    render() {
        if(this.props.history.location.pathname==='/login' || this.props.history.location.pathname==='/sign-up'){
            return null;
          }else{
            return (
                <div className="Footer">
                    <div>
                        <FooterText />
                    </div>
                </div>
            );
        }
    }
}
export default withRouter(Footer);
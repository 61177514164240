import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import { Helmet } from 'react-helmet'


const useStyles = makeStyles(theme => ({
  innerPaper: {
    textAlign:'left',
    lineHeight: '2em',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 6),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1),
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  head: {
    fontSize: '20px',
    fontWeight: 'bold'
  }
}));

export default function TermsOfUse() {
  const classes = useStyles();
  window.scrollTo(0, 0);

  return (
    <React.Fragment>
         <Helmet>
          <title>서비스 이용약관 | 경품달(경품을 품은 달)</title>
          <meta name="description" content="경품달 서비스 이용약관 | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
          <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
          <meta name="author" content="병맛사람들" />
          </Helmet>
      <CssBaseline />
          <Paper elevation={2} className={classes.innerPaper}>
        <Container maxWidth="md">
            <span className={classes.head}>서비스 이용약관</span>
            <br />
            <br />
            <span className={classes.title}>제 1 조 목적</span>
            <br />이 약관은 병맛사람들(이하 "회사"라 함)과 회사가 운영하는 인터넷사이트 (www.kyungpumdal.com, 이하 "경품을 품은 달(경품달)"이라 함)를 통해서 제공하는 광고·마케팅 관련 서비스 등(이하 "서비스"라 함)을 이용하는 자의 권리, 의무를 정함으로써 상호 발전을 도모하는 것을 그 목적으로 합니다.
            <br />
            <br /><span className={classes.title}>제 2 조 약관의 명시 및 효력과 개정</span>
            <br />① 회사는 이 약관의 내용을 회사의 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편주소 등)등과 함께 회원이 확인할 수 있도록 "경품을 품은 달(경품달)" 초기 서비스 화면(전면)에 게시합니다. 단, 모바일 서비스등은 화면의 제약등으로 인하여 별도의 페이지에 게시합니다.
            <br />② 회사는 약관의 규제에 관한 법률, 전자문서 및 전자거래 기본법, 전자서명법, 정보통신망 이용 촉진 및 정보보호등에 관한 법률(이하 “정보통신망법”이라 함) 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
            <br />③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 공지사항에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
            <br />④ 회사가 전 항에 따라 개정약관을 공지할 때에 이용자가 명시적으로 거부의 의사를 표시하지 않거나 이용계약을 해지하지 않는 경우 개정약관에 동의한 것으로 봅니다.
            <br />⑤ 회사가 이용자에게 이용약관의 변경을 공지하였음에도 불구하고 이용자가 이를 알지 못하여 발생하는 피해에 대하여 회사는 책임지지 않습니다.
            <br />
            <br /><span className={classes.title}>제 3 조 용어의 정의</span>
            <br />① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <br />1. 경품을 품은 달(경품달) : 회사가 운영하는 인터넷사이트(www.kyungpumdal.com)로서, 이용자에게 컴퓨터 등 정보통신설비를 통하여 재화 또는 용역에 대한 가격 등 정보를 제공하며, 경품 및 추첨행사를 진행하는 가상의 영업장을 말합니다.
            <br />2. 회원 : 회사에 개인정보를 제공하여 회원 등록을 한 자로서, 회사의 정보를 지속적으로 제공받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
            <br />3. 이용자 : 경품달에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
            <br />4. 비회원 : 회원에 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.
            <br />5. 아이디(ID) : 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인하여 등록된 문자와 숫자의 조합을 말합니다.
            <br />6. 비밀번호(Password) : 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여 회사에 등록한 영문과 숫자 또는 특수문자의 조합을 말합니다.
            <br />7. 제휴사 : 경품을 품은 달(경품달)과 계약 하에 재화 또는 용역의 노출 및 광고를 목적으로 경품달에 정보를 제공하고 있는 인터넷쇼핑몰 및 제휴마케팅 중계업자를 말합니다.
            <br />8. 구매자 : 재화 또는 용역을 구입할 의사를 온라인으로 제공하는 양식에 맞추어 밝힌 이용자를 말합니다.
			<br />9. 가입형 추첨대상 : 경품을 품은 달(경품달) 이용방법 페이지에 따라 가입형 추첨대상의 의사를 온라인으로 제공하는 양식에 맞추어 밝힌 이용자를 말합니다.
            <br />10. 구매형 추첨대상 : 경품을 품은 달(경품달) 이용방법 페이지에 따라 구매형 추첨대상의 의사를 온라인으로 제공하는 양식에 맞추어 밝힌 이용자를 말합니다.
            <br />② 제1항에서 정의되지 않은 이 약관상의 용어의 의미는 일반적인 거래관행에 의합니다.
            <br />
            <br /><span className={classes.title}>제 4 조 서비스의 제공 범위 및 한계</span>
            <br />① 회사는 다음과 같은 업무를 수행합니다.
            <br />1. 제휴사에서 판매하는 재화 또는 용역의 정보 및 가격을 제공
            <br />2. 재화 또는 용역에 대한 정보 제공 및 이용자와 제휴사의 구매계약 중개
			<br />3. 경품 추첨행사 진행과 일련의 현상을 통한 경품당첨자 선정 및 경품 제공
            <br />4. 기타 본 조항에 정한 업무에 부수한 업무
            <br />② 회원은 회사가 별도 고지한 서비스에 있어서 이용약관, 개인정보처리방침 및 기타 회사가 공지하는 사항을 항상 숙지해야 하며, 이를 숙지하지 못해 생기는 불이익은 회원에게 있습니다.
            <br />③ 회사가 제공하는 서비스는 이용자와 제휴사간에 재화 또는 용역을 거래할 수 있도록 경품을 품은 달(경품달)의 이용을 허락하거나, 통신판매를 중개하고 재화 또는 용역의 정보만을 제공하는 것을 목적으로 하며, 회사는 제휴사가 경품을 품은 달(경품달)에 제공하는 재화 또는 용역의 정보에 관련해서는 일체의 책임을 지지 않습니다.
			<br />④ 회사는 제휴마케팅 수익금을 통해 추첨행사를 진행하며, 사행성을 조장하지 않습니다. 사행성을 조장하는 회원의 경우, 이를 숙지하지 못해 생기는 불이익은 해당 회원에게 있습니다.
			<br />⑤ 회사는 경품 추첨과정 중 조작 과정이 없도록 하며, 추첨 과정 중 불공정한 조작이 있는 경우 이에 대한 책임을 집니다.
            <br />
            <br /><span className={classes.title}>제 5 조 이용계약의 성립</span>
            <br />① 이용계약은 회원이 되고자 하는 자(이하 "가입신청자")가 이 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 "회사"가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
            <br />② 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
            <br />1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 단, 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함.
            <br />2. 실명이 아니거나 타인의 명의를 이용한 경우
            <br />3. 허위의 정보를 기재하거나, 회사가 제시하는 필수 내용을 기재하지 않은 경우
            <br />4. 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우
            <br />5. 이용자의 귀책사유로 인하여 승낙이 불가능하거나 기타 사항을 위반하여 신청하는 경우
			<br />6. 회원이 구매를 통한 사행 행위를 시도하거나, 이를 조장하는 경우
            <br />③ 제1항에 따른 신청에 있어 회사는 회원의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
            <br />④ 회사는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
            <br />⑤ 제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록 합니다.
            <br />⑥ 이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.
            <br />⑦ 회사는 회원에 대해 회사 정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
            <br />⑧ 회사는 회원에 대하여 "영화및비디오물의진흥에관한법률" 및 "청소년보호법"등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.
            <br />
            <br /><span className={classes.title}>제 6 조 개인정보의 변경 및 보호</span>
            <br />① 회원은 이용신청 시 허위의 정보를 제공하여서는 아니 되며, 기재한 사항이 변경되었을 경우에는 즉시 변경사항을 최신의 정보로 수정하여야 합니다. 단, ID등은 수정할 수 없으나 법령에 의한 경우는 예외로 합니다.
            <br />② 수정하지 않은 정보로 인하여 발생하는 손해는 당해 회원이 전적으로 부담하며, 회사는 이에 대하여 아무런 책임을 지지 않습니다.
            <br />③ 회사는 이용계약을 위하여 회원이 제공한 정보를 서비스 운영을 위한 목적 이외의 용도로 사용하거나 이용자의 동의 없이 제3자에게 제공하지 않습니다. 단, 법령에서 회원정보의 이용과 제3자에 대한 정보제공을 허용하고 있는 경우는 예외로 합니다.
            <br />④ 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
            <br />
            <br /><span className={classes.title}>제 7 조 아이디 및 비밀번호의 관리</span>
            <br />① 아이디(ID) 및 비밀번호에 대한 관리책임은 회원에게 있으며, 회원은 어떠한 경우에도 본인의 아이디(ID) 또는 비밀번호를 타인에게 양도하거나 대여할 수 없습니다.
            <br />② 회사의 귀책사유 없이 아이디(ID) 또는 비밀번호의 유출, 양도, 대여로 인하여 발생하는 손실이나 손해에 대하여는 이용자 본인이 그에 대한 책임을 부담합니다.
            <br />③ 회원은 아이디(ID) 또는 비밀번호를 도난당하거나 제3자가 무단으로 사용하고 있음을 인지한 경우, 즉시 회사에 통보하여야 하고 회사는 이에 대한 신속한 처리를 위하여 최선의 노력을 다하여야 합니다.
            <br />
            <br /><span className={classes.title}>제 8 조 이용계약의 종료</span>
            <br />① 회원은 언제든지 회사에게 해지의사를 통지함으로써 이용계약을 해지할 수 있습니다. 다만, 회원은 해지의사를 통지하기 최소한 7일 전에 모든 거래를 완결시키는데 필요한 조치를 취하여야만 합니다.
            <br />② 전항의 기간 내에 회원이 발한 의사표시로 인해 발생한 불이익에 대한 책임은 회원 본인이 부담하여야 하며, 이용계약이 종료되면 회사는 회원에게 부가적으로 제공한 각종 혜택과 추첨에 따른 경품당첨 권한을 회수할 수 있습니다.
            <br />③ 회원의 의사로 이용계약을 해지 한 후, 추후 재이용을 희망할 경우에는 재이용 의사가 회사에 통지되고, 이에 대한 회사의 승낙이 있는 경우에만 서비스 재이용이 가능합니다.
            <br />④ 회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할 수 있습니다
            <br />1. 다른 회원의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 공서양속에 위배되는 행위를 한 경우
            <br />2. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
            <br />3. 제5조 제2항의 승낙거부사유가 있음이 확인된 경우
            <br />4. 기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우
            <br />⑤ 회사가 이용계약을 해지를 하는 경우 회원에게 e-mail, SMS, 전화, 기타의 방법을 통하여 해지사유를 밝혀 해지의사를 통지합니다. 이용계약은 회사의 해지의사를 회원에게 통지한 시점에 종료됩니다.
            <br />⑥ 회사가 이용계약을 해지하더라도, 해지 이전에 이미 체결된 매매계약의 완결과 관련해서는 이 약관이 계속 적용됩니다.
            <br />⑦ 이용계약이 종료될 시에는 회사는 회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.
            <br />⑧ 이용계약이 종료된 경우에는, 회원의 재이용신청에 대하여 회사는 이에 대한 승낙을 하지 아니할 수 있습니다.
            <br />
            <br /><span className={classes.title}>제 9 조 서비스 기간과 중단</span>
            <br />① 본 약관에 따른 서비스 기간은 서비스 신청일로부터 이용계약의 해지 시까지 입니다.
            <br />② 회사는 업무상 또는 기술상 특별한 사정이 없는 한 연중무휴, 1일 24시간 서비스를 제공합니다.
            <br />③ 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 증설, 교체 및 고장, 통신의 두절, 시스템 장애 또는 운영 상의 상당한 사유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 서비스 일시 중단 사실과 그 사유를 경품을 품은 달(경품달) 초기화면에 통지합니다.
            <br />④ 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부의 이용을 제한하거나 정지 할 수 있습니다.
            <br />⑤ 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 회사 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.
            <br />
            <br /><span className={classes.title}>제 10 조 서비스 이용료</span>
            <br />회사는 이용자와 제휴사 간의 자유로운 전자상거래에 있어서 필요로 하는 각종 서비스를 제공하고 회사의 내부 정책에 따라 그에 대한 이용료(서비스 이용료)를 부과할 수 있습니다.
            <br />
            <br /><span className={classes.title}>제 11 조 이용자 관리</span>
            <br />① 회사는 이 약관과 관련법령 및 상거래의 일반원칙을 위반한 회원에 대하여 다음과 같은 조치를 할 수 있습니다.
            <br />1. 회사가 부가적으로 제공한 혜택의 일부 또는 전부의 회수
            <br />2. 특정 서비스 이용제한
            <br />3. 이용계약의 해지
            <br />4. 손해배상의 청구
            <br />② 회사가 전항 각 호에 정한 조치를 할 경우 회사는 사전에 회원에게 유선 또는 이메일로 통보하며, 회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한 경우 선조치 후 통보할 수 있습니다.
            <br />③ 회원은 본조에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우 이에 대하여 항변을 할 수 있습니다.
            <br />
            <br /><span className={classes.title}>제 12 조 회원에 대한 혜택 부여</span>
            <br />① 회사는 회원의 구매 금액, 횟수, 빈도 및 기타 이용 실적 등을 토대로 회원에게 소정의 등급을 부여할 수 있으며, 각 등급에 따라 일정한 혜택을 부여할 수 있습니다.
            <br />② 회사가 회원에게 부여하는 등급 및 혜택에 관한 제반사항 및 그 변경은 별도의 서비스 화면에 공지할 수 있습니다.
			<br />③ 구매형 추첨대상의 경우, 회원의 구매액에 따라 회사가 제공받는 커미션금액에 따라 추첨점수를 적립할 수 있습니다. 이때 커미션 금액은 같은 금액의 재화 또는 서비스를 구매하였더라도, 제휴사에 따라 달라질 수 있습니다.
			<br />④ 가입형 추첨대상의 경우, 경품을 품은 달(경품달) 이용방법 페이지에 따라 가입형 추첨대상의 의사를 밝힘에 따라 추첨점수를 적립할 수 있습니다. 각 월별 가입형 추첨대상의 최대점수는 각 월별 일수의 전체 합계로 합니다. 
			<br />⑤ 추첨행사를 통해, 경품 당첨자가 선정된 경우, 해당 회원이 당첨 되었음을 SMS, 푸시, 이메일, 서비스 플랫폼, 경품을 품은 달(경품달)를 통해 공개할 수 있습니다. 단, 회원의 동의가 없는 경우 개인정보를 비식별처리하여 공개합니다.
			<br />⑥ 추첨행사를 통해, 경품 당첨자가 선정된 경우, 선정된 회원은 회사로부터 경품을 제공받기 위해 관련된 세금(제세공과금 등)을 회사에 제공해야합니다. 회사가 고지한 기간 내 회원이 관련된 세금(제세공과금 등)을 회사에 제공하지 않은 경우에는 회사는 경품을 회원에게 제공하지 않을 수 있습니다. (오만원(50,000 원) 미만의 가액을 가진 경품의 경우 제세공과금은 제외됩니다.)
			<br />⑥ 추첨행사를 통해, 경품 당첨자가 선정된 경우, 선정된 회원은 회사로부터 경품을 제공받기 위해 관련된 정보를 회사에 제공 해야합니다. 회원이 회사가 고지한 기간 내 관련된 정보를 회사에 제공하지 않는 경우, 회사는 선정된 회원에게 경품을 제공하지 않을 수 있습니다.
			<br />⑦ 회사가 주최하는 추첨행사와 이후 경품지급 과정 중 회원의 잘못된 정보입력 또는 회원에게 책임이 있는 어떠한 사유로든 회원과 연락이 되지 않는 경우, 회사는 해당 회원에게 경품을 제공하지 않을 수 있습니다. 또한 사행성을 조장하거나 관련된 사행행위를 하는 등과 같은 이유로 회사가 판단하기에 회사에 부정적인 영향을 미치는 경우, 추첨과정중 선정된 회원이라고 해도, 해당 회원은 추후 경품당첨 선정 대상에서 제외될 수 있습니다.
            <br />
            <br /><span className={classes.title}>제 13 조 저작권의 귀속 및 이용제한</span>
            <br />① 회사는 서비스를 이용함에 있어 저작권자의 저작권 보호를 위한 정책을 수립하여 운영하며 회원은 회사의 저작권 정책을 준수하여야 합니다.
            <br />② 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
            <br />③ 회원이 창작하여 경품을 품은 달(경품달)에 게재 또는 등록한 게시물에 대한 저작권은 회원 본인에게 있으며 해당 게시물이 타인의 지적 재산권을 침해하여 발생되는 모든 책임은 회원 본인에게 있습니다.
            <br />④ 이용자는 경품을 품은 달(경품달)을 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
            <br />⑤ 회원은 자신이 창작, 등록한 게시물을 회사의 서비스를 운영, 전송 배포 또는 홍보를 위해 사용료 없는 영구적인 사용권을 회사에 부여합니다. 사용권은 회원이 탈퇴한 후에도 유효하며, 세부사항은 아래와 같습니다.
            <br />1. 회사가 제공하는 관련 서비스 내에서 회원의 게시물에 대한 복제, 전시, 배포, 편집저작물 작성
            <br />2. 제휴사에게 회원의 게시물 내용을 제공. 단 회원의 아이디 외의 개인정보는 제공하지 않습니다.
            <br />⑥ 회원의 게시물에 대해 제 3자로부터 저작권 및 기타 권리의 침해로 이의가 제기된 경우, 회사는 해당 게시물을 임의로 삭제할 수 있으며 해당 게시물에 대한 법적 문제가 종결된 후 회원이 이를 근거로 회사에 신청하는 경우에만 상기 임시 삭제된 게시물을 다시 게재할 수 있습니다.
            <br />⑦ 회사는 게시물이 다음 각 호에 해당하는 경우 사전 통보 없이 해당 게시물을 삭제하거나 게시자에 대하여 특정 서비스의 이용제한, 이용계약의 해지 등의 조치를 할 수 있습니다.
            <br />1. 대한민국의 법령을 위반하는 내용을 포함하는 경우
            <br />2. 관계법령에 의거 판매가 금지된 불법제품 또는 음란물을 게시, 광고하는 경우
            <br />3. 허위 또는 과대광고의 내용을 포함하는 경우
            <br />4. 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 경우
            <br />5. 직거래 유도 또는 타 사이트의 링크를 게시하는 경우
            <br />6. 정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를 포함하는 경우
            <br />7. 사회 공공질서나 미풍양속에 위배되는 경우
            <br />8. 회사가 제공하는 서비스의 원활한 진행을 방해하는 것으로 판단되는 경우
            <br />
            <br /><span className={classes.title}>제 14 조 금지행위</span>
            <br />① 회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나 시스템에 접근하는 행위는 금지됩니다.
            <br />② 이용자는 경품을 품은 달(경품달)을 디컴파일, 해킹 또는 리버스 엔지니어링 등의 방법으로 소스코드를 분석하거나 임의로 변경 또는 서비스의 정상적인 운영을 방해하려는 시도를 해서는 안 됩니다.
            <br />③ 회사는 시스템 부정행위가 확인된 경우 해당 회원에 대하여 회사가 부가적으로 제공한 혜택의 일부 또는 전부의 회수, 특정 서비스의 이용제한, 이용계약의 해지 등의 조치를 할 수 있으며 이로 인하여 발생한 손해가 있을 시 이의 배상을 청구할 수 있습니다.
            <br />④ 이용자는 회사와 정당한 계약 또는 회사의 동의 없이 재화 또는 용역을 재판매할 수 없고 이를 이용하여 영업 활동을 할 수 없으며, 회사는 이용자가 이 약관에 위반한 영업 활동으로 인하여 발생한 결과에 대하여 책임을 지지 않습니다.
            <br />⑤ 이용자는 회사가 제공하는 할인율 등을 이용하여 비정상적인 다수의 거래를 체결시킴으로써 회사에 손실을 발생시키고 공정한 시장 환경 조성에 방해가 되는 행위 등을 하여서는 아니 되며, 해당 내용이 확인된 경우 회사는 당해 거래를 취소할 수 있으며 경우에 따라 거래 중지 등의 제재 조치를 가할 수 있습니다.
            <br />⑥ 이용자가 본 조에 정한 내용을 위반한 경우 회사에 대하여 손해배상 의무를 집니다.
            <br />
            <br /><span className={classes.title}>제 15 조 약관 외 준칙 및 관련 법령과의 관계</span>
            <br />① 이 약관에 명시되지 않은 사항은 관련 법령의 규정과 일반 상관례에 의합니다.
            <br />② 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 "개별약관"이라고 합니다)을 정하여 이를 회사 홈페이지 등을 통해 미리 공지할 수 있습니다.
            <br />③ 회사는 전항의 개별약관에 변경이 있을 경우, 시행 7일 이전에 해당 변경사항을 공지합니다.
            <br />④ 회원은 이 약관 및 개별약관의 내용에 변경이 있는지 여부를 주시하여야 하며, 변경사항의 공지가 있을 시에는 이를 확인하여야 합니다.
            <br />
            <br /><span className={classes.title}>제 16 조 회사의 면책</span>
            <br />① 회사가 이용자에게 제공하는 서비스는 제휴사와의 온라인 거래를 위한 광고 마케팅이므로 제휴사와 이용자 상호간의 거래와 관련된 제반 문제 즉, 거래이행, 물품배송, 청약철회 또는 반품, 물품하자로 인한 분쟁해결 등 필요한 사후처리는 거래당사자인 제휴사와 이용자가 직접 수행하여야 합니다. 회사는 이에 대하여 관여하지 않으며 어떠한 책임도 부담하지 않습니다.
            <br />② 제9조 제3항, 제4항의 사유로 인하여 서비스를 일시적으로 중단하는 경우 회사는 이로 인하여 회원 또는 제3자가 입은 손해에 대하여 책임지지 않습니다. 단, 회사의 고의 또는 중과실로 인한 경우에는 그러하지 아니합니다.
            <br />③ 제9조 5항의 사유로 인하여 서비스를 제한하거나 중단하는 경우 회사는 불가항력을 이유로 그 책임을 면합니다.
            <br />④ 회사는 이용자 또는 제휴사의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
            <br />⑤ 회사는 광고·마케팅 서비스를 통하여 거래되는 재화 또는 용역의 하자, 물품등록정보의 오류, 미비 등으로 인하여 이용자가 입는 손해에 대해서는 책임(제조물 책임 포함)을 지지 않습니다.
            <br />⑥ 회사는 이용자가 제휴사 혹은 다른 이용자가 게재한 정보, 자료, 사실의 정확성 등을 신뢰함으로써 입은 손해에 대하여 책임을 지지 않습니다.
            <br />⑦ 이 약관은 회사와 회원 간에 성립되는 서비스이용계약의 기본 약정입니다. 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 "개별약관"이라고 합니다)을 정하여 미리 공지할 수 있습니다. 회원이 이러한 개별약관에 동의하고 특정 서비스를 이용하는 경우에는 개별약관이 우선적으로 적용되고, 이 약관은 보충적인 효력을 갖습니다.
            <br />⑧ 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서 회사는 일체의 책임을 지지 않습니다.
            <br />⑨ 회사는 이용자의 귀책사유로 인한 서비스 장애에 대하여 책임을 지지 않습니다.
            <br />⑩ 회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비의 보수점검, 교체 또는 고장, 통신의 두절 등으로 인하여 일시적 또는 종국적으로 서비스를 제공할 수 없는 경우, 서비스 제공에 관한 책임이 면제됩니다. 이 경우 회사는 경품을 품은 달(경품달)에 게시하거나 기타의 방법으로 회원들에게 통지합니다. 단, 부득이한 사유가 있는 경우에는 사후 통지로 대체할 수 있습니다.
            <br />⑪ 회사는 무료로 제공하는 서비스 이용과 관련하여 관련 법령에 특별한 규정이 없는 한, 이용자에게 손해가 생기더라도 책임지지 않습니다.
            <br />⑫ 회사는 제 18조 ④항의 의무에 대해 노력합니다. 그러나 회원이 제 19조 ②항에 대한 회원의 의무를 다하지 않는 경우, 회사는 이용자에게 손해가 생기더라도 책임지지 않습니다.
            <br />
            <br /><span className={classes.title}>제 17 조 정보의 제공 및 광고의 게재</span>
            <br />① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편, 푸시메시지, SMS 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 수신 거절을 할 수 있습니다.
            <br />② 제1항의 정보를 전송하려고 하는 경우에는 회원의 사전 동의를 받아서 전송합니다. 다만, "회원"의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.
            <br />③ 회사는 서비스의 운영과 관련하여 서비스 화면, 경품을 품은 달(경품달), 전자우편 등에 광고를 게재할 수 있습니다. 다만, 이는 광고 수신에 동의한 회원에 한합니다.
            <br />④ 이용자는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
            <br />⑤ 제휴쇼핑몰 별 추첨점수 적립 예시와 주의사항 (https://drive.google.com/open?id=1bwYUW1iByWAj2-B-ddguJVY6c1IJxT1y)
            <br />
            <br /><span className={classes.title}>제 18 조 회사의 의무</span>
            <br />① 회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는 데 최선을 다하여야 합니다.
            <br />② 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보포함)보호를 위한 보안 시스템을 갖추어야 합니다.
            <br />③ 회사는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
            <br />④ 회사는 제휴쇼핑몰 별 추첨점수 적립 예시와 주의사항에 대하여 안내할 수 있도록 노력합니다.
            <br />
            <br /><span className={classes.title}>제 19 조 이용자의 의무</span>
            <br />① 이용자는 다음 각 호의 행위를 하여서는 안됩니다.
            <br />1. 신청 또는 변경 시 허위내용의 등록
            <br />2. 경품을 품은 달(경품달)에 게시된 정보의 무단변경
            <br />3. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
            <br />4. 회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
            <br />5. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            <br />6. 외설 또는 폭력적인 메시지, 화상, 음성, 동영상 기타 공서양속에 반하는 정보를 경품을 품은 달(경품달)에 공개 또는 게시하는 행위
            <br />7. 불특정 다수의 자를 대상으로 회사의 서비스를 이용하여 영리 목적으로 활동하는 행위
			      <br />8. 사행성을 조장하거나, 사행성과 관련된 행위
            <br />② 회사는 제 18조 ④항의 의무에 대해 노력합니다. 그러나 제휴마케팅의 특성상 다수의 쇼핑몰이 존재하며, 이에 따라 상시 최신화와 안내에 대해 어려움이 따르므로 회원이 회사가 고지한 제휴쇼핑몰 별 추첨점수 적립 예시와 주의사항을 숙지하더라도, 정확한 내용을 회사에 문의하지 않아 발생하는 책임은 회원 본인에게 있습니다. (회원은 정확한 추첨점수 적립과 구매 주의사항 및 회사가 고지한 안내내용에 표시되지 않는 내용 또는 상세내용에 대해서는 help@byeongmat.com으로 문의할 수 있습니다.)
            <br />
            <br /><span className={classes.title}>제 20 조 준거법 및 관할법원</span>
            <br />이 약관과 회사와 회원 간의 서비스 이용계약 및 회원 상호간의 분쟁에 대해 회사를 당사자로 하는 소송이 제기될 경우에는 대한민국 법을 준거법으로 하며, 회사의 본사 소재지를 관할하는 법원을 전속적 합의관할법원으로 정합니다.
            <br />
            <br /><span className={classes.title}>제 21 조 기타사항</span>
            <br />① 회사는 필요한 경우 특정 서비스(혹은 그 일부)를 경품을 품은 달(경품달)을 통하여 미리 공지한 후, 일시적 또는 영구적으로 수정하거나 중단할 수 있습니다.
            <br />② 회사와 회원은 상대방의 명백한 동의 없이 이 약관상의 권리와 의무를 제3자에게 양도 또는 증여할 수 없으며 담보의 목적으로 제공할 수 없습니다.
            <br />③ 이 약관과 관련하여 당사자 간의 합의에 의하여 추가로 작성된 계약서, 협정서, 통보서 등과 회사의 정책변경, 법령의 제정.개정 또는 공공기관의 고시.지침 등에 의하여 회사가 경품을 품은 달(경품달)을 통해 회원에게 공지하는 내용도 본 약관의 일부를 구성합니다.
        </Container>
             </Paper>
    </React.Fragment>
  );
}
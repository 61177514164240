import 'date-fns';
import React, { useEffect, useContext } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import CommonAPI from '../components/CommonAPI'
import TokenContext from '../components/ContextAPI'
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
// import RedeemIcon from '@material-ui/icons/Redeem';
import Divider from '@material-ui/core/Divider';
import { Helmet } from 'react-helmet'
import DateFnsUtils from '@date-io/date-fns';
import koLocale from "date-fns/locale/ko";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';


const useStyles = makeStyles(theme => ({
  root: {
    borderRadius:0,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 0),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0),
      paddingBottom: '10px',

    },
  },
  formControlOption: {
    minWidth: 150,
  },
  fromControlOptionSizeDown: {
    minWidth: 100,
  },
  dateField: {
    maxWidth: 160,
  },
  dateFieldBirthYMD: {
    width: 260,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  sectionMobile: {
    display: 'inline',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  prizeTextForMobile12: {
    [theme.breakpoints.down('md')]: {
      fontSize:'12px',
    },
  },
  prizeTextForMobile13: {
    [theme.breakpoints.down('md')]: {
      fontSize:'13px',
    },
  },
  prizeTextForMobile14: {
    [theme.breakpoints.down('md')]: {
      fontSize:'14px',
    },
  }
}));

export default function MyPage() {
  const classes = useStyles();
  const tokenContext = useContext(TokenContext);

  //직업코드, 반려동물 코드, 카테고리 코드에 대한 State 생성
  const [jobCode, setJobCode] = React.useState(null);
  const [petCode, setPetCode] = React.useState(null);
  const [categoryCode, setCategoryCode] = React.useState(null);

  //닉네임(State)
  const [nickName, setNickName] = React.useState('');
  //닉네임 확인 결과(State)
  const [nickNameCheck, setNickNameCheck] = React.useState(false);
  //전화번호 확인 결과(State)
  const [phoneNumberCheck, setPhoneNumberCheck] = React.useState(false);
  //패스워드 (State)
  const [password, setPassword] = React.useState('');
  //생일(State)
  const [birthYMD, setBirthYMD] = React.useState('');
  //선택된 카테고리 코드(State)
  const [selectedCategoryCode, setSelectedCategoryCode] = React.useState('');
  //선택된 직업 코드(State)
  const [selectedJobCode, setSelectedJobCode] = React.useState('');
  //선택된 반려동물 코드(State)
  const [selectedPetCode, setSelectedPetCode] = React.useState('');
  //닉네임 변경 여부
  const [changeNickName, setChangeNickName] = React.useState(false);
  //닉네임 변경 여부
  const [changePhoneNumber, setChangePhoneNumber] = React.useState(false);
  //성별(State)
  const [sex, setSex] = React.useState('');
  //혼인여부(State)
  const [marital, setMarital] = React.useState('');
  //자녀여부(State)
  const [children, setChildren] = React.useState('');
  //자차소유여부(State)
  const [car, setCar] = React.useState('');
  //마케팅 수신 여부(State)
  const [marketing, setMarketing] = React.useState(false);
  //자녀 성별(State)
  const [childrenSex, setChildrenSex] = React.useState('M');
  //자녀 생일(State)
  const [childrenBirthYMD, setChildrenBirthYMD] = React.useState(getCurDate);
  //휴대폰 번호 1(State)
  const [phoneNumber1, setPhoneNumber1] = React.useState('');
  //휴대폰 번호 1(State)
  const [phoneNumber2, setPhoneNumber2] = React.useState('');
  //휴대폰 번호 1(State)
  const [phoneNumber3, setPhoneNumber3] = React.useState('');
  //업데이트 완료여부(State)
  const [isSucceedModify, setIsSucceedModify] = React.useState(false);
  //회원정보 수정 이후 다이얼로그 Open 여부(State)
  const [open, setOpen] = React.useState(false);
  //회원정보 수정 이후 다이얼로그 Open 여부(State)
  const [openWithdrawal, setOpenWithdrawal] = React.useState(false);
  //회원탈퇴 텍스트
  const [witdrawalText, setWitdrawalText] = React.useState('');
  //회원탈퇴 여부
  const [isWithDrawal, setIsWithDrawal] = React.useState(false);
  //유저 추첨점수
  const [userScore, setUserScore] = React.useState(0);
  //유저 추첨점수
  const [userRank, setUserRank] = React.useState(0);
  //유저 추첨 라운드
  const [roundNumber, setRoundNumber] = React.useState('');

  //14세 미만 체크함수
  function isChild(birthDate) {
    birthDate = birthDate.replace(/-/gi, "");

    let today = new Date();
    let yyyy = today.getFullYear();
    let mm = today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1); // getMonth()
    let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
    let n = parseInt(String(yyyy) + String(mm) + String(dd)) - parseInt(birthDate) - 140000;

    if (n < 0) {
      return true
    } else {
      return false;
    }
  }

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
  }

   // comma 찍기 함수
   function comma(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // 오늘날짜 구하기 (yyyy-MM-dd)
  function getCurDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month
    } else if (day.length < 2) {
      day = '0' + day
    };
    return [year, month, day].join('-');
  }

  // SELECT 메뉴 생성
  function getSelectMenu(props, menuName) {
    if (menuName === 'job') {
      return (
        props.map(prop => (
          <MenuItem key={prop.jobCode} value={prop.jobCode}>{prop.jobName}</MenuItem>
        ))
      )
    } else if (menuName === 'category') {
      return (
        props.map(prop => (
          <MenuItem key={prop.categoryCode} value={prop.categoryCode}>{prop.categoryName}</MenuItem>
        ))
      )
    } else if (menuName === 'pet') {
      return (
        props.map(prop => (
          <MenuItem key={prop.petCode} value={prop.petCode}>{prop.petName}</MenuItem>
        ))
      )
    }
  }

  // 고객정보 가져오기 API
  const getLovelyUser = (token) => {
    const api = new CommonAPI("/api/get-lovely-user");
    api.setAuthToken('Bearer ' + token);
    return api.httpPostAxiosWithJWT();
  }

  // 고객정보 가져오기 API
  const getUserScore = (token) => {
    const api = new CommonAPI("/api/kyungpumdal/get-user-score");
    api.setAuthToken('Bearer ' + token);
    api.setHttpPostParam("{'eventType':'p'}")
    return api.httpPostAxiosWithJWT();
  }

  // 직업코드 가져오기 API
  function getJobCode() {
    const api = new CommonAPI("/api/kyungpumdal/get-code-name");
    api.setHttpGetParam("?codeType=job")
    return api.httpGetAxios();
  }

  // 반려동물 코드 가져오기 API
  function getPetCode() {
    const api = new CommonAPI("/api/kyungpumdal/get-code-name");
    api.setHttpGetParam("?codeType=pet")
    return api.httpGetAxios();
  }

  // 관심카테고리 코드 가져오기 API
  function getCategoryCode() {
    const api = new CommonAPI("/api/kyungpumdal/get-code-name");
    api.setHttpGetParam("?codeType=category")
    return api.httpGetAxios();
  }

  // 닉네임 중복확인 API
  const getIsExistNickName = event => {
    const api = new CommonAPI("/api/get-is-exist-nick");
    api.setHttpGetParam("?nickName=" + nickName)
    api.httpGetAxios().then(
      (response) => {
        if (response.data.result === 0) {
          // console.log(response.data.data.isExistNickName);
          if (response.data.data.isExistNickName === true) {
            setNickName('')
            setNickNameCheck(false);
          } else {
            setNickName(nickName)
            setNickNameCheck(true);
          }
        }
      }
    ).catch();
  }

  // 정보 수정 API 호출
  function setModiedyUser(jsonBody) {
    // console.log(jsonBody)
    const api = new CommonAPI("/api/modify-lovely-user");
    api.setHttpPostParam(jsonBody);
    api.setAuthToken('Bearer ' + tokenContext.token)
    return api.httpPostAxiosWithJWT();
  }

  // 탈퇴하기 API 호출
  function withdrawal() {
    // console.log(jsonBody)
    const api = new CommonAPI("/api/withdrawal-lovely-user");
    api.setAuthToken('Bearer ' + tokenContext.token)
    return api.httpPostAxiosWithJWT().then((response) => {
      if(response.data.result ===0 ){
        setIsWithDrawal(true);
      }
    });
  }

  // Validation Check 수정하기 버튼
  function modifyValidation() {
    if (marketing === false) {
      if (nickName !== '' && phoneNumber1 !== '' && phoneNumber2 !== '' && phoneNumber3 !== '') {
        if (isChild(birthYMD) !== true) {
          if (!(changePhoneNumber === true && phoneNumberCheck === false)) {
            if (!(changeNickName === true && nickNameCheck === false)) {
              return true;
            } else {
              return false
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      if (nickName !== '' && phoneNumber1 !== '' && phoneNumber2 !== '' && phoneNumber3 !== '' && sex !== '' && marital !== '' && children !== '' && car !== '' && selectedPetCode !== '' && selectedCategoryCode !== '' && selectedJobCode !== '') {
        if (isChild(birthYMD) !== true) {
          if (!(changePhoneNumber === true && phoneNumberCheck === false)) {
            if (!(changeNickName === true && nickNameCheck === false)) {
              return true;
            } else {
              return false
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  // 휴대폰 중복확인 API
  const getIsExistPhoneNumber = event => {
    if (phoneNumber1.length < 2 || phoneNumber1.length > 4) {
      setPhoneNumber1('')
    } else if (phoneNumber2.length < 3 || phoneNumber2.length > 4) {
      setPhoneNumber2('')
    } else if (phoneNumber3.length < 4 || phoneNumber3.length > 4) {
      setPhoneNumber3('')
    } else {
      const phoneNumber = phoneNumber1 + "-" + phoneNumber2 + "-" + phoneNumber3;
      const api = new CommonAPI("/api/get-is-exist-phone-number");
      api.setHttpGetParam("?phoneNumber=" + phoneNumber)
      api.httpGetAxios().then(
        (response) => {
          if (response.data.result === 0) {
            // console.log(response);
            if (response.data.data.isExistPhoneNumber === true) {
              setPhoneNumber1('')
              setPhoneNumber2('')
              setPhoneNumber3('')
              setPhoneNumberCheck(false);
            } else {
              setPhoneNumber1(phoneNumber1)
              setPhoneNumber2(phoneNumber2)
              setPhoneNumber3(phoneNumber3)
              setPhoneNumberCheck(true);
            }
          }
        }
      ).catch();
    }
  }

  //회원 수정 오브젝트 생성
  const modifyUser = () => {
    if (!(changePhoneNumber === true && phoneNumberCheck === false)) {
      if (!(changeNickName === true && nickNameCheck === false)) {
        if (birthYMD !== '' && nickName !== '' && phoneNumber1 !== '' && phoneNumber2 !== '' && phoneNumber3 !== '')
          if (isChild(birthYMD) !== true) {
            let requestObj = {};
            let categoryArray = [];
            let childrenArray = [];

            if (password === '' || password === undefined || password === null) {
              requestObj.newUserPWD = "none";
            } else {
              requestObj.newUserPWD = password;
            }
            requestObj.nickName = nickName;
            requestObj.phoneNumber = phoneNumber1 + "-" + phoneNumber2 + "-" + phoneNumber3;
            requestObj.birthYMD = birthYMD;

            if (marketing === true) {
              requestObj.sexType = sex;
              requestObj.maritalStatus = marital;
              requestObj.childrenStatus = children;
              requestObj.carStatus = car;
              requestObj.jobCode = selectedJobCode;
              requestObj.petCode = selectedPetCode;
              requestObj.mailReceiveStatus = marketing;

              if (selectedCategoryCode !== '') {
                let categoryObj = {};
                categoryObj.categoryCode = selectedCategoryCode;
                categoryArray.push(categoryObj);
                requestObj.lovelyUserCategories = categoryArray;
              }

              if (children === 'Y') {
                let childrenObj = {};
                childrenObj.sexType = childrenSex;
                childrenObj.birthYMD = childrenBirthYMD;
                childrenArray.push(childrenObj);
                requestObj.lovelyChildren = childrenArray;
              }
            } else {
              requestObj.lovelyChildren = childrenArray;
              requestObj.lovelyUserCategories = categoryArray;
            }

            setModiedyUser(JSON.stringify(requestObj)).then(
              (response) => {
                if (response.data.result === 0) {
                  handleClickOpen();
                }
              }
            ).catch();
          }
      }
    }

  }

  // API CALL WHEN MOUNTED
  useEffect(() => {
    window.scrollTo(0, 0);

    let isSubscribed = true;
    if (isSubscribed === true) {
      //직업코드 호출
      getJobCode().then(
        (response) => {
          if (response.data.result === 0) {
            setJobCode(response.data.dataList);
            // console.log(response.data.dataList);
            //펫코드 호출
            getPetCode().then(
              (response) => {
                if (response.data.result === 0) {
                  setPetCode(response.data.dataList);
                  // console.log(response.data.dataList);
                  //카테고리 코드 호출
                  getCategoryCode().then(
                    (response) => {
                      if (response.data.result === 0) {
                        setCategoryCode(response.data.dataList);
                        // console.log(response.data.dataList);
                        //유저 정보호출
                        getLovelyUser(tokenContext.token).then(
                          (response) => {
                            if (response.data.result === 0) {
                              const lovelyUser = response.data.lovelyUser;
                              // console.log(lovelyUser)
                              if (lovelyUser !== undefined) {
                                setNickName(lovelyUser.nickName);
                                setBirthYMD(lovelyUser.birthYMD);
                                const phoneNumberArray = lovelyUser.phoneNumber.split('-')
                                setPhoneNumber1(phoneNumberArray[0]);
                                setPhoneNumber2(phoneNumberArray[1]);
                                setPhoneNumber3(phoneNumberArray[2]);

                                if (lovelyUser.sexType !== undefined) {
                                  setSex(lovelyUser.sexType);
                                }
                                if (lovelyUser.carStatus !== undefined) {
                                  setCar(lovelyUser.carStatus);
                                }
                                if (lovelyUser.maritalStatus !== undefined) {
                                  setMarital(lovelyUser.maritalStatus);
                                }
                                if (lovelyUser.childrenStatus !== undefined) {
                                  setChildren(lovelyUser.childrenStatus);
                                }
                                if (lovelyUser.jobCode !== 0 && lovelyUser.jobCode !== undefined) {
                                  setSelectedJobCode(lovelyUser.jobCode);
                                }
                                if (lovelyUser.petCode !== 0 && lovelyUser.petCode !== undefined) {
                                  setSelectedPetCode(lovelyUser.petCode);
                                }
                                if (lovelyUser.mailReceiveStatus !== undefined) {
                                  setMarketing(lovelyUser.mailReceiveStatus);
                                }
                                if (lovelyUser.lovelyChildren.length !== 0) {
                                  setChildrenBirthYMD(lovelyUser.lovelyChildren[0].birthYMD);
                                  setChildrenSex(lovelyUser.lovelyChildren[0].sexType);
                                }
                                if (lovelyUser.lovelyUserCategories.length !== 0) {
                                  setSelectedCategoryCode(lovelyUser.lovelyUserCategories[0].categoryCode);
                                }
                              }
                            }
                          }
                        ).catch();
                      }
                    }
                  ).catch();
                }
              }
            ).catch();
          }
        }
      ).catch();

  
    getUserScore(tokenContext.token).then(
      (response) => {
        // console.log(response)
        if (response.data.result === 0 && response.data.data !== undefined) {
            setUserScore(response.data.data.score);
            setRoundNumber(response.data.data.roundNumber);
            setUserRank(response.data.data.rank);
          }
          }
    ).catch();
  }
    return () => isSubscribed = false
  }, [tokenContext.token]);

  //성별 핸들러
  const handleChangeSex = event => {
    setSex(event.target.value);
  };
  //닉네임 핸들러
  const handleChangeNickName = event => {
    setNickName(event.target.value);
    setChangeNickName(true);
  };
  //패스워드 핸들러
  const handleChangePassword = event => {
    setPassword(event.target.value);
  };
  //직업코드 핸들러
  const handleChangeJobCode = event => {
    setSelectedJobCode(event.target.value);
  }
  //카테고리 코드 핸들러
  const handleChangeCategoryCode = event => {
    setSelectedCategoryCode(event.target.value);
  }
  //반려동물 코드 핸들러
  const handleChangePetCode = event => {
    setSelectedPetCode(event.target.value);
  }
  //결혼여부 핸들러
  const handleChangeMarital = event => {
    setMarital(event.target.value);
  };
  //자녀여부 핸들러
  const handleChangeChildren = event => {
    setChildren(event.target.value);
  };
  //자차여부 핸들러
  const handleChangeCar = event => {
    setCar(event.target.value);
  };
  //마케팅 수신동의여부 핸들러
  const handleChangeTermsMarketing = event => {
    setMarketing(event.target.checked);
  };
  //자녀 생일 핸들러
  const handleChangeChildrenBrithYMD = date => {
    setChildrenBirthYMD(formatDate(date));
  }
  //자녀 성별 핸들러
  const handleChangeChildrenSex = event => {
    setChildrenSex(event.target.value);
  }
  //휴대폰 번호 첫번째 핸들러
  const handleChangePhoneNumber1 = event => {
    setPhoneNumber1(event.target.value);
    setChangePhoneNumber(true);
  }
  //휴대폰 번호 두번째 핸들러
  const handleChangePhoneNumber2 = event => {
    setPhoneNumber2(event.target.value);
    setChangePhoneNumber(true);
  }
  //휴대폰 번호 세번째 핸들러
  const handleChangePhoneNumber3 = event => {
    setPhoneNumber3(event.target.value);
    setChangePhoneNumber(true);
  }
  //생일 핸들러
  const handleChangeBirthYMD = date => {
    setBirthYMD(formatDate(date));
  }
  const handleChangeWithDrawalText = event => {
    setWitdrawalText(event.target.value)
  }

  //회원정보 수정 클릭 핸들러
  const handleClickOpen = () => {
    setOpen(true);
    setTimeout(function() {
      setOpen(false)
    },3000);
    setTimeout(function() {
      setIsSucceedModify(true)
    },3000);
  };

  //회원탈퇴 클릭 핸들러
  const handleWithdrawlClickOpen = () => {
    setOpenWithdrawal(true);
  };
  //회원탈퇴 닫기 핸들러
  const handleWithdrawalClose = () => {
    setOpenWithdrawal(false);
  };

  //자녀 패널
  function childrenPanel() {
    return (
      // childrenList.map(prop => (
      <React.Fragment key={'index'}>
        <Grid item md={3} xs={6}>
          <FormControl className={classes.fromControlOptionSizeDown}>
            <InputLabel id="register-children-sex-select-label">자녀 성별</InputLabel>
            <Select
              labelId="register-children-sex-select"
              id="register-children-sex-select"
              value={childrenSex}
              disabled={marketing===false}
              onChange={handleChangeChildrenSex}
              style={{minWidth:"150px"}}
            >
              <MenuItem value={'M'}>남</MenuItem>
              <MenuItem value={'F'}>여</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog-children-birthYmd"
            label="자녀 생년월일"
            invalidDateMessage="생년월일 형식이 아닙니다"
            invalidLabel="알수 없는 오류"
            format="yyyy-MM-dd"
            value={childrenBirthYMD}
            onChange={handleChangeChildrenBrithYMD}
            KeyboardButtonProps={{
              'aria-label': 'change date birth ymd',
            }}
            required
            disabled={marketing===false}
            className={classes.dateField}
            style={{margin:0}}
          />
          </MuiPickersUtilsProvider>
          {/* <TextField
            id="date2"
            label="자녀 생년월일"
            type="date"
            disabled={marketing===false}
            value={childrenBirthYMD}
            className={classes.dateField}
            onChange={handleChangeChildrenBrithYMD}
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
        </Grid>
        <br />
      </React.Fragment>
      // ))
    )
  }

  if (isSucceedModify === true) {
    return <Redirect to='/' />
  }else if(isWithDrawal === true){
    return <Redirect to='/logout' />
  } else {
    return (
      <React.Fragment>
        <Helmet>
          <title>마이페이지 | 경품달(경품을 품은 달)</title>
          <meta name="description" content="경품달 마이페이지 | 아이폰급 철통 보안유지 | 가입만해도 매달 경품이벤트 추첨대상 | 집과 땅을 경품으로 드리는 그날까지 ! | 공정한 경품이벤트를 선도합니다" />
          <meta name="keywords" content="경품달,로또,복권,연금복권,이베이츠,적립금,공정성,주작,조작,투표,쿠차,coocha,ebates,경품이벤트,경품,당첨금,쿠팡,알리익스프레스,알리바바,인터넷쇼핑,부모님인터넷쇼핑,무료,핫딜,직구,해외직구,블랙프라이데이,십일절,11절,위메프데이,홈쇼핑,쇼핑몰,캐시백,유튜브추첨,구독형,유튜브경품,구매대행,부모님구매대행,해외여행,11번가,지마켓,옥션" />
          <meta name="author" content="병맛사람들" />
          </Helmet>
        <CssBaseline />
            <Paper elevation={0} className={classes.root} style={{backgroundColor:"#263980", color:'white'}}>
          <Container maxWidth='md' style={{padding:0}}>
            {/* <RedeemIcon style={{fontSize:'40px'}} className={classes.sectionDesktop}/> */}
            {/* <RedeemIcon style={{fontSize:'30px'}} className={classes.sectionMobile}/> */}
            {/* <div className={classes.sectionDesktop}> */}
            <Typography variant="h6" align="center" className={classes.prizeTextForMobile14}>
              {tokenContext.nickName} 님 추첨정보<br/>
              {roundNumber !== '' ? roundNumber+' 회차' : '이달 구매 이력이 없습니다'}
            </Typography>

            {/* </div> */}
            {/* <div className={classes.sectionMobile} >
            <Typography variant="body1" align="center">
              {tokenContext.nickName} 님 추첨점수<br/>
              {roundNumber !== undefined ? roundNumber+' 회차' : ''}
            </Typography><br/>
            </div> */}
            <div className={classes.sectionDesktop}>
            <Typography variant="h6" align="center">
            {userRank !== 0 ? '[ 순위 ' + userRank + '등 ]' : '[ 순위 없음 ]'}<br/>
            [ {comma(userScore)} 점 ]
            </Typography>
            <br/>
            </div>
            <div className={classes.sectionMobile}>
            <Typography variant="body2" align="center">
            {userRank !== 0 ? '[ 순위 ' + userRank + '등 ]' : '[ 순위 없음 ]'}<br/>
            [ {comma(userScore)} 점 ]
            </Typography>
            </div>
            <div className={classes.sectionDesktop}>
            <Typography variant="body2" align="center">
              쇼핑몰별 구매인증 업데이트 시간이 달라<br/>
              점수 갱신까지 시간이 소요될 수 있습니다.
            </Typography>
            </div>
            <div className={classes.sectionMobile} style={{margin:5}}>
            <Typography style={{fontSize:'10px'}} align="center">
              쇼핑몰별 구매인증 업데이트 시간이 달라<br/>
              점수 갱신까지 시간이 소요될 수 있습니다.
            </Typography>
            </div>
            </Container>
            </Paper>
            <Paper elevation={0} className={classes.root}>
            <Container maxWidth='md' style={{padding:0}}>
              <ValidatorForm onSubmit={modifyUser}>
                <Grid container justify='center'>
                  <Grid item xs={12}>
                    <Typography variant="h6" className={classes.sectionDesktop} align="center">
                      회원정보 수정
                  </Typography>
                  <div className={classes.sectionDesktop}>
                  <Typography>
                      고객님의 소중한 정보는 안전하게 저장됩니다.
                  </Typography>
                  </div>
                  <div className={classes.sectionMobile}>
                  <Typography variant="body1">
                      회원정보 수정
                  </Typography>
                  <Typography variant="body2">
                      소중한 정보는 안전하게 저장됩니다.
                  </Typography>
                  </div>
                  </Grid>
                </Grid>
                <br />
                <Grid container justify='center'>
                  <Grid item md={12} xs={12}>

                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog-birthYmd"
                      label="생년월일"
                      invalidDateMessage="생년월일 형식이 아닙니다"
                      invalidLabel="불러오는 중"
                      format="yyyy-MM-dd"
                      value={birthYMD}
                      onChange={handleChangeBirthYMD}
                      KeyboardButtonProps={{
                        'aria-label': 'change date birth ymd',
                      }}
                      required
                      style={{ minWidth: 280 }}
                    />
                    </MuiPickersUtilsProvider>

                    {/* <TextField
                      id="date"
                      label="생년월일"
                      type="date"
                      required
                      fullWidth
                      value={birthYMD}
                      className={classes.dateFieldBirthYMD}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChangeBirthYMD}
                    /> */}
                  </Grid>
                </Grid>
                <br />
                <Grid container justify='center'>
                  <Grid item md={6} xs={8}>
                    <TextField
                      disabled={nickNameCheck}
                      variant="outlined"
                      required
                      fullWidth
                      name="nickname"
                      label="닉네임"
                      id="nickname"
                      value={nickName}
                      type="text"
                      autoComplete='nickname'
                      onChange={handleChangeNickName}
                      margin="dense"
                      style={{zIndex:0}}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} style={{ paddingTop: 10 }}>
                    {
                      !changeNickName ? <Button color="primary" variant='contained' disabled>확인</Button>
                        :
                        !nickNameCheck ?
                          <Button color="primary" variant='contained' onClick={getIsExistNickName}>확인</Button>
                          :
                          <Button color="primary" disabled>완료</Button>
                    }
                  </Grid>
                </Grid>
                <br />
                <Grid container justify="center" spacing={0}>
                  <Grid item md={2} xs={2}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      disabled={phoneNumberCheck}
                      name="phoneNumber1"
                      label="휴대폰"
                      type="number"
                      value={phoneNumber1}
                      InputProps={{ inputProps: { min: 0, max: 9999 } }}
                      id="phoneNumber1"
                      margin="dense"
                      onChange={handleChangePhoneNumber1}
                    />
                  </Grid>
                  <Grid item md={2} xs={3}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      disabled={phoneNumberCheck}
                      name="phoneNumber2"
                      label=""
                      value={phoneNumber2}
                      type="number"
                      InputProps={{ inputProps: { min: 0, max: 9999 } }}
                      id="phoneNumber2"
                      margin="dense"
                      onChange={handleChangePhoneNumber2}
                    />
                  </Grid>
                  <Grid item md={2} xs={3}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      disabled={phoneNumberCheck}
                      value={phoneNumber3}
                      name="phoneNumber3"
                      label=""
                      type="number"
                      InputProps={{ inputProps: { min: 0, max: 9999 } }}
                      id="phoneNumber3"
                      margin="dense"
                      onChange={handleChangePhoneNumber3}
                    />
                  </Grid>
                  <Grid item md={1} xs={3} style={{ paddingTop: 13 }}>
                    {
                      !changePhoneNumber ?
                        <Button color="primary" variant='contained' disabled>확인</Button>
                        :
                        !phoneNumberCheck ?
                          <Button color="primary" variant='contained' onClick={getIsExistPhoneNumber}>확인</Button>
                          :
                          <Button color="primary" disabled>완료</Button>
                    }
                  </Grid>
                </Grid>
                <br/>
                <Grid container justify='center'>
                  <Grid item md={7} xs={11}>
                    <TextField
                      style={{zIndex:0}}
                      variant="outlined"
                      fullWidth
                      name="password"
                      label="신규 비밀번호 (입력 후, 수정하기 클릭)"
                      type="password"
                      inputProps={{ pattern: "^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$" }}
                      id="password"
                      value={password}
                      autoComplete='current-password'
                      margin="dense"
                      onChange={handleChangePassword}
                    />
                  </Grid>
                </Grid>
                <br/><Divider/><br/>
                <Grid container justify='center'>
                  <Grid item xs={12}>
                    <Typography variant="h6" align="center" className={classes.prizeTextForMobile14}>
                      선택 정보 수정 (가입형 추첨)
                  </Typography>
                  <div className={classes.sectionDesktop}>
                    <Typography>
                      선택정보는 고객님께 최적의 상품을 제공하기 위한 정보입니다.<br />
                      선택정보 입력과 알림 수신 동의하시고, 매달 경품추첨 대상이 되세요.
                  </Typography>
                  </div>
                  <div className={classes.sectionMobile}>
                    <Typography variant="body2">
                      선택정보 입력과 알림 수신 동의 후,<br /> 매달 무료로 경품추첨 대상이 되세요.
                    </Typography>
                  </div>
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={<Checkbox value="allowMarketingTerms" checked={marketing} color="primary" onChange={handleChangeTermsMarketing} />}
                  label="알림 수신, 선택정보 수집 동의"
                />
                <br />

                <Grid container justify='center'>
                  <Grid item md={3} xs={6}>
                    <FormControl className={classes.formControlOption}>
                      <InputLabel id="sex-select-label">성별</InputLabel>
                      <Select
                        disabled={marketing===false}
                        labelId="sex-select"
                        id="sex-select"
                        value={sex}
                        onChange={handleChangeSex}
                      >
                        <MenuItem value={'M'}>남</MenuItem>
                        <MenuItem value={'F'}>여</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <FormControl className={classes.formControlOption}>
                      <InputLabel id="marital-select-label">결혼여부</InputLabel>
                      <Select
                        disabled={marketing===false}
                        labelId="marital-select"
                        id="marital-select"
                        value={marital}
                        onChange={handleChangeMarital}
                      >
                        <MenuItem value={'Y'}>기혼</MenuItem>
                        <MenuItem value={'N'}>미혼</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container justify='center'>

                  <Grid item md={3} xs={6}>
                    <FormControl className={classes.formControlOption}>
                      <InputLabel id="register-pet-select-label">반려동물</InputLabel>
                      <Select
                        disabled={marketing===false}
                        labelId="register-pet-select"
                        id="register-pet-select"
                        value={selectedPetCode}
                        onChange={handleChangePetCode}
                      >
                        {petCode === null ? 'loading' : getSelectMenu(petCode, 'pet')}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <FormControl className={classes.formControlOption}>
                      <InputLabel id="job-select-label">직업</InputLabel>
                      <Select
                        disabled={marketing===false}
                        labelId="job-select"
                        id="job-select"
                        value={selectedJobCode}
                        onChange={handleChangeJobCode}
                      >
                        {jobCode === null ? 'loading' : getSelectMenu(jobCode, 'job')}
                      </Select>
                    </FormControl>
                  </Grid>

                </Grid>
                <Grid container justify='center'>
                  <Grid item md={3} xs={6}>
                    <FormControl className={classes.formControlOption}>
                      <InputLabel id="category-select-label">관심 카테고리</InputLabel>
                      <Select
                        disabled={marketing===false}
                        labelId="category-select"
                        id="category-select"
                        value={selectedCategoryCode}
                        onChange={handleChangeCategoryCode}
                      >
                        {categoryCode === null ? 'loading' : getSelectMenu(categoryCode, 'category')}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <FormControl className={classes.formControlOption}>
                      <InputLabel id="register-car-select-label">자차소유여부</InputLabel>
                      <Select
                        disabled={marketing===false}
                        labelId="register-car-select"
                        id="register-car-select"
                        value={car}
                        onChange={handleChangeCar}
                      >
                        <MenuItem value={'Y'}>있음</MenuItem>
                        <MenuItem value={'N'}>없음</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={0} justify='center'>
                  <Grid item md={children === 'Y' ? 12 : 12} xs={children === 'Y' ? 12 : 12}>
                    <FormControl className={children === 'Y' ? classes.fromControlOptionSizeDown : classes.formControlOption}>
                      <InputLabel id="children-select-label">자녀여부</InputLabel>
                      <Select
                        disabled={marketing===false}
                        labelId="children-select"
                        id="children-select"
                        value={children}
                        onChange={handleChangeChildren}
                      >
                        <MenuItem value={'Y'}>있음</MenuItem>
                        <MenuItem value={'N'}>없음</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {children === 'Y' ? childrenPanel() : ''}
                </Grid>
                <br />
                <br />
                <Grid container justify="center">
                  <Grid item md={2}>
                    <div className={classes.sectionDesktop}>
                    <Button
                      disabled={modifyValidation() === false}
                      fullWidth
                      variant="outlined"
                      color="primary"
                      style={{color:'#001353', borderColor:'#001353'}}
                      className={classes.submit}
                      type="submit"
                      size="medium"
                    >
                      수정하기
                  </Button>
                  </div>
                    <div className={classes.sectionMobile}>
                    <Button
                      disabled={modifyValidation() === false}
                      fullWidth
                      variant='outlined'
                      color="primary"
                      className={classes.submit}
                      type="submit"
                      size="medium"
                      style={{color:"#001353", borderColor:'#001353'}}
                    >
                      수정하기
                  </Button>
                  </div>
                  </Grid>
                </Grid>
              </ValidatorForm>
              <br />
              <div className={classes.sectionDesktop}>
              <Grid container justify="flex-end">
                <Grid item md={3}>
                  <Button onClick={handleWithdrawlClickOpen}>
                    <span style={{fontSize:'5pt', color:'grey'}}>회원 탈퇴하기</span>
                  </Button>
                </Grid>
              </Grid>
              </div>
              </Container>
            </Paper>

        {/* 고객정보 업데이트 완료 다이얼로그 */}
        <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"수정이 완료 되었습니다."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            고객님의 소중한 정보는 안전하게 보관하겠습니다.<br/>
            3초 뒤, 홈 화면으로 이동합니다.<br/>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {/* 고객정도 업데이트 완료 다이얼로그 */}
      <Dialog
        open={openWithdrawal}
        onClose={handleWithdrawalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"정말 탈퇴 하시겠어요 ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            불편함이 있으셨다면, 진심을 다해 죄송한 마음을 전하고 싶습니다.<br/>
            불편사항은 help@byeongmat.com 으로 접수 하실 수 있어요.<br/>
            저희가 개선할 수 있도록 최선을 다해 노력하겠습니다.<br/><br />
            탈퇴 하시려면,<br/>
          </DialogContentText>
            <TextField
              variant="outlined"
              fullWidth
              label="'탈퇴 안하면 다음달에 당첨될지도 ?' 를 입력해주세요."
              type="text"
              onChange={handleChangeWithDrawalText}
            />
            <br/>
        </DialogContent>
          <Button onClick={handleWithdrawalClose} color="primary" autoFocus>
            닫 기<LoyaltyIcon />
          </Button>
          <Button onClick={withdrawal} color="secondary"
            disabled={witdrawalText!=='탈퇴 안하면 다음달에 당첨될지도 ?'}>
            탈퇴하기
          </Button>
          <br/>
      </Dialog>
      </React.Fragment> 
    );
  }
}